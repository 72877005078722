import React from 'react';
import {getUsers} from "../../../app/hooks";
import {connect} from "react-redux";


class CalendarNavigation extends React.Component {

    state = {
        dateCountSelect: 7,
        doctors: [],
    }

    changeDateCount = (days) => {
        let subtract = -1, add = 6;
        days = parseInt(days);
        if(days === 1)      {
            subtract = 0; add = 1;
        }
        if(days === 3)      {
            subtract = -1; add = 2;
        }
        if(days === 7)      {
            subtract = -1; add = 6;
        }
        if(days === 14)      {
            subtract = -4; add = 12;
        }
        if(days === 30)      {
            subtract = -10; add = 20;
        }
        this.setState({...this.state, dateCountSelect: days});
        this.props.reload(subtract, add);
    }

    left = () => {
        let subtract = this.props.subtract - this.state.dateCountSelect;
        let add = subtract + (this.state.dateCountSelect);
        this.props.reload(subtract, add);
    }

    right = () => {
        let subtract = this.props.subtract + this.state.dateCountSelect;
        let add = subtract + (this.state.dateCountSelect);
        this.props.reload(subtract, add);
    }

    render = () => {
        return <div className="mb-2 form-inline d-flex justify-content-between">
            <div className="d-flex">
                <button
                    onClick={this.left}
                    className="btn btn-white mr-1">
                    <i className="fa fa-chevron-left" />
                </button>

                <div>
                    <select value={this.state.dateCountSelect} onChange={(e) => this.changeDateCount(e.target.value)} className="form-control">
                        <option value={1}> 1 </option>
                        <option value={3}> 3 </option>
                        <option value={7}> 7 </option>
                        <option value={14}> 14 </option>
                        <option value={30}> 30 </option>
                    </select>
                </div>

                <button
                    onClick={this.right}
                    className="btn btn-white ml-1 mr-3">
                    <i className="fa fa-chevron-right" />
                </button>

                {!this.props.user.is_doctor &&
                <select name="doctor" className="form-control"
                        value={this.props.doctor} onChange={(e) => this.props.reload(false, false, e.target.value)}>
                    <option value="">Sve</option>
                    {this.state.doctors.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>}
            </div>
        </div>
    }

    componentDidMount() {
        getUsers({all: true, active: true, doctors: true}).then((response) => {
            this.setState({...this.state, doctors: response.data});
        });
    }
}
const mapStateToProps = state => ({
    user: state.user
});
export default connect(mapStateToProps)(CalendarNavigation);
