import React from 'react';
import {connect} from "react-redux";
import {patchUser} from "../../../app/hooks";
import UserForm from "./UserForm";
import routes from "../../../app/routes";
import {checkUser, loginUser} from "../../../providers/userProvider";
import {setUser} from "../../../store/actions/userActions";


class Account extends React.Component {

    state = {
        id: this.props.match.params.id || this.props.user.id
    }

    onSuccess = (response, form) => {
        loginUser(response);
        this.props.setUser(checkUser());
    }

    render = () => {
        return <div className="container mt-4 mb-5" style={{maxWidth: 800}}>
            <div className="title mb-5">{routes.account.name}</div>

            <UserForm
                id={this.props.user.id}
                onSuccess={this.onSuccess}
                hook={patchUser} />
        </div>
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    setUser: setUser
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
