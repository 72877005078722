import React from 'react';
import {connect} from "react-redux";
import {deleteUserAuthorization, getUsers, patchUserAuthorization} from "../../app/hooks";
import Form from "../framework/form/Form";


class AuthorizationNotification extends React.Component {

    state = {
        users: [],
        form: {
            id: this.props.item.id,
            code: JSON.parse(this.props.item.data).code,
            user: ""
        }
    }

    delete = () => {
        if (!window.confirm(`Želite da obrisete notifikaciju?`)) {
            return false;
        }
        deleteUserAuthorization({id: this.props.item.id}).then((response) => {
            console.log(response.data)
            this.props.reload();
        })
    }

    onSuccess = (response, form) => {
        setTimeout(() => {
            this.props.reload();
        }, 1000);
    }

    render = () => {
        const data = JSON.parse(this.props.item.data);
        return <div>
            <div><strong> Zahtev za logovanje: </strong></div>
            <div className="mb-2">
                <strong>{data.name} {data.lastname}</strong>
            </div>
            <Form form={this.state.form} onSuccess={this.onSuccess} hook={patchUserAuthorization}>
                <input type="hidden" name="id"  />
                <input type="hidden" name="code"  />
                <div className="mb-1">
                    Odaberite korisnika:
                </div>
                <div className="mb-2">
                    <select name="user" className="form-control" required>
                        <option> </option>
                        {this.state.users.map((item) => {
                            return <option key={item.id} value={item.id}>
                                {item.role} {item.name} {item.lastname}
                            </option>
                        })}
                    </select>
                </div>

                <input className="btn btn-white mr-2" type="submit" value="ODOBRITE" />
                <button
                onClick={this.delete}
                    type="button" className="btn btn-white"> OBRIŠITE </button>
            </Form>

        </div>
    };

    componentDidMount() {
        getUsers({all: true, active: true}).then((response) => {
            this.setState({...this.state, users: response.data});
        })
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationNotification);
