import React from 'react';
import {patchPatient} from "../../../app/hooks";
import routes from "../../../app/routes";
import PatientForm from "../patient/PatientForm";


class Patient extends React.Component {

    render = () => {
        return <div className="container mt-4 mb-5" style={{maxWidth: 800}}>
            <div className="title mb-5">{routes.patient.name}</div>

            <PatientForm
                id={this.props.match.params.id}
                hook={patchPatient} />
        </div>
    }
}
export default Patient;
