import React from 'react';
import {price} from "../../../app/helpers";
import {deleteAppointmentExamination, patchAppointmentExamination} from "../../../app/hooks";


class Examination extends React.Component {

    state = {
        form: this.props.exam.pivot
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
        patchAppointmentExamination({id: this.props.exam.pivot.id, field: name, value: value}).then((response) => {
        })
    }

    delete = () => {
        var r = window.confirm(`Želite da obrišete?`);
        if (r !== true) {
            return false;
        }
        deleteAppointmentExamination({id: this.props.exam.pivot.id}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        const exam = this.props.exam;
        const form = this.state.form;
        return <div className="mb-3 accent_block d-flex align-items-center justify-content-between">
            <div className="mr-3 flex-grow-1">
                <div className="font-weight-bold font_2 mb-3">
                    {exam.name}
                </div>

                <div className="d-flex align-items-center mb-2">
                    <div className="font-weight-bold mr-2" style={{width: 120}}>
                        {price(exam.price)}
                    </div>
                    <input type="number" name="override_price"
                           onChange={this.changeField}
                           value={form.override_price || ""} className="form-control" placeholder="Nova cena" />
                </div>
                <textarea className="form-control" placeholder="Dodajte napomenu..."
                          onChange={this.changeField}
                          name="description"
                          value={form.description || ""} />
            </div>
            <div>
                <button className="btn btn-white" onClick={this.delete}> Izbrišite </button>
            </div>
        </div>
    }

}

export default Examination;
