import React from 'react';
import {connect} from "react-redux";
import {getPatientsWaiting} from "../../app/hooks";
import moment from "moment";
import {openDrawer} from "../../store/actions/drawersActions";


class PatientsWaiting extends React.Component {

    state = {
        appointments: []
    }
    interval = false

    render = () => {
        if(this.state.appointments.length === 0) return "";
        return <div className="mb-2 font_09">
            {this.state.appointments.map((item) => {
                return <div key={item.id} className="p-2 text-white" style={{background: item.user.color}}>
                    <div className="container">
                        <div className="mb-1">
                            Pacijent čeka na pregled: {item.patient.name} {item.patient.lastname} ({moment(item.start).format("HH:mm")})
                        </div>
                        <div>
                            {item.user.title}  {item.user.name}  {item.user.lastname}
                        </div>
                    </div>

                </div>
            })}

        </div>
    }

    load = () => {
        getPatientsWaiting().then((response) => {
            console.log(response.data)
           this.setState({...this.state, appointments: response.data});
        });
    }

    componentDidMount() {
        this.interval = setInterval(this.load, 5000);
       this.load();
    }
    componentWillUnmount() {
        if(this.interval)   {
            clearInterval(this.interval);
            this.interval = false;
        }
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientsWaiting);
