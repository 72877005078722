import React from 'react';
import {deleteRole, patchRole} from "../../../app/hooks";


class RoleItem extends React.Component {

    state = {
        edit: false,
        item: this.props.item,
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, item: {...this.state.item, [name]: value}});
    }

    save = (e) => {
        this.editField(e);
        const name = e.target.name;
        const value = e.target.value;
        patchRole({id: this.props.item.id, name: name, value: value}).then((response) => {
           this.props.reload();
        });
    }

    delete = (item) => {
        var r = window.confirm(`Želite da obrišete?`);
        if (r !== true) {
            return false;
        }
        deleteRole({id: this.props.item.id}).then((response) => {
            this.props.reload();
        });
    }

    toggleEdit = () => {
        this.setState({...this.state, edit: !this.state.edit});
    }

    render = () => {
        const item = this.state.item;
        if(this.state.edit)     {
            return <tr className="mb-2">
                <td>
                    <input type="text" className="form-control" name="name" maxLength={191}
                           onChange={this.editField} onBlur={this.save} value={item.name} />
                </td>
                <td>
                    <textarea className="form-control" name="title"
                           onChange={this.editField} onBlur={this.save} value={item.title} />
                </td>
                <td>
                    <button onClick={this.toggleEdit} className="btn btn-info btn-block">Zatvorite</button>
                    <button onClick={this.delete} className="btn btn-danger btn-block">Obrišite</button>
                </td>
            </tr>
        }
        return <tr className="mb-2">
            <td> {item.name} </td>
            <td> {item.title} </td>
            <td>
                <button onClick={this.toggleEdit} className="btn btn-info btn-block">Prepravite</button>
            </td>
        </tr>
    }
}
export default RoleItem;
