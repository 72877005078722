
const routes = {
    login: {route: "/login", name: "Ulogujte se"},
    authorization: {route: "/authorization"},

    userRegister: {route: "/user/register", name: "Kreiranje profila korisnika"},
    account: {route: "/account", name: "Vaš nalog"},
    users: {route: "/users", name: "Registar korisnika"},
    user: {route: (id) => `/user/${id}`, name: "Nalog korisnika"},
    patientRegister: {route: "/patient/register", name: "Otvaranje kartona"},
    patients: {route: "/patients", name: "Registar pacijenata"},
    apointments: {route: "/appointments", name: "Registar pregleda"},
    surgery: {route: "/surgery", name: "Hirurgija"},
    patient: {route: (id) => `/patient/${id}`, name: "Nalog pacijenta"},
    program: {route: `/program`, name: "Operativni program"},

    // CMS
    calendar: {route: "/calendar", name: "Zakazivanje pregleda"},

    settingsOffices: {route: "/offices", name: "Ordinacije"},
    settingsDiseases: {route: "/diseases", name: "ICD-10 KLASIFIKACIJA"},
    settingsExaminations: {route: "/examinations", name: "Pregledi"},
    settingsMachines: {route: "/machines", name: "Aparati"},
    settingsRoles: {route: "/roles", name: "Kategorije korisnika"},
    settingsTemplates: {route: "/templejts", name: "Templejti"},

    statistics: {route: "/statistics", name: "STATISTIKA"},
}
 export default routes;