import React from 'react';
import Form from "../../framework/form/Form";
import logo from "../../../assets/images/logo.png"
import {getOffices, getUserAuthorizationStatus, postUserAuthorization} from "../../../app/hooks";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";
import Modal from "../../framework/Modal";
import Loader from "../../framework/Loader";
import {loginUser} from "../../../providers/userProvider";


class Authorization extends React.Component {

    state = {
        form: {
            name: "",
            lastname: "",
            office: ""
        },
        offices: [],
        loading: false
    };
    interval = false;

    onSuccess = (response, form) => {
       this.setState({...this.state, loading: true});
       this.interval = setInterval(() => {
           getUserAuthorizationStatus({code: response.data}).then((statusResponse) => {
               if(statusResponse.data)  {
                   let user = loginUser(statusResponse);
                   if(user) {
                       window.location.reload();
                   }
               }
           });
       }, 2000);
    }

    closeLooper = () => {
        this.setState({...this.state, loading: false});
        if(this.interval)   {
            clearInterval(this.interval);
            this.interval = false;
        }
    }

    render = () => {
        return <div className="d-flex justify-content-center align-items-center vh-100">
            <Modal
                width={460}
                zIndex={7}
                title="Poslat zahtev"
                open={this.state.loading}
                close={this.closeLooper}>

                <div className="text-center"> Zahtev je poslat. Bićete ulogovani čim vam neko odobri. </div>
                <Loader height={100} />
            </Modal>

           <div className="auth_form">
               <div className="text-center mb-3">
                    <img src={logo} width={160} className="img-fluid" alt="logo" />
               </div>

               <Form form={this.state.form} onSuccess={this.onSuccess} hook={postUserAuthorization}>
                   <div className="mb-2">
                       <label className="w-100">
                           <div className="font-weight-bold mb-1">Vaše ime: </div>
                           <input type="text" name="name" className="form-control" autoComplete="off" required />
                       </label>
                   </div>
                   <div className="mb-2">
                       <label className="w-100">
                           <div className="font-weight-bold mb-1">Vaše prezime: </div>
                           <input type="text" name="lastname" className="form-control" autoComplete="off" required />
                       </label>
                   </div>
                   <div className="mb-2">
                       <label className="w-100">
                           <div className="font-weight-bold mb-1">Ordinacija: </div>
                           <select className="form-control" name="office" required>
                               <option value=""> </option>
                               {this.state.offices.map((item) => {
                                   return <option key={item.id} value={item.id}> {item.name} </option>
                               })}
                           </select>
                       </label>
                   </div>
                   <input type="submit" className="btn btn-primary btn-block" value="Pošaljite zahtev" />
               </Form>
               <Link
                   to={routes.login.route}
                   className="btn btn-white btn-block mt-3">
                   Vratite se nazad
               </Link>

           </div>
        </div>
    }

    componentDidMount() {
        getOffices().then((response) => {
            this.setState({...this.state, offices: response.data});
        })
    }
}
export default Authorization;
