import React from 'react';
import {patchUser} from "../../../app/hooks";
import UserForm from "../user/UserForm";
import routes from "../../../app/routes";


class User extends React.Component {

    render = () => {
        return <div className="container mt-4 mb-5" style={{maxWidth: 800}}>
            <div className="title mb-5">{routes.user.name}</div>

            <UserForm
                id={this.props.match.params.id}
                hook={patchUser} />
        </div>
    }
}
export default User;
