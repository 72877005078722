import React from 'react';
import UserForm from "./UserForm";
import routes from "../../../app/routes";
import {putUser} from "../../../app/hooks";


class UserRegister extends React.Component {


    render = () => {
        return <div className="container mt-4 mb-5" style={{maxWidth: 800}}>
            <div className="title mb-5">{routes.userRegister.name}</div>

            <UserForm hook={putUser} reset={true} />
        </div>
    }
}
export default UserRegister;
