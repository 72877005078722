import React from 'react';
import Form from "../../framework/form/Form";
import logo from "../../../assets/images/logo.png"
import {postUserLogin} from "../../../app/hooks";
import {loginUser} from "../../../providers/userProvider";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";

class Login extends React.Component {

    state = {
        form: {
            username: "",
            password: "",
            remember: false,
        }
    };

    onSuccess = (response, form) => {
        let user = loginUser(response, this.state.form.remember);
        if(user) {
            window.location.reload();
        }
    }


    render = () => {
        return <div className="d-flex justify-content-center align-items-center vh-100">
           <div className="auth_form">
               <div className="text-center mb-3">
                    <img src={logo} width={160} className="img-fluid" alt="logo" />
               </div>
               <Form form={this.state.form} onSuccess={this.onSuccess} hook={postUserLogin}>
                   <div className="mb-2">
                       <label className="w-100">
                           <div className="font-weight-bold mb-1">Korisničko ime: </div>
                           <input type="text" name="username" className="form-control" autoComplete="off" required />
                       </label>
                   </div>
                   <div className="mb-2">
                       <label className="w-100">
                           <div className="font-weight-bold mb-1">Lozinka: </div>
                           <input type="password" name="password" className="form-control" autoComplete="off" required />
                       </label>
                   </div>
                   <div className="mb-2">
                       <label>
                           <span className="mr-2 chbx">
                               <input type="checkbox" name="remember" /><span />
                           </span>
                           Zapamti me?
                       </label>
                   </div>

                   <input type="submit" className="btn btn-primary btn-block" value="Prijavite se" />
               </Form>
               <Link
                   to={routes.authorization.route}
                   className="btn btn-white btn-block mt-3">
                   Zaboravili ste lozinku ili korisničko ime?
               </Link>
           </div>
        </div>
    }
}
export default Login;
