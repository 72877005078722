import React from 'react';
import {deletePatient, getAppointments, getUsers, patchAppointment, patchAppointmentReport} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import Pagination from "../../framework/Pagination";
import moment from "moment";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {price} from "../../../app/helpers";
import {diagnosis} from "../../../providers/diagnosis";


class Appointments extends React.Component {

    state = {
        loading: true,
        patients: {},
        doctors: [],
        dateCountSelect: this.props.user.is_doctor ? 1 : "all",
        form: {
            page: 1,
            searchPatient: "",
            pagination: true,
            doctor: (this.props.user.is_doctor && !this.props.modal) ? this.props.user.id : "",
            subtract: "all",
            add: "all",
            patient: this.props.patient,
            operation: "",
            rfzo: false,
            start: (this.props.user.is_doctor && !this.props.modal)
                ? `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + (new Date().getDate())).slice(-2)}` : "",
            end: (this.props.user.is_doctor && !this.props.modal)
                ? `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + (new Date().getDate())).slice(-2)}` : ""
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let  newState = {...this.state, form: {...this.state.form, page: 1, [name]: value}};
        this.setState(newState);
        this.load(newState.form);
    }

    changeDateCount = (days) => {
        let subtract = 0, add = 1;
        if(days === "1")      {
            subtract = 0; add = 1;
        }
        if(days === "3")      {
            subtract = -1; add = 2;
        }
        if(days === "7")      {
            subtract = -1; add = 6;
        }
        if(days === "14")      {
            subtract = -4; add = 12;
        }
        if(days === "30")      {
            subtract = -10; add = 20;
        }
        if(days === "all")      {
            subtract = "all"; add = "all";
        } else  {
            days = parseInt(days);
        }
        const newState = {...this.state, dateCountSelect: days, form: {...this.state.form, subtract: subtract, add: add}};
        this.setState(newState);
        this.load(newState.form);
    }
    left = () => {
        let subtract = this.state.form.subtract - this.state.dateCountSelect;
        let add = subtract + (this.state.dateCountSelect);
        const newState = {...this.state, form: {...this.state.form, subtract: subtract, add: add}};
        this.setState(newState);
        this.load(newState.form);
    }
    right = () => {
        let subtract = this.state.form.subtract + this.state.dateCountSelect;
        let add = subtract + (this.state.dateCountSelect);
        const newState = {...this.state, form: {...this.state.form, subtract: subtract, add: add}};
        this.setState(newState);
        this.load(newState.form);
    }


    delete = (item) => {
        var r = window.confirm(`Želite da obrišete pacijenta ${item.name} ${item.lastname}?`);
        if (r !== true) {
            return false;
        }
        deletePatient({id: item.id}).then((response) => {
            this.load();
        });
    }

    selected = (item) => {
        this.props.drawer.callback(item);
        this.props.closeDrawer('patients');
    }

    patchAppointmentAndReload = (id, field, value) => {
        patchAppointment({id, field, value}).then((response) => {
            this.load();
        });
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.apointments.name}</div>


            <div className="mb-3 d-flex align-items-baseline form-inline">
                {/*<div>
                    <div className="d-flex mb-2 mr-3">
                        {this.state.form.subtract !== "all" &&
                        <button
                            onClick={this.left}
                            className="btn btn-white mr-1">
                            <i className="fa fa-chevron-left" />
                        </button>}

                        <div>
                            <select value={this.state.dateCountSelect}
                                    onChange={(e) => this.changeDateCount(e.target.value)} className="form-control">
                                <option value={1}> 1 </option>
                                <option value={3}> 3 </option>
                                <option value={7}> 7 </option>
                                <option value={14}> 14 </option>
                                <option value={30}> 30 </option>
                                <option value="all"> SVE </option>
                            </select>
                        </div>
                        {this.state.form.subtract !== "all" &&
                        <button
                            onClick={this.right}
                            className="btn btn-white ml-1">
                            <i className="fa fa-chevron-right" />
                        </button>}
                    </div>
                    {this.state.form.subtract !== "all" &&
                    <div className="text-center">
                        {moment().add(this.state.form.subtract,'days').format("DD.MM.YY.").toString()}
                        &nbsp; - &nbsp;
                        {moment().add(this.state.form.add-1,'days').format("DD.MM.YY.").toString()}
                    </div>}
                </div>*/}

                <div className="mr-3">
                    Izmedju:
                    <span className="position-relative">
                    <input
                        value={this.state.form.start}
                        onChange={(e) => {
                            let  newState = {...this.state, form: {...this.state.form, page: 1, start: e.target.value}};
                            this.setState(newState);
                            this.load(newState.form);
                        }}
                        type="date" className="form-control mr-1" />
                    </span>
                    <span className="position-relative">
                         <input
                             value={this.state.form.end}
                             onChange={(e) => {
                                 console.log(e.target.value)
                                 let  newState = {...this.state, form: {...this.state.form, page: 1, end: e.target.value}};
                                 this.setState(newState);
                                 this.load(newState.form);
                             }}
                             type="date" className="form-control mr-1" />
                    </span>
                </div>

                {!this.props.user.is_doctor &&
                <select name="doctor" className="form-control mr-3"
                        value={this.state.form.doctor} onChange={this.changeField}>
                    <option value="">Sve</option>
                    {this.state.doctors.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>}

                <input
                    value={this.state.form.searchPatient}
                    name="searchPatient"
                    className="form-control mr-2"
                    onChange={this.changeField}
                    type="text" placeholder="Pretraga pacijenta..." />


                <select name="operation" className="form-control mr-3"
                        value={this.state.form.operation} onChange={this.changeField}>
                    <option value="">{this.state.form.operation ? "Sve" : "Operacija"}</option>
                    <option value="zakazane">Operacija (zakazane)</option>
                    <option value="nezakazane">Operacija (nezakazane)</option>
                    <option value="sve">Operacija (zak. + nezak.)</option>
                    <option value="obavljeno">Operacija (obavljeno)</option>
                    <option value="otkazano">Operacija (otkazano)</option>
                </select>

                <label>
                    <input checked={this.state.form.rfzo} onChange={(e) => {
                        const value = e.target.checked;
                        let  newState = {...this.state, form: {...this.state.form, page: 1, rfzo: value}};
                        this.setState(newState);
                        this.load(newState.form);
                    }} type="checkbox" className="mr-2" /> RFZO
                </label>
            </div>

            {this.state.appointments.data.length > 0
                ? <>
                    <table className="table">
                        <thead className="font-weight-bold">
                        <tr>
                            <td style={{minWidth: 200}}> Ime i Prezime </td>
                            <td> Pol </td>
                            <td>Godine</td>
                            <td> Doktor </td>
                            <td> Pregledi </td>
                            <td width={140}> Ukupna cena </td>
                            <td> Dijagnostika obavljena </td>
                            <td> Zenice proširene </td>
                            <td> Operacija </td>
                            <td>Početak</td>
                            <td width={140}> </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.appointments.data.map((item) => {
                            let full_price = 0;
                            return <tr key={item.id} className="mb-2">
                                <td>
                                    <button className="btn btn-link text-left "
                                        onClick={() => this.props.openDrawer({name: "patient", patient: item.patient})}>
                                    {item.patient?.name} {item.patient?.lastname}
                                </button>
                                </td>
                                <td> {item.patient?.sex === 1 ? "M" : "Ž"} </td>
                                <td> {moment().diff(moment(item.patient?.dob),'years')}</td>
                                <td>
                                    <button className="btn btn-link text-left"
                                            onClick={() => this.props.openDrawer({name: "user", user: item.user})}>
                                        {item.user.title} {item.user.name} {item.user.lastname}
                                    </button>
                                </td>
                                <td>
                                    {item.surgery && <div className="alert alert-success mb-1 p-1">HIRURGIJA</div>}
                                    <div className="font-weight-bold">
                                        <span style={{color: item.status_color}}>{item.status_name}</span>

                                        {(this.props.user.is_admin || this.props.user?.is_nurse) &&
                                        <select value={item.payment}
                                            onChange={(e) => this.patchAppointmentAndReload(item.id, "payment", e.target.value)} className="ml-3">
                                            <option value={1}> 1 </option>
                                            <option value={2}> 2 </option>
                                        </select>}
                                    </div>

                                    {item.examinations.map((exam) => {
                                        const processPrice = exam.pivot.override_price || exam.copied_price;
                                        full_price = full_price + parseFloat(processPrice || 0);
                                        return <div key={exam.id} className="font-weight-bold">
                                            <span className="mr-2">  {exam.name} </span>
                                            {exam.pivot.override_price
                                                ? <span className="red text-nowrap" title="Cena je prepravljena"> ({price(processPrice)}) </span>
                                                : <span className={`text-nowrap ${((this.props.user.is_admin || this.props.user?.is_nurse) && item.payment === 2) ? 'green' : ''}`}>
                                                    ({price(processPrice)})
                                                </span>}

                                            {(this.props.user.is_admin || this.props.user?.is_nurse) &&
                                            <span className="ml-2">  {exam.code} </span>}

                                        </div>
                                    })}
                                </td>
                                <td>
                                    {price(full_price)}
                                </td>
                                <td style={{maxWidth: 500}}>
                                    {item.report && diagnosis().map((arr) => {
                                        const diag = arr[0];
                                        if(!item.report[diag.code]) return "";
                                        return <span key={diag.code} className="border d-inline-block p-2 mr-2 mb-1 bg-white" style={{borderRadius: 8}}>
                                            {diag.name}
                                        </span>
                                    })}
                                </td>
                                <td>
                                    {item.report && item.report.zenice &&
                                    <i className="far fa-check-circle font_12 green" />}
                                </td>
                                <td>
                                    {item.report.hir_obavljeno &&
                                    <div className="font-weight-bold green">
                                        OBAVLJENO
                                    </div>}
                                    {item.report.hir_otkazano &&
                                    <div className="font-weight-bold red">
                                        OTKAZANO
                                    </div>}

                                    {item.report.hir_os &&
                                    <div className="font-weight-bold">OS</div>}
                                    {item.report.hir_od &&
                                    <div className="font-weight-bold">OD</div>}
                                    {item.report.hir_operacija &&
                                    <div className="font-weight-bold">{item.report.hir_operacija}</div>}
                                    <div className="mt-1">
                                        {item.report.hir_socivo} {item.report.hir_jacina_sociva && <span>({item.report.hir_jacina_sociva})</span>}
                                    </div>
                                    {item.report.hir_start &&
                                    <div className="font-weight-bold mt-1">
                                        {moment(item.report.hir_start).format("DD.MM.YYYY. HH:mm").toString()}
                                    </div>}
                                </td>
                                <td>
                                    {moment(item.start).format("DD.MM.YYYY. HH:mm").toString()}
                                </td>
                                <td>
                                    {this.props.modal
                                        ? <button
                                            onClick={() => this.props.load(item.report)}
                                            className="btn btn-info"> Učitajte izveštaj </button>
                                        : <>
                                            <button
                                                onClick={() => this.props.openDrawer({name: "pdf", appointment: item, callback: this.load})}
                                                className="btn btn-danger btn-block">
                                                <strong> PDF </strong>
                                            </button>
                                            {item.report &&
                                                item.surgery
                                                    ? <button
                                                    onClick={() => this.props.openDrawer({name: "report", appointment: item, callback: this.load})}
                                                    className="btn btn-success btn-block">
                                                    <i className="fas fa-first-aid font_2 mb-2" /><br />
                                                    <strong> operacija </strong>
                                                </button>
                                                    : <button
                                                onClick={() => this.props.openDrawer({name: "report", appointment: item, callback: this.load})}
                                                className="btn btn-info btn-block">
                                                <i className="far fa-clipboard font_2 mb-2" /> <br />
                                                <strong> izveštaj </strong>
                                            </button>}
                                        </>}
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.appointments.last_page} />
                </>
                : <div className="p-5 font_2">
                    Nema pregleda
                </div>}

        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getAppointments(form).then((response) => {
            this.setState({...this.state, loading: false, appointments: response.data});
        });
    }

    componentDidMount() {
        getUsers({all: true, active: true, doctors: true}).then((response) => {
            this.setState({...this.state, doctors: response.data});
        });
       this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.appointment,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
