import { combineReducers, createStore } from "redux";
import drawers from "./reducers/drawersReducer"
import user from "./reducers/userReducer"
import office from "./reducers/officeReducer"


const store = createStore(combineReducers({
    drawers: drawers,
    user: user,
    office: office,
}));

export default store;