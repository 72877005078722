import React from 'react';
import PatientForm from "./PatientForm";
import routes from "../../../app/routes";
import {putPatient} from "../../../app/hooks";


class PatientRegister extends React.Component {


    render = () => {
        return <div className="container mt-4 mb-5" style={{maxWidth: 800}}>
            <div className="title mb-5">{routes.patientRegister.name}</div>

            <PatientForm hook={putPatient} reset={true} />
        </div>
    }
}
export default PatientRegister;
