import React from "react";
import {Route, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import routes from "../../app/routes";
import CmsLayout from "../../views/layout/CmsLayout";


const UserMiddleware = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        rest.user === false
            ? <Redirect to={routes.login.route} />
            : <CmsLayout {...{component: Component, ...props}} />
    )}/>
};

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(UserMiddleware);
