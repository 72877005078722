import React from "react";

class Autocomplete extends React.Component {

    state = {
        value: this.props.default || "",
        autocomplete: []
    }

    change = (e) => {
        const value = e.target.value;
        this.setState({...this.state, value: value});
        this.props.route({value: value}).then((response) => {
            this.setState({...this.state, autocomplete: response.data});
        })
    }

    anchor = (e, item) => {
        e.preventDefault();
        this.setState({...this.state, value: item.name, autocomplete: []});
        this.props.onClick(item);
    }

    render = () => {
        return <div className="position-relative">
                <input
                    placeholder={this.props.placeholder || ""}
                    value={this.state.value}
                    onChange={this.change}
                    type="text" className="form-control" />
                {this.state.autocomplete.length > 0 &&
                <div className="autocomplete">
                    <ul className="link_button list-unstyled mb-0">
                    {this.state.autocomplete.map((item) => {
                        return <li key={item.id}>
                            <a href="/" onClick={(e) => this.anchor(e, item)}>
                                {this.props.render ? this.props.render(item) : item.name}
                            </a>
                        </li>
                    })}
                    </ul>
                </div>}
            </div>
    };

}

export default Autocomplete;
