import React from 'react';
import {connect} from "react-redux";


class Guard extends React.Component   {

    state = {
    }

    render = () => {
        let pass = false;
        if(this.props.doctor)   {
           if(this.props.user.is_doctor)   {
               pass = true;
           }
        }
        if(this.props.admin)   {
            if(this.props.user.is_admin)   {
                pass = true;
            }
        }
        if(!pass)   {
            return "";
        }
        if(!this.props.children)        {
            return "";
        }
        return <div className={"guard_block " + this.props.className || ""}>
            <div title={`Blok vidi:\n${this.props.doctor && "Doktor\n"}${this.props.admin && "Admin\n"}`} className="guard_info">
                <i className="fas fa-info-circle" />
            </div>
            {this.props.children}
        </div>
    };
}
const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Guard);
