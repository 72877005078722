import React from 'react';
import {deletePatient, getPatients} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import Pagination from "../../framework/Pagination";
import {Link} from "react-router-dom";
import moment from "moment";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";


class Patients extends React.Component {

    state = {
        loading: true,
        patients: {},
        form: {
            page: 1,
            search: ""
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let  newState = {...this.state, form: {...this.state.form, page: 1, [name]: value}};
        this.setState(newState);
        this.load(newState.form);
    }

    delete = (item) => {
        var r = window.confirm(`Želite da obrišete pacijenta ${item.name} ${item.lastname}?`);
        if (r !== true) {
            return false;
        }
        deletePatient({id: item.id}).then((response) => {
            this.load();
        });
    }

    selected = (item) => {
        this.props.drawer.callback(item);
        this.props.closeDrawer('patients');
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.patients.name}</div>


            <div className="mb-3 d-flex form-inline">
                <input
                    value={this.state.form.search}
                    name="search"
                    className="form-control mr-2"
                    onChange={this.changeField}
                    type="text" placeholder="Pretražite po imenu..." />
            </div>

            {this.state.patients.data.length > 0
                ? <>
                    <table className="table">
                        <thead className="font-weight-bold">
                        <tr>
                            {this.props.drawer.status &&
                            <td> </td>}
                            <td>Ime</td>
                            <td>Pol</td>
                            <td>Godine</td>
                            <td>Preporuka</td>
                            <td>Email</td>
                            <td>Telefon</td>
                            <td>Profesija</td>
                            <td width={120}> </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.patients.data.map((item) => {
                            return <tr key={item.id} className="mb-2">
                                {this.props.drawer.status &&
                                <td><button
                                    onClick={() => this.selected(item)}
                                    className="btn btn-sm btn-success">
                                    Izaberite
                                </button></td>}
                                <td>
                                    <button className="btn btn-info text-left mr-3 text-center pb-0" onClick={() => this.props.openDrawer({name: "patient", patient: item})}>
                                        <i className="fas fa-notes-medical font_6" /> <br/>
                                        Karton
                                    </button>
                                    <Link to={routes.patient.route(item.id)}>
                                    {item.name} {item.lastname}
                                </Link></td>
                                <td> {item.sex === 1 ? "M" : "Ž"} </td>
                                <td> {moment().diff(moment(item.dob),'years')}</td>
                                <td>
                                    {item.recommendation}
                                    {item.recommendation === "Saradnik" &&
                                    <div>
                                        {item.associate?.name}
                                    </div>}
                                </td>
                                <td>
                                    {item.email &&
                                    <a href={`mailto:${item.email}`}>{item.email}</a>}
                                </td>
                                <td>
                                    {item.phone &&
                                    <a href={`tel:${item.phone}`}>{item.phone}</a>}
                                </td>
                                <td> {item.profession} </td>
                                <td>
                                    <button
                                        onClick={() => this.delete(item)}
                                        className="btn btn-sm btn-danger">
                                        <i className="fa fa-trash" /> OBRIŠITE
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.patients.last_page} />
                </>
                : <div className="p-5 font_2">
                    Nema pacijenata
                </div>}

        </div>
    }

    load = (form) => {
        getPatients(form).then((response) => {
            this.setState({...this.state, loading: false, patients: response.data});
        });
    }

    componentDidMount() {
       this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.patients,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Patients);
