import React from 'react';
import {connect} from "react-redux";
import {getNotifications} from "../../app/hooks";
import moment from "moment";
import Drawer from "../framework/Drawer";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {notifications} from "../notifications/export";
import sound from "../../assets/sounds/notification.mp3";

class NotificationsButtonAndDrawer extends React.Component {

    state = {
        notifications: []
    }
    interval = false

    render = () => {
        return <>
            <Drawer
                width={460}
                zIndex={6}
                title="Notifikacije"
                show={this.props.drawer.status}
                close={() => this.props.closeDrawer("notifications")}>

                <div className="p-3">
                    {this.state.notifications.length > 0
                        ? this.state.notifications.map((item) => {
                            const Element = notifications()[item.component]
                            return <div key={item.id}>
                                <div className="mb-3 accent_block">
                                    <div className="mb-2">
                                        {moment(item.created_at).format("DD.MM. HH:mm").toString()}
                                    </div>

                                    <Element item={item} reload={this.load} />
                                </div>
                            </div>
                        })
                        : <div className="text-center p-3" style={{color: "gray"}}>Nema notifikacija.</div>}
                </div>
            </Drawer>

            <button
                onClick={() => this.props.openDrawer({name: "notifications"})}
                className="btn btn-white btn-sm mr-2 position-relative">
                {this.state.notifications.length > 0 &&
                <i className="notification_count">{this.state.notifications.length}</i>}
                <i className="far fa-bell" />
            </button>
        </>
    }

    load = () => {
        getNotifications().then((response) => {
            if(this.state.notifications.length < response.data.length)      {
                var audio = new Audio(sound);
                audio.play();
            }
            this.setState({...this.state, notifications: response.data});
        });
    }

    componentDidMount() {
        this.interval = setInterval(this.load, 3000);
       this.load();
    }
    componentWillUnmount() {
        if(this.interval)   {
            clearInterval(this.interval);
            this.interval = false;
        }
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.notifications,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsButtonAndDrawer);
