import React from 'react';
import {connect} from "react-redux";
import Form from "../../framework/form/Form";
import {getOffices, getRoles, getUser} from "../../../app/hooks";
import { SketchPicker } from 'react-color';
import Loader from "../../framework/Loader";


class UserForm extends React.Component {

    state = {
        form: {
            username: "",
            name: "",
            lastname: "",
            password: "",
            role_id: "",
            color: "",
            email: "",
            phone: "",
            offices: []
        },
        roles: [],
        offices: [],
        loading: true
    };

    changeOffices = (e, item) => {
       if(e.target.checked)     {
           this.setState({...this.state, form: {...this.state.form, offices: [...this.state.form.offices, item]}});
       } else   {
           const newOffices = this.state.form.offices.filter(office => office.id !== item.id);
           console.log(newOffices)
           this.setState({...this.state, form: {...this.state.form, offices: newOffices}});
       }
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        const inOffice = this.state.form.offices.map(item => item.id);
        return  <Form form={this.state.form} hook={this.props.hook} onSuccess={this.props.onSuccess} reset={this.props.reset}>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Korisničko ime: </div>
                <div className="col-md-9">
                    <input type="text" name="username" className="form-control" autoComplete="off" required />
                </div>
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Ime: </div>
                <div className="col-md-9">
                    <input type="text" name="name" className="form-control" autoComplete="off" required />
                </div>
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Prezime: </div>
                <div className="col-md-9">
                    <input type="text" name="lastname" className="form-control" autoComplete="off" required />
                </div>
            </div>

            {this.state.form.password === "" ?
            <div className="row align-items-center mb-5">
                <div className="col-md-3 text-right">* Lozinka: </div>
                <div className="col-md-9">
                    <input type="password" name="password" className="form-control" autoComplete="off" />
                </div>
            </div> : <div />}


            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Kategorija: </div>
                <div className="col-md-9">
                    <select name="role_id" className="form-control" required>
                        <option> </option>
                        {this.state.roles.map((item) => {
                            if(item.children.length > 0)        {
                                return item.children.map((child) => {
                                    return <option key={child.id} value={child.id}>{item.name} ({child.name})</option>
                                })
                            }
                            return <option key={item.id} value={item.id}>{item.name}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">E-mail adresa: </div>
                <div className="col-md-9">
                    <input type="email" name="email" className="form-control" autoComplete="off" />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">Broj telefona: </div>
                <div className="col-md-9">
                    <input type="text" name="phone" className="form-control" autoComplete="off" />
                </div>
            </div>

            <div className="row align-items-center mb-2" ignore="true">
                <div className="col-md-3 text-right"> Ordinacije: </div>
                <div className="col-md-9">
                    {this.state.offices.map((item, i) => {
                        return <div key={item.id}>
                           <label className="chbx">
                               <input type="checkbox" checked={inOffice.includes(item.id)}
                                      onChange={(e) => this.changeOffices(e, item)} /><span />
                               <span className="ml-2"> {item.name} </span>
                           </label>
                        </div>
                    })}
                </div>
            </div>

            <div className="row align-items-center mb-4">
                <div className="col-md-3 text-right">Boja operatera: </div>
                <div className="col-md-9">
                    <SketchPicker name="color"  />
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-md-3 text-right"> </div>
                <div className="col-md-9">
                    <input type="submit" className="btn btn-primary btn-block" value="Sačuvaj profil" />
                </div>
            </div>
        </Form>
    }

    async componentDidMount() {
        if(this.props.id)       {
            try {
                const response = await Promise.all([getRoles(), getUser({id: this.props.id})]);
                let form = response[1].data.id === this.props.user.id ? {...response[1].data, password: "", old_password: ""} : response[1].data;
                this.setState({...this.state, loading: false, roles: response[0].data, form: form});
            } catch (error) {

            }
        } else      {
            getRoles().then((response) => {
                this.setState({...this.state, loading: false, roles: response.data});
            });
        }
        getOffices().then((response) => {
            this.setState({...this.state, offices: response.data});
        })
    }
}
const mapStateToProps = state => ({
    user: state.user,
    office: state.office
});
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
