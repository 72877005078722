import React from 'react';
import {Link} from "react-router-dom";
import Dropdown from "../framework/Dropdown";
import routes from "../../app/routes";
import {connect} from "react-redux";
import {logoutUser} from "../../providers/userProvider";
import {setOffice} from "../../store/actions/officeActions";
import {changeOffice} from "../../providers/officeProvider";
import {openDrawer} from "../../store/actions/drawersActions";
import PatientsWaiting from "../drawers/PatientsWaiting";
import NotificationsButtonAndDrawer from "../drawers/NotificationsButtonAndDrawer";


class CmsHeader extends React.Component   {

    state = {
        ordination: 1,
        office: {}
    }

    logout = (e) => {
        e.preventDefault();
        logoutUser();
    }

    changeOffice = (e) => {
        const id = e.target.value;
        changeOffice(id);
    }

    render = () => {
        return <header className="mb-3">
            <PatientsWaiting />

            <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <Link to="/" className="btn btn-white btn-sm mr-2">
                       <i className="far fa-calendar-alt mr-1" /> Zakazivanje pregleda
                    </Link>
                    {this.state.office.surgery &&
                    <Link to={routes.surgery.route} className="btn btn-white btn-sm mr-2">
                        <i className="far fa-calendar-alt mr-1" /> Hirurgija
                    </Link>}
                    <Link to={routes.apointments.route} className="btn btn-white btn-sm mr-2">
                        <i className="fas fa-stethoscope mr-1" /> Registar pregleda
                    </Link>
                    <Link to={routes.program.route} className="btn btn-white btn-sm mr-2">
                        Oper. program
                    </Link>

                    <Dropdown className="mr-2" title={<span><i className="fas fa-folder-open" /> Kartoteka</span>}>
                        <Link to={routes.patientRegister.route} className="link_button">
                            {routes.patientRegister.name}
                        </Link>
                        <Link to={routes.patients.route} className="link_button">
                            {routes.patients.name}
                        </Link>
                    </Dropdown>

                    <Dropdown className="mr-2" title={<span><i className="fas fa-users mr-1" /> Administracija</span>}>
                        <Link to={routes.userRegister.route} className="link_button">
                            {routes.userRegister.name}
                        </Link>
                        <Link to={routes.users.route} className="link_button">
                            {routes.users.name}
                        </Link>
                    </Dropdown>

                    <Dropdown title={<span><i className="fas fa-cog mr-1" /> Podešavanja</span>}>
                        <Link to={routes.settingsOffices.route} className="link_button">
                            {routes.settingsOffices.name}
                        </Link>
                        <Link to={routes.settingsDiseases.route} className="link_button">
                            {routes.settingsDiseases.name}
                        </Link>
                        <Link to={routes.settingsExaminations.route} className="link_button">
                            {routes.settingsExaminations.name}
                        </Link>
                        <Link to={routes.settingsMachines.route} className="link_button">
                            {routes.settingsMachines.name}
                        </Link>
                        <Link to={routes.settingsRoles.route} className="link_button">
                            {routes.settingsRoles.name}
                        </Link>
                        <Link to={routes.settingsTemplates.route} className="link_button">
                            {routes.settingsTemplates.name}
                        </Link>
                    </Dropdown>

                    {this.props.user.is_admin &&
                    <Link to={routes.statistics.route} className="btn btn-white btn-sm ml-2">
                        {routes.statistics.name}
                    </Link>}

                    {this.props.user.offices.length > 1 &&
                    <div className="ml-2">
                        <select
                            value={this.props.office}
                            onChange={this.changeOffice}
                                className="form-control form-control-sm" style={{border: "3px solid #3f9ede"}}>
                            {this.props.user.offices.map((item) => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>}
                </div>

                <div>
                  <NotificationsButtonAndDrawer />

                    <Dropdown anchor="right" title={<span><i className="far fa-user mr-1" />{this.props.user.title} {this.props.user.name} {this.props.user.lastname}</span>}>
                        <Link to={routes.account.route} className="link_button">
                            {routes.account.name}
                        </Link>
                        <a href="/" onClick={this.logout} className="link_button"> Izlogujte se </a>
                    </Dropdown>
                </div>
            </div>
        </header>
    };

    componentDidMount() {
        let office;
        if(this.props.office)       {
            office = this.props.user.offices.find(item => parseInt(item.id) === parseInt(this.props.office));
        } else  {
            office = this.props.user.offices[0];
            this.props.setOffice(office.id);
        }
        document.querySelector('title').innerHTML = office.name;
        this.setState({...this.state, office: office});
    }
}
const mapStateToProps = state => ({
    user: state.user,
    office: state.office,
    drawers: state.drawers,
});
const mapDispatchToProps = {
    setOffice: setOffice,
    openDrawer: openDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(CmsHeader);
