import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import {openDrawer} from "../../../store/actions/drawersActions";
import {times} from "../../../app/helpers";
import {
    getAppointments,
    getAppointmentStatus,
    getUsersSchedules,
    patchAppointmentStatus,
    patchUserScheduleConfirm
} from "../../../app/hooks";
import Loader from "../../framework/Loader";
import CalendarNavigation from "./CalendarNavigation";
import CalendarModal from "./CalendarModal";
import Modal from "../../framework/Modal";
import routes from "../../../app/routes";


class Calendar extends React.Component {

    state = {
        preload: true,
        times: [],
        appointmentStatus: [],

        dates: [],
        subtract: -1,
        add: 6,
        doctor: this.props.user.is_doctor ? this.props.user.id : "",

        isSurgery: false,
        schedules: {},
        appointments: {},
        calendarModal: false,
        clickedTime: false,
    }

    clickedCell = (e, time) => {
        if(e.target.tagName === "SELECT") return false;
        this.setState({...this.state, calendarModal: true, clickedTime: time});
    }
    changeAppointmentStatus = (e, id) => {
        patchAppointmentStatus({id: id, status: e.target.value}).then((response) => {
            this.load();
        })
    }
    confirmSchedule = (e, id) => {
        if (!window.confirm(`Želite da potvrdite raspored (${e.target.value})?`)) {
            return false;
        }
        patchUserScheduleConfirm({id: id, value: e.target.value}).then((response) => {
            this.load();
        });
    }

    render = () => {
        if(this.state.preload)      {
            return  <Loader />
        }
        return <div className="container-fluid pb-5">

            <Modal
                width={660}
                reload={true}
                zIndex={5}
                title="Želite da:"
                open={this.state.calendarModal}
                close={() => this.setState({...this.state, calendarModal: false})}>

                <CalendarModal
                    time={this.state.clickedTime}
                    schedules={this.state.schedules}
                    appointments={this.state.appointments}
                    reload={this.load} />
            </Modal>

            <CalendarNavigation
                reload={this.load}
                subtract={this.state.subtract}
                doctor={this.state.doctor} />

            <div className="table-responsive p-2">
                <table className="table table-bordered calendar">
                    <thead className="font-weight-bold">
                    <tr className="primary_bcg">
                        <td style={{width: 50}} />
                        {this.state.dates.map((item) => {
                            const dateString = item.format("DD.MM. ddd").toString();
                            return <td key={dateString}>
                                {dateString}
                            </td>
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.times.map((timeString, index1) => {
                        return <tr key={timeString + "_" + index1}>
                            <td className="text-center p-1 font_08" style={{verticalAlign: "middle"}}>{timeString}</td>

                            {this.state.dates.map((item) => {
                                const dateString = item.format("DD.MM.YYYY.").toString();
                                const time = moment(timeString + " " + dateString, "HH:mm DD.MM.YYYY.");

                                const ordinations = {};
                                const renderDoctors = [];
                                const currentSchedules = [];
                                if(this.state.schedules[dateString])      {
                                    for(let i=0; i<this.state.schedules[dateString].length; i++)      {
                                        if(this.state.isSurgery) continue;
                                            const schedule = this.state.schedules[dateString][i];
                                        if(time.isBetween(schedule.start.clone().subtract(1, "seconds"), schedule.end))        {
                                            const classes = "calendar_doctor_bar lvl_" + schedule.lvl;
                                            currentSchedules.push(schedule);
                                            if(schedule.ordination)       {
                                                ordinations[schedule.user_id] = schedule.ordination;
                                            }
                                            let name = "";
                                            let status = "";
                                            if(schedule.start.isSame(time))      {
                                                name = <span> {schedule.user.title + " " + schedule.user.lastname} </span>
                                                if(!schedule.confirmed_date)       {
                                                    status = <select
                                                        title="Nije potvrđeno radno vreme."
                                                        onChange={(e) => this.confirmSchedule(e, schedule.id)}
                                                        className="calendar_schedule_not_confirmed">
                                                        <option value="">Nije potvđeno</option>
                                                        <option value="telefon">Potvđeno telefonom</option>
                                                        <option value="sms">Potvđeno sms-om</option>
                                                        <option value="email">Potvđeno putem e-maila</option>
                                                        <option value="viber">Potvđeno putem viber-a</option>
                                                    </select>
                                                } else  {
                                                    status = <div
                                                        title={`Potvrđen raspored: ${moment(schedule.confirmed_date).format("DD.MM. HH:mm").toString()}\nPutem: ${schedule.confirmed_method}a \nOd strane: ${schedule.confirmer.name} ${schedule.confirmer.lastname}`}
                                                        className="calendar_schedule_confirmed">
                                                        &#10003;
                                                    </div>
                                                }
                                            }
                                            renderDoctors.push(<div key={schedule.id} className={classes} style={{background: schedule.user.color, color: schedule.user.color}}>
                                                {status}
                                                {name}
                                            </div>)
                                        }
                                    }
                                }

                                const renderPatients = [];
                                if(this.state.appointments[dateString])      {
                                    for(let i=0; i<this.state.appointments[dateString].length; i++)      {
                                        const appointment = this.state.appointments[dateString][i];
                                        if(appointment.start > appointment.end) {
                                            const start = appointment.start;
                                            appointment.start = appointment.end;
                                            appointment.end = start;
                                        }
                                        if(appointment.status_id === 4) continue;
                                        if(time.isBetween(appointment.start.clone().subtract(1, "seconds"), appointment.end))        {
                                            const classes = "col";
                                            let name = "";
                                            let status = "";
                                            let ordination = "";
                                            let doctor = "";
                                            if(appointment.start.isSame(time))      {
                                                doctor = <div>
                                                    ({appointment.user.title + " " + appointment.user.lastname})
                                                </div>
                                                name =  <span>{appointment.patient?.name} {appointment.patient?.lastname}<br/> {appointment.start.format("HH:mm").toString()} - {appointment.end.format("HH:mm").toString()}</span>
                                                status =  <select
                                                    value={appointment.status_id}
                                                    title={appointment.status_name}
                                                    onChange={(e) => this.changeAppointmentStatus(e, appointment.id)} className="calendar_patient_status">
                                                    {this.state.appointmentStatus.map((item) => {
                                                        return <option key={item.id} value={item.id} style={{background: "white"}}>{item.name}</option>
                                                    })}
                                                </select>
                                                if(ordinations[appointment.user.id])      {
                                                    ordination =  <div
                                                        title={`Ordinacija broj ${ordinations[appointment.user.id]}`}
                                                        className="calendar_ordination" style={{background: appointment.status_color}}>
                                                        {ordinations[appointment.user.id]}
                                                    </div>
                                                }
                                            }
                                            renderPatients.push(<div key={appointment.id} className={classes} style={{background: appointment.user.color}}>
                                                <div className="position-absolute" style={{borderRight: "8px solid " + appointment.status_color, height: "100%", right: 0}} />
                                                {name}
                                                {status}
                                                {this.state.isSurgery && doctor}
                                                {ordination}
                                            </div>)
                                        }
                                        else    {
                                            for(let i=0; i<this.state.appointments[dateString].length; i++)      {
                                                const app = this.state.appointments[dateString][i];
                                                if(app.id !== appointment.id)   {
                                                    if(appointment.start.isBetween(app.start, app.end) || appointment.end.isBetween(app.start, app.end))        {
                                                        if(time.isBetween(app.start.clone().subtract(1, "seconds"), app.end)) {
                                                            renderPatients.push(<div key={appointment.id} className="col"/>);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                return <td key={dateString} className={moment().isSame(time, 'day') ? "calendar_cell selected" : "calendar_cell"}
                                           onClick={(e) => this.clickedCell(e, time)}>
                                    {renderDoctors}
                                    {renderPatients.length > 0
                                        ?<div className={"calendar_patients row lvl_" + (currentSchedules.length > 0 ? currentSchedules[currentSchedules.length - 1].lvl : 1)}>
                                            {renderPatients.reverse()}
                                        </div>
                                        : <div className="calendar_timestamp text-center">{time.format("HH:mm ddd").toString()}</div>}
                                </td>

                            })}

                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    }

    load = (subtract, add, doctor) => {
        if(subtract === 0)       {}
        else if(!subtract)       {
            subtract = this.state.subtract;
            add = this.state.add;
        }
        if(doctor === "")   {}
        else if(!doctor)     {
            doctor = this.state.doctor;
        }
        let start = moment().add(subtract,'days');
        let end = moment().add(add-1,'days');

        const diff = end.diff(start,'days');
        var days = [];
        for (var i = 0; i <= diff; i++) {
            days.push(start.clone().add(i, 'days'));
        }
        this.setState({...this.state, dates: days, subtract: subtract, add: add, doctor: doctor, preload: false});

        getUsersSchedules({subtract: subtract, add: add, user: doctor}).then((response) => {
            const schedules = response.data;
            const schedulesHolder = {};
            for(let i=0; i<schedules.length; i++) {
                const dateString = moment(schedules[i].start).format("DD.MM.YYYY.").toString();
                if(!schedulesHolder[dateString]) schedulesHolder[dateString] = [];
                if(schedulesHolder[dateString].length === 0)  {
                    schedulesHolder[dateString].push({...schedules[i], start: moment(schedules[i].start), end: moment(schedules[i].end), lvl: 1});
                } else  {
                    let lvl = 1;
                    for(let j=0; j<schedulesHolder[dateString].length; j++) {
                        const compare = schedulesHolder[dateString][j];
                        if(compare.end.isAfter(moment(schedules[i].start)))        {
                            lvl = lvl + 1;
                        }
                    }
                    schedulesHolder[dateString].push({...schedules[i], start: moment(schedules[i].start), end: moment(schedules[i].end), lvl: lvl});
                }
            }
            this.setState({...this.state, schedules: schedulesHolder});
        });
        const isSurgery = (this.props.history.location.pathname === routes.surgery.route);
        getAppointments({subtract: subtract, add: add, doctor: doctor, surgery: isSurgery}).then((response) => {
            const appointmentsHolder = {};
            for(let i=0; i<response.data.length; i++) {
                const dateString = moment(response.data[i].start).format("DD.MM.YYYY.").toString();
                if(!appointmentsHolder[dateString]) appointmentsHolder[dateString] = [];
                appointmentsHolder[dateString].push({...response.data[i], start: moment(response.data[i].start), end: moment(response.data[i].end)});
            }
            this.setState({...this.state, appointments: appointmentsHolder, isSurgery: isSurgery});
        });
    }

    componentDidMount() {
        getAppointmentStatus().then((response) => {
            this.setState({...this.state, appointmentStatus: response.data, times: times()});
        })
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname)    {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
}
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
