import React from 'react';
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {getAppointments, getExaminations, getMachines, getUsers} from "../../../app/hooks";
import {PDFViewer} from "@react-pdf/renderer";
import PdfRenderer from "./PdfRenderer";
import SurgeryRenderer from "./SurgeryRenderer";


class PdfDrawer extends React.Component {


    state = {
        appointment: false,
        office: false,
        examinations: [],
        machines: false,
        users: [],
        style: {
            fontSize: 9,
            reportName: "",
        },
        lista: "operativna"
    }

    change = (e) => {
        const name = e.target.name;
        this.setState({...this.state, style: {...this.state.style, [name]: e.target.value}});
    }

    render = () => {
        const app = this.state.appointment;
        if(!app) return <div className="h-100" style={{background: "gray"}} />;

        return <div className="h-100" style={{background: "gray"}}>
            <div className="row m-0 p-0 h-100">
                <div className="col-md-3 h-100">

                    <div className="mb-5">
                        <button
                            onClick={() =>  this.props.closeDrawer("pdf")}
                            className="btn btn-white font_09 mt-2">Zatvorite PDF izveštaj</button>
                    </div>

                    <div className="text-white">

                         <div className="mb-3">
                            Naslov:
                            <textarea name="reportName" value={this.state.style.reportName} className="form-control"
                                   onChange={this.change} />
                        </div>

                        Veličina fonta: <select name="fontSize" onChange={this.change} className="form-control mb-4" value={this.state.style.fontSize}>
                            {[8,9,10,11,12,13,14,15,16,17,18,19,20].map((item) => {
                                return <option key={item} value={item}> {item} </option>
                            })}
                    </select>

                        {this.state.appointment.surgery &&
                        <div>
                            <div>
                                <button onClick={() => {
                                    this.setState({...this.state, lista: "operativna", style: {...this.state.style, reportName: "OPERATIVNA LISTA"}});
                                }} className="btn btn-white mb-2">
                                    OPERATIVNA LISTA
                                </button>
                            </div>
                            <div>
                                <button onClick={() => {
                                    this.setState({...this.state, lista: "otpusna", style: {...this.state.style, reportName: "OTPUSNA LISTA"}});
                                }} className="btn btn-white">
                                    OTPUSNA LISTA
                                </button>
                            </div>
                        </div>}
                    </div>

                </div>
                <div className="col-md-9 h-100">
                    {this.state.machines && this.state.appointment &&
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        {this.state.appointment.surgery
                            ?   <SurgeryRenderer
                                lista={this.state.lista}
                                fontSize={this.state.style.fontSize}
                                reportName={this.state.style.reportName}
                                appointment={this.state.appointment}
                                users={this.state.users}
                                office={this.state.office} />

                            :   <PdfRenderer
                                fontSize={this.state.style.fontSize}
                                reportName={this.state.style.reportName}
                                appointment={this.state.appointment}
                                machines={this.state.machines}
                                office={this.state.office} />}
                    </PDFViewer>}
                </div>
            </div>

        </div>
    }


    load = (id) => {
        this.setState({...this.state, appointment: false});
        getAppointments({id: id, subtract: "all", images: true}).then((response) => {
            const name = response.data.surgery ? "OPERATIVNA LISTA" : "Specijalistički izveštaj";
            this.setState({...this.state, appointment: response.data, style: {...this.state.style, reportName: name}});
        });
    }

    componentDidMount() {
        getExaminations().then((response) => {
            const office = this.props.user.offices.find(item => parseInt(item.id) === parseInt(this.props.office));
            this.setState({...this.state, examinations: response.data, office: office});
        });
        getMachines().then((response) => {
            this.setState({...this.state, machines: response.data});
        })
        getUsers({all: true, active: true}).then((response) => {
            this.setState({...this.state, users: response.data});
        })
        setTimeout(() => {
            this.load(this.props.drawer.appointment.id);
        }, 500);

    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.pdf,
    office: state.office
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(PdfDrawer);
