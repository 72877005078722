import React from 'react';
import {connect} from "react-redux";
import {PDFViewer, Document, Image, Page, Text, StyleSheet, View, Font} from "@react-pdf/renderer";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import moment from "moment";

const styles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    }
});

class ProgramPdfDrawer extends React.Component {


    state = {

    }


    render = () => {
        if(!this.props.drawer.appointments)  return null;
        const appointment = this.props.drawer.appointments[0];
        const report = appointment.report;
        return <div className="h-100" style={{background: "gray"}}>
            <div className="mb-1 mr-2 text-right">
                <button
                    onClick={() =>  this.props.closeDrawer("program")}
                    className="btn btn-white font_09 mt-2">Zatvorite PDF izveštaj</button>
            </div>

            <PDFViewer  style={{width: '100%', height: '100%'}}>
                <Document title="PROGRAM">
                    <Page size="LETTER" wrap style={{padding: 20, fontFamily: 'Roboto', fontSize: 14}}>
                        <View style={{display: "flex", flexDirection: "row", fontWeight: "bold", marginBottom: 20}}>
                            {report.doctor
                                ? <Text style={{marginRight: 20}}>
                                    {report.doctor.title} {report.doctor.name} {report.doctor.lastname}
                                </Text>
                                : <View />}
                            <Text style={{marginRight: 20, fontStyle: "italic"}}>
                                (od {moment(report.hir_start).add(1, "hour").format("HH:mm")}h)
                            </Text>
                            <Text>
                                {moment(report.hir_start).format("DD.MM.YYYY.")}
                            </Text>
                        </View>

                        <View style={styles.table}>

                            <View style={{...styles.tableRow, fontWeight: "bold"}}>
                                <View style={{...styles.tableCol, width: '4%'}}>
                                    <Text style={styles.tableCell}> </Text>
                                </View>
                                <View style={{...styles.tableCol, width: '25%'}}>
                                    <Text style={styles.tableCell}>Ime i prezime</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Vreme dolaska</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Oko</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Vrsta sočiva</Text>
                                </View>
                                <View style={{...styles.tableCol, width: '16%'}}>
                                    <Text style={styles.tableCell}>Operacija</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Jačina sočiva</Text>
                                </View>
                                <View style={{...styles.tableCol, width: '15%'}}>
                                    <Text style={styles.tableCell}>Komentar</Text>
                                </View>
                            </View>

                            {this.props.drawer.appointments.map((item, j) => {
                                return <View key={item.id} style={styles.tableRow}>
                                    <View style={{...styles.tableCol, width: '4%'}}>
                                        <Text style={styles.tableCell}>{j+1}</Text>
                                    </View>
                                    <View style={{...styles.tableCol, width: '25%'}}>
                                        <View style={styles.tableCell}>
                                            <Text>{item.patient.lastname} {item.patient.name}</Text>
                                            <Text>  {moment(item.patient.dob).format("YYYY")} ({moment().diff(moment(item.patient.dob),'years')})</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {moment(item.report.hir_start).format("HH:mm")}h
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.report.hir_os && "OS"} {item.report.hir_od && "OD"}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.report.hir_socivo}
                                        </Text>
                                    </View>
                                    <View style={{...styles.tableCol, width: '16%'}}>
                                        <Text style={styles.tableCell}>
                                            {item.report.hir_operacija}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            {item.report.hir_jacina_sociva}
                                        </Text>
                                    </View>
                                    <View style={{...styles.tableCol, width: '15%'}}>
                                        <Text style={styles.tableCell}>
                                            {item.report.hir_komentar}
                                        </Text>
                                    </View>
                                </View>
                            })}
                        </View>
                    </Page>
                </Document>

            </PDFViewer>
        </div>
    }

    componentDidMount() {

    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.program,
    office: state.office
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramPdfDrawer);
