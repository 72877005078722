import React from "react";
import axios from "axios";
import {server} from "../../../app/helpers";


class FileUploader extends React.Component {

    state = {
        maxSize: 12, // MB
        preload: false,
        loader: 30
    }



    render = () => {
        if(!this.state.preload) return "";
        return <div className="storage_preload">
            <div className="storage_preload_loader"><div style={{width: this.state.loader + "%"}} /></div>
            <img src={this.state.preload} width={120} alt="preloader" />
        </div>
    };

    componentDidMount() {
        const file = this.props.file;

        let size = file.size/1000000;  // BYTES to MEGABYTES
        if(size > this.state.maxSize) {
            alert('Fajl ' + file.name + ' je veci od '+ this.state.maxSize +' MB');
            return false;
        }

        // SET PRELOAD
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target.result) {
                const src = file.name.endsWith(".pdf") ? '/images/000-pdf.png' : e.target.result;
                this.setState({...this.state, preload: src});
            }
        }
        reader.readAsDataURL(file);

        // UPLOAD
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('bucket', this.props.bucket);
        formData.append('full', 'true');
        axios({
            method: 'POST',
            url: server('file'),
            data: formData,
            onUploadProgress: progressEvent => {
                let previewProcess = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({...this.state, loader: previewProcess});
                if(previewProcess === 100 && this.state.preload)  {

                }
            }
        }).then((response) => {
            this.setState({...this.state, preload: false});
            this.props.reload();
        });
    }
}

export default FileUploader;
