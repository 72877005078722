import React from 'react';
import {patchOffice} from "../../../app/hooks";
import {gallery, times} from "../../../app/helpers";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";


class OfficeItem extends React.Component {

    state = {
        edit: false,
        item: this.props.item,
        times: times(true),
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, item: {...this.state.item, [name]: value}});
    }

    save = (e, reload=false) => {
        this.editField(e);
        const name = e.target.name;
        const value = e.target.value;
        patchOffice({id: this.props.item.id, name: name, value: value}).then((response) => {
           this.props.reload(reload);
        });
    }
    saveCheckbox = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if(!e.target.checked)        {
            value = null;
        }
        patchOffice({id: this.props.item.id, name: name, value: value}).then((response) => {
            this.props.reload(true);
        });
    }


    toggleEdit = () => {
        this.setState({...this.state, edit: !this.state.edit});
    }

    render = () => {
        const item = this.state.item;
        if(this.state.edit)     {
            return <tr className="mb-2">
                <td>
                    <div className="font-italic font_08 text-center mb-1">Kliknite na sliku da promenite</div>
                    <img src={gallery(item.logo_name)}  className="img-fluid" alt="logo"
                         onClick={() => this.props.openDrawer({name: "files", callback: (files) => this.save({target: {name: "logo_id", value: files[0].id}}, true)})} />
                </td>
                <td>
                    <input type="text" className="form-control" name="name" maxLength={191}
                           onChange={this.editField} onBlur={this.save} value={item.name} />
                </td>
                <td>
                    <label className="chbx">
                        <input type="checkbox" checked={!!item.surgery}
                               name="surgery" onChange={this.saveCheckbox} value={1} /><span /> Hirurgija
                    </label>
                </td>
                <td>
                    <input type="number" className="form-control" name="ordinations"
                           onChange={this.editField} onBlur={this.save} value={item.ordinations || ""} />
                </td>
                <td>
                    <select name="start" onChange={this.save} style={{width: 200}}
                            value={item.start} className="form-control">
                        {this.state.times.map((start) => {
                            return <option key={start} value={start}>
                                {start}
                            </option>
                        })}
                    </select>
                </td>
                <td>
                    <select name="end" onChange={this.save} style={{width: 200}}
                            value={item.end} className="form-control">
                        {this.state.times.map((end) => {
                            return <option key={end} value={end}>
                                {end}
                            </option>
                        })}
                    </select>
                </td>
                <td>
                    <img src={gallery(item.report_logo_name)}  className="img-fluid" alt="report logo"
                         onClick={() => this.props.openDrawer({name: "files", callback: (files) => this.save({target: {name: "report_logo_id", value: files[0].id}}, true)})} />
                </td>
                <td>
                    <img src={gallery(item.report_footer_name)}  className="img-fluid" alt="report logo"
                         onClick={() => this.props.openDrawer({name: "files", callback: (files) => this.save({target: {name: "report_footer_id", value: files[0].id}}, true)})} />
                </td>
                <td>
                    <button onClick={this.toggleEdit} className="btn btn-info btn-block">Zatvorite</button>
                </td>
            </tr>
        }
        return <tr className="mb-2">
            <td>  {item.logo_name &&
            <img src={gallery(item.logo_name)} className="img-fluid" alt="logo" />} </td>
            <td> {item.name} </td>
            <td> {item.surgery && <i className="far fa-check-circle font_12 green" aria-hidden="true"/>} </td>
            <td> {item.ordinations} </td>
            <td> {item.start} </td>
            <td> {item.end} </td>
            <td>  {item.report_logo_name &&
            <img src={gallery(item.report_logo_name)} className="img-fluid" alt="logo" />} </td>
            <td>  {item.report_footer_name &&
            <img src={gallery(item.report_footer_name)} className="img-fluid" alt="logo" />} </td>
            <td>
                <button onClick={this.toggleEdit} className="btn btn-info btn-block">Prepravite</button>
            </td>
        </tr>
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
}
export default connect(mapStateToProps, mapDispatchToProps)(OfficeItem);
