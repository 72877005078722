
export function diagnosis()     {
    return [
        [{name: "FOKO", code: "foko", type: "checkbox"}, {name: "NAPOMENA", code: "foko_napomena", type: "textarea"}],
        [{name: "REF", code: "ref", type: "checkbox"}, {name: "NAPOMENA", code: "ref_napomena", type: "textarea"}],
        [{name: "KER", code: "ker", type: "checkbox"}, {name: "NAPOMENA", code: "ker_napomena", type: "textarea"}],
        [{name: "TOPO", code: "topo", type: "checkbox"}, {name: "NAPOMENA", code: "topo_napomena", type: "textarea"}],
        [{name: "IOP", code: "iop", type: "checkbox"}, {name: "NAPOMENA", code: "iop_napomena", type: "textarea"}],
        [{name: "FUN", code: "fun", type: "checkbox"}, {name: "NAPOMENA", code: "fun_napomena", type: "textarea"}],
        [{name: "ABER", code: "aber", type: "checkbox"}, {name: "NAPOMENA", code: "aber_napomena", type: "textarea"}],
        [{name: "PAHI", code: "pahi", type: "checkbox"}, {name: "NAPOMENA", code: "pahi_napomena", type: "textarea"}],
        [{name: "OCTMAC", code: "octmac", type: "checkbox"}, {name: "NAPOMENA", code: "octmac_napomena", type: "textarea"}],
        [{name: "OCTANT", code: "octant", type: "checkbox"}, {name: "NAPOMENA", code: "octant_napomena", type: "textarea"}],
    ]
}
