import React from 'react';


class Page404 extends React.Component {

    render = () => {
        return <div className="p-5 font-weight-bold text-center font_4">
            Ne postoji stranica.
        </div>
    }
}

export default Page404;