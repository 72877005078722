import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import "./assets/css/style.scss";
import App from './app/App';

import { Provider } from "react-redux";
import store from "./store/store";

import './providers/axiosIntercept';

import * as serviceWorker from './app/serviceWorker';

import moment from "moment";
moment.updateLocale("rs", {
    months : [
        "Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul",
        "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"
    ],
    weekdays: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    weekdaysShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
});



ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();