import React from 'react';
import {getStatistics, getStatisticsExams} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {price} from "../../../app/helpers";


class Statistics extends React.Component {

    state = {
        statistics: [],
        exams: [],
        loading: true,
        form: {
            page: 1,
            doctor: "",
            start: `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-01`,
            end: "",
            type: "pregledi"
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let  newState = {...this.state, form: {...this.state.form, page: 1, [name]: value}};
        this.setState(newState);
        this.load(newState.form);
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.statistics.name}</div>


            <div className="mb-3 d-flex align-items-baseline form-inline">
                <div className="mr-3">
                    Izmedju:
                    <span className="position-relative ml-1">
                    <input
                        value={this.state.form.start}
                        onChange={(e) => {
                            console.log(e.target.value)
                            let  newState = {...this.state, form: {...this.state.form, page: 1, start: e.target.value}};
                            this.setState(newState);
                            this.load(newState.form);
                        }}
                        type="date" className="form-control mr-1" />
                    </span>
                    <span className="position-relative">
                         <input
                             value={this.state.form.end}
                             onChange={(e) => {
                                 let  newState = {...this.state, form: {...this.state.form, page: 1, end: e.target.value}};
                                 this.setState(newState);
                                 this.load(newState.form);
                             }}
                             type="date" className="form-control mr-1" />
                    </span>
                </div>

                <select name="type" className="form-control mr-3"
                        value={this.state.form.type} onChange={this.changeField}>
                    <option value="">Sve</option>
                    <option value="pregledi">Pregledi</option>
                    <option value="operacije">Operacije</option>
                </select>

            </div>

            {this.state.statistics?.length > 0
                ? <div className="mb-5">
                    <table className="table">
                        <thead className="font-weight-bold">
                        <tr>
                            <td> Doktor </td>
                            <td> TOTAL </td>
                            <td> Placeno, besplatno </td>
                            <td width={140}> SATI </td>
                            <td width={140}> Zarada/Sat</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.statistics.map((item) => {
                            let full_price = 0;
                            return <tr key={item.id} className="mb-2">
                                <td>{item.title} {item.name} {item.lastname}</td>
                                <td>{price(item.full_price)}</td>
                                <td>
                                    {item.appointments_count}
                                    <b title="Placeno" className="green ml-2 mr-2">{item.payed_appointments_count}</b>
                                    <b title="Besplatno" className="red">{item.free_appointments_count}</b>
                                </td>
                                <td>{item.hours ? Math.round(item.hours * 10) / 10 : ''}</td>
                                <td>{item.hours ? price(Math.round(item.full_price/item.hours)) : ''}</td>
                            </tr>})}
                        </tbody>
                    </table>
                </div>
                : <div className="p-5 font_2">
                    Nema statistike
                </div>}


            {this.state.exams?.length > 0
                ? <>
                    <table className="table">
                        <thead>
                            <tr className="font_3">
                                <td> <b>TOTAL</b></td>
                                <td>
                                    <span className="mr-1" style={{color: "gray"}}> ({this.state.exams.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.full_count), 0)}) </span>
                                    <b>{price(this.state.exams.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.full_price), 0))}</b>
                                </td>
                                <td>
                                    <span className="mr-1" style={{color: "gray"}}> ({this.state.exams.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.payment_1_count), 0)}) </span>
                                    <b>{price(this.state.exams.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.payment_1_price), 0))}</b>
                                </td>
                                <td>
                                    <span className="mr-1" style={{color: "gray"}}> ({this.state.exams.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.payment_2_count), 0)}) </span>
                                    <b>{price(this.state.exams.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.payment_2_price), 0))}</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr className="font-weight-bold">
                            <td>  </td>
                            <td>  </td>
                            <td>  </td>
                            <td> </td>
                        </tr>

                        <tr className="font-weight-bold">
                            <td> NAZIV </td>
                            <td> TOTAL </td>
                            <td> Payment 1 </td>
                            <td> Payment 2 </td>
                        </tr>

                        {this.state.exams.map((item) => {
                            let full_price = 0;
                            return <tr key={item.id} className="mb-2">
                                <td>{item.name}</td>
                                <td><span className="mr-1" style={{color: "gray"}}>({item.full_count})</span> {price(item.full_price)}</td>
                                <td><span className="mr-1" style={{color: "gray"}}>({item.payment_1_count})</span> {price(item.payment_1_price)}</td>
                                <td><span className="mr-1" style={{color: "gray"}}>({item.payment_2_count})</span> {price(item.payment_2_price)}</td>

                            </tr>})}
                        </tbody>
                    </table>
                </>
                : <div className="p-5 font_2">
                    Nema pregleda
                </div>}

        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getStatistics(form).then((response) => {
            this.setState({...this.state, loading: false, statistics: response.data});
        });
        getStatisticsExams(form).then((response) => {
            console.log(response.data)
            this.setState({...this.state, loading: false, exams: response.data});
        });
    }

    componentDidMount() {
       this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.appointment,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
