import React from 'react';
import {deleteExamination, patchExamination} from "../../../app/hooks";


class ExaminationItem extends React.Component {

    state = {
        edit: false,
        item: this.props.item,
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, item: {...this.state.item, [name]: value}});
    }

    save = (e) => {
        this.editField(e);
        const name = e.target.name;
        const value = e.target.value;
        patchExamination({id: this.props.item.id, name: name, value: value}).then((response) => {
           this.props.reload();
        });
    }

    delete = (item) => {
        var r = window.confirm(`Želite da obrišete?`);
        if (r !== true) {
            return false;
        }
        deleteExamination({id: this.props.item.id}).then((response) => {
            this.props.reload();
        });
    }

    toggleEdit = () => {
        this.setState({...this.state, edit: !this.state.edit});
    }

    render = () => {
        const item = this.state.item;
        if(this.state.edit)     {
            return <tr className="mb-2">
                <td>
                    <input type="text" className="form-control" name="name" maxLength={191}
                           onChange={this.editField} onBlur={this.save} value={item.name} />
                    <br />
                    <input type="text" className="form-control w-auto" name="code" maxLength={32}
                           onChange={this.editField} onBlur={this.save} value={item.code} />
                </td>
                <td>
                    <textarea className="form-control" name="description"
                           onChange={this.editField} onBlur={this.save} value={item.description} />
                </td>
                <td>
                    <input type="price" className="form-control" name="price"
                           onChange={this.editField} onBlur={this.save} value={item.price} />
                </td>
                <td>
                    <button onClick={this.toggleEdit} className="btn btn-info btn-block">Zatvorite</button>
                    <button onClick={this.delete} className="btn btn-danger btn-block">Obrišite</button>
                </td>
            </tr>
        }
        return <tr className="mb-2">
            <td> {item.name}
                <div className="mt-2"> Sifra: <b>{item.code}</b> </div>
            </td>
            <td> {item.description} </td>
            <td> {item.price} </td>
            <td>
                <button onClick={this.toggleEdit} className="btn btn-info btn-block">Prepravite</button>
            </td>
        </tr>
    }
}
export default ExaminationItem;
