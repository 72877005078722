import React from 'react';
import {connect} from "react-redux";
import {getAppointments, getPatient} from "../../app/hooks";
import moment from "moment";
import {contacts} from "../../providers/contacts";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import PatientLbo from "../cms/patient/PatientLbo";



class PatientDrawer extends React.Component {

    state = {
        patient: {},
        appointments: []
    }

    openReport = (appointment) => {
        this.props.closeDrawer("patient");
        this.props.openDrawer({name: "report", appointment: appointment});
    }

    render = () => {
        const patient = this.state.patient;
        return <div className="p-3">

            <PatientLbo lbo={patient.lbo} />

            <div className="font_6 font-weight-bold mb-3">
                {patient.name}  {patient.lastname}
            </div>
            <div className="accent_block mb-3">
                <div className="mb-3">
                    JMBG: &nbsp;
                    {!patient.jmbg
                        ? <i className="gray"> nije postavljeno </i>
                        : <span>{patient.jmbg}</span>}
                </div>
                <div className="mb-3">
                    Datum rođenja: &nbsp;
                    {!patient.dob
                        ? <i className="gray"> nije postavljeno </i>
                        : <span>{moment(patient.dob).format("DD.MM.YYYY.").toString()} ({moment().diff(moment(this.state.patient.dob),'years')} god.)</span>}
                </div>
                <div className="">
                    Pol pacijenta: &nbsp;
                    {!patient.sex
                        ? <i className="gray"> nije postavljeno </i>
                        : patient.sex === 1
                            ? "Muško"
                            : "Žensko"}
                </div>
            </div>

            <div className="accent_block mb-3">
                <div className="mb-3">
                    Telefon: &nbsp;
                    {!patient.phone
                        ? <i className="gray"> nije postavljeno </i>
                        : <a href={"tel:" + patient.phone}>{patient.phone}</a>}
                </div>
                <div className="mb-3">
                    E-mail adresa: &nbsp;
                    {!patient.email
                        ? <i className="gray"> nije postavljeno </i>
                        : <a href={"mailto:" + patient.email}>{patient.email}</a>}
                </div>
                <div className="mb-3">
                    Adresa: &nbsp;
                    {!patient.address
                        ? <i className="gray"> nije postavljeno </i>
                        : <span>{patient.address}</span>}
                </div>
                <div className="mb-3">
                    Profesija: &nbsp;
                    {!patient.profession
                        ? <i className="gray"> nije postavljeno </i>
                        : <span>{patient.profession}</span>}
                </div>
                <div className="mb-3">
                    Preferiran kontakt: &nbsp;
                    {!patient.contact
                        ? <i className="gray"> nije postavljeno </i>
                        : <span>{contacts().find(item => parseInt(item.id) === parseInt(patient.contact)).name}</span>}
                </div>

                <div className="mb-3">
                    Ordinacije: &nbsp;
                    {patient.offices && patient.offices.map((item) => {
                        return <span className="mr-3 border p-1" key={item.id}>
                            {item.name}
                        </span>
                    })}
                </div>


                {patient.comment &&
                <div className="mb-3 font-italic text-wrap">{patient.comment}</div>}
            </div>
            <div className="mb-3">
                <div className="mb-3 font_4 font-weight-bold"> Pregledi: </div>
                {this.state.appointments.length > 0
                    ? <div className="accent_block mb-2">
                        {this.state.appointments.map((item) => {
                            return <div key={item.id} className="d-flex align-items-center mb-3 hoverable"
                                        onClick={() => this.openReport(item)}>
                                <i className="fas fa-notes-medical font_10 mr-3" style={{color: item.status_color}} />
                                <div>
                                    <div>{moment(item.start).format("DD.MM.YYYY").toString()}</div>
                                    {item.user.title} {item.user.name} {item.user.lastname} &nbsp;
                                    {moment(item.start).format("HH:mm").toString()} -  {moment(item.end).format("HH:mm").toString()}
                                    {item.examinations.map((exam) => {
                                        return <div key={exam.id} className="font-weight-bold">{exam.name}</div>
                                    })}
                                    <div className="font-weight-bold" style={{color: item.status_color}}>{item.status_name}</div>
                                </div>
                            </div>
                        })}
                    </div>
                    : <div className="font-italic">Nema zakazanih pregleda</div>}
            </div>
        </div>
    }

    componentDidMount() {
        getPatient({id: this.props.drawer.patient.id}).then((response) => {
            this.setState({...this.state, patient: response.data});
        });
        getAppointments({patient: this.props.drawer.patient.id, subtract: "all",
            withoutGlobalScope: true}).then((response) => {
            this.setState({...this.state, appointments: response.data});
        });
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.patient,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientDrawer);
