import React from 'react';
import {getOffices} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import {connect} from "react-redux";
import OfficeItem from "./OfficeItem";


class Offices extends React.Component {

    state = {
        loading: true,
        offices: [],
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.settingsOffices.name}</div>

            {this.state.offices.length > 0
                ? <table className="table">
                        <thead className="font-weight-bold">
                        <tr>
                            <td width={140}>Logo</td>
                            <td>Ime</td>
                            <td>Hirurgija</td>
                            <td width={150}>Broj ordinacija</td>
                            <td> Otvaranje </td>
                            <td> Zatvaranje </td>
                            <td width={140}>Logo za izveštaj</td>
                            <td width={140}>Footer izveštaj</td>
                            <td width={100}> </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.offices.map((item) => {
                            return <OfficeItem key={item.id} item={item} reload={this.load} />
                        })}
                        </tbody>
                    </table>
                : <div className="p-5 font_2">
                    Nema ordinacija
                </div>}

        </div>
    }

    load = (reload=false) => {
        if(reload)  {
            this.setState({...this.state, loading: true});
        }
        getOffices().then((response) => {
            this.setState({...this.state, loading: false, offices: response.data});
        });
    }

    componentDidMount() {
       this.load();
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Offices);
