import React from 'react';
import {getTemplates, getUsers, putTemplate} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import {connect} from "react-redux";
import TemplateItem from "./TemplateItem";


class Templates extends React.Component {

    state = {
        loading: true,
        templates: {},
        doctors: [],
        add: false,
        form: {
            search: "",
        },
    }

    addTemplate = (id, surgery=false) => {
        putTemplate({user_id: id, surgery}).then((response) => {
            this.load();
        }).catch((error) => {
            alert(error.message)
        })
    }


    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.settingsTemplates.name}</div>


          <table className="table">
                <thead className="font-weight-bold">
                <tr>
                    <td width={260}>Ime</td>
                    <td> Templejti  </td>
                    <td width={180}>  </td>
                </tr>
                </thead>
                <tbody>
                {this.state.doctors.map((user) => {
                    const item = this.state.templates[user.id] || [];
                    return <tr key={user.id} className="mb-2">
                        <td> {user.title} {user.name} {user.lastname}</td>
                        <td>
                            <div className="">
                                {item.map(template => {
                                    return <TemplateItem key={template.id} item={template} reload={this.load} />
                                })}
                            </div>
                        </td>
                        <td>
                            <button onClick={() => this.addTemplate(user.id)} className="btn btn-block mb-2 btn-success">
                                Dodajte izvestaj
                            </button>
                            <button onClick={() => this.addTemplate(user.id, true)} className="btn btn-block btn-success">
                                Dodajte hirurgiju
                            </button>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getTemplates(form).then((response) => {
            const holder = {};
            for(let i=0; i<response.data.length; i++) {
                const item = response.data[i];
                if(!holder[item.user.id]) holder[item.user.id] = [];
                holder[item.user.id].push(item);
            }
            this.setState({...this.state, loading: false, templates: holder});
        });
        getUsers({all: true, active: true, doctors: true}).then((response) => {
            this.setState({...this.state, doctors: response.data});
        });
    }

    componentDidMount() {
       this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Templates);
