import React from 'react';
import {connect} from "react-redux";
import Form from "../../framework/form/Form";
import Loader from "../../framework/Loader";
import {getAssociates, getOffices, getPatient, postCheckLbo} from "../../../app/hooks";
import Autocomplete from "../../framework/Autocomplete";
import {contacts} from "../../../providers/contacts";
import PatientLbo from "./PatientLbo";


class PatientForm extends React.Component {

    state = {
        form: {
            name: "",
            lastname: "",
            jmbg: "",
            dob: "",
            sex: "",
            contact: "",
            email: "",
            phone: "",
            address: "",
            profession: "",
            recommendation: "",
            associate_id: "",
            comment: "",
            offices: [],

            lbo: false,

        },
        loading: true,
        offices: [],
        lbo: "", // za pretragu,
        lboError: "",
        lboLoading: false,
    };

    changeOffices = (e, item) => {
        if(e.target.checked)     {
            this.setState({...this.state, form: {...this.state.form, offices: [...this.state.form.offices, item]}});
        } else   {
            const newOffices = this.state.form.offices.filter(office => office.id !== item.id);
            this.setState({...this.state, form: {...this.state.form, offices: newOffices}});
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    }

    checkLbo = (e) => {
        e.preventDefault();
        this.setState({...this.state, lboError: "", lboLoading: true});
        postCheckLbo({lbo: this.state.lbo}).then((response) => {
            console.log(response.data)
            this.setState({...this.state, lboError: "", lboLoading: false,
                form: {...this.state.form,
                    lbo: response.data,
                    name: response.data.ime,
                    lastname: response.data.prezime,
                    jmbg : response.data.jmbg ,
            }});
        }).catch(error => {
            this.setState({...this.state, lboError: JSON.stringify(error), lboLoading: true});
        });
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        const inOffice = this.state.form.offices.map(item => item.id);

        return  <>

            <div className="guard_block p-3 mb-3">
                <div className="row align-items-center">
                    <div className="col-md-3 text-right"> LBO: </div>
                    <div className="col-md-9">
                        <p className="mb-1">Unesite LBO da biste pokupili podatke pacijenta:</p>
                        <form onSubmit={this.checkLbo} className="d-flex">
                            <input type="text" name="lbo" placeholder=""
                                   value={this.state.lbo}
                                   onChange={(e) => this.setState({...this.state, lbo: e.target.value})}
                                   required minLength={11} maxLength={11}
                                   className="form-control mr-1" autoComplete="off" />
                            <input type="submit" className="btn btn-primary" value="posalji" disabled={this.state.lboLoading} />
                        </form>
                        {this.state.lboLoading &&
                        <div className="mt-1">Ucitavam...</div>}
                        {this.state.lboError &&
                        <div className="alert alert-danger mt-1">{this.state.lboError}</div>}
                    </div>
                </div>
            </div>

            <PatientLbo lbo={this.state.form.lbo} />

            <Form form={this.state.form} hook={this.props.hook} onSuccess={this.props.onSuccess} reset={this.props.reset}>

            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Ime: </div>
                <div className="col-md-9">
                    <input type="text" name="name" className="form-control" autoComplete="off" required />
                </div>
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">* Prezime: </div>
                <div className="col-md-9">
                    <input type="text" name="lastname" className="form-control" autoComplete="off" required />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right"> JMBG: </div>
                <div className="col-md-9">
                    <input type="text" name="jmbg" className="form-control" autoComplete="off" />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">* Datum rođenja: </div>
                <div className="col-md-9 form-inline">
                    <input type="date" name="dob" className="form-control" />
                </div>
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right"> Pol pacijenta: </div>
                <div className="col-md-9">
                    <label className="mr-3">
                        <input type="radio" name="sex" data-value={1} /> Muško
                    </label>
                    <label>
                        <input type="radio" name="sex" data-value={2} /> Žensko
                    </label>
                </div>
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-md-3 text-right">E-mail adresa: </div>
                <div className="col-md-9">
                    <input type="email" name="email" className="form-control" autoComplete="off" />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">* Broj telefona: </div>
                <div className="col-md-9">
                    <input type="text" name="phone" className="form-control" autoComplete="off" required />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">Adresa: </div>
                <div className="col-md-9">
                    <input type="text" name="address" className="form-control" autoComplete="off" />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">Profesija: </div>
                <div className="col-md-9">
                    <input type="text" name="profession" className="form-control" autoComplete="off" />
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">Preferiran kontakt: </div>
                <div className="col-md-9">
                    {contacts().map((item) => {
                        return <label key={item.value} className="mr-3">
                            <input type="radio" name="contact" data-value={item.id} /> {item.name}
                        </label>
                    })}
                </div>
            </div>

            <div ignore="true" className="row align-items-center mb-3 accent_block">
                <div className="col-md-3 text-right">Tip preporuke: </div>
                <div className="col-md-9">
                    <select
                        onChange={this.changeField}
                        value={this.state.form.recommendation} className="form-control" name="recommendation">
                        <option> </option>
                        <option>Facebook</option>
                        <option>Website</option>
                        <option>Reklama</option>
                        <option>Saradnik</option>
                    </select>

                    {this.state.form.recommendation === "Saradnik" &&
                    <div className="mt-2">
                        <Autocomplete
                            route={getAssociates}
                            default={this.state.form.associate && (this.state.form.associate.name || "")}
                            onClick={(associate) => this.setState({...this.state, form: {...this.state.form, associate_id: associate.id}})}
                            placeholder="Pretražite saradnike..." />
                    </div>}
                </div>
            </div>


            <div className="row align-items-center mb-2" ignore="true">
                <div className="col-md-3 text-right"> Ordinacije: </div>
                <div className="col-md-9">
                    {this.state.offices.map((item, i) => {
                        return <div key={item.id}>
                            <label className="chbx">
                                <input type="checkbox" checked={inOffice.includes(item.id)}
                                       onChange={(e) => this.changeOffices(e, item)} /><span />
                                <span className="ml-2"> {item.name} </span>
                            </label>
                        </div>
                    })}
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <div className="col-md-3 text-right">Komentar: </div>
                <div className="col-md-9">
                    <textarea name="comment" className="form-control" />
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-md-3 text-right"> </div>
                <div className="col-md-9">
                    <input type="submit" className="btn btn-primary btn-block" value="Sačuvaj profil" />
                </div>
            </div>
        </Form>
            </>
    }

    async componentDidMount() {
        if(this.props.id)       {
            getPatient({id: this.props.id}).then((response) => {
                console.log(response.data)
                this.setState({...this.state, loading: false, form: response.data});
            });
        } else      {
            this.setState({...this.state, loading: false});
        }
        getOffices().then((response) => {
            this.setState({...this.state, offices: response.data});
        })
    }
}
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
