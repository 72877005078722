import React from "react";


function PatientLbo({lbo}) {

    if(!lbo) return null;

    return (
        <div className="mt-1 alert alert-success">
            {Object.keys(lbo).map(key => {
                if(["id", "updated_at", "created_at", "patient_id", "report"].includes(key))   return null;
                return <div className="mb-1" key={key}> {key} : <b>{lbo[key]}</b> </div>
            })}
        </div>
    )
}
export default PatientLbo;