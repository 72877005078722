import React from 'react';
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import {times} from "../../app/helpers";
import {deleteAppointment, getExaminations, getUsers, putAppointment} from "../../app/hooks";
import Form from "../framework/form/Form";
import moment from "moment";


class AppointmentModal extends React.Component {

    state = {
        times: times(),
        patient: false,
        doctor: false,
        doctors: false,
        users: [],
        schedule: false,
        examinations: [],
        form: {
            appointment_id: "",
            user_id: "",
            instrumentarka: "",
            antesteziolog: "",
            anesteticarka: "",
            patient_id: "",
            examination_id: 41,
            appointment_status_id: 1,
            date: "",
            start: "",
            end: "",
            surgery: "",
        }
    }

    patientSelected = (patient) => {
        this.setState({...this.state, patient: patient, form: {...this.state.form, patient_id: patient.id}});
    }

    onSuccess = (response, form) => {
        this.props.drawer.reload();
        this.props.closeDrawer('appointment');
    }

    delete = () => {
        if (!window.confirm(`Želite da obrišete pregled?`)) {
            return false;
        }
        deleteAppointment({id: this.state.form.appointment_id}).then((response) => {
            this.props.drawer.reload();
            this.props.closeDrawer('appointment');
        });
    }

    render = () => {
        const start = this.state.schedule ? this.state.schedule.start.format("H:mm").toString() : false;
        const end = this.state.schedule ? this.state.schedule.end.format("H:mm").toString() : false;
        let started1 = false, ended1 = false, started2 = false, ended2 = false;
        return <Form form={this.state.form} hook={putAppointment} onSuccess={this.onSuccess} reset={true}>
            <div className="mb-3">
                <input type="hidden" name="appointment_id" />
                <input type="hidden" name="user_id" />
                <input type="hidden" name="date" />
                <input type="hidden" name="appointment_status_id" />
                <input type="hidden" name="patient_id" required />
                {this.state.doctor &&
                <strong>{this.state.doctor.title} {this.state.doctor.name} {this.state.doctor.lastname}</strong>}
            </div>

            {!this.state.form.user_id && this.state.doctors &&
            <>
                <div className="mb-3">
                    <div className="font-weight-bold mb-2">
                        Izaberite lekara:
                    </div>
                    <select name="user_id" className="form-control" required>
                        <option> </option>
                        {this.state.doctors.map((item) => {
                            return <option key={item.id} value={item.id}>
                                {item.title} {item.name} {item.lastname}
                            </option>
                        })}
                    </select>
                </div>
            </>}
            {this.state.form.surgery &&
            <div className="mb-3">
                <div className="font-weight-bold mb-2">
                    Instrumentarka:
                </div>
                <select name="instrumentarka" className="form-control">
                    <option> </option>
                    {this.state.users.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>
            </div>}

            {this.state.form.surgery &&
            <div className="mb-3">
                <div className="font-weight-bold mb-2">
                    Anesteziolog:
                </div>
                <select name="anesteziolog" className="form-control">
                    <option> </option>
                    {this.state.users.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>
            </div>}

            {this.state.form.surgery &&
            <div className="mb-3">
                <div className="font-weight-bold mb-2">
                    Anestetičarka:
                </div>
                <select name="anesteticarka" className="form-control">
                    <option> </option>
                    {this.state.users.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>
            </div>}

            <div className="mb-4 row">
                <div className="col">
                    <div className="font-weight-bold"> Od: </div>
                    <select name="start" className="form-control">
                        {this.state.times.map((item) => {
                            if(start)       {
                                if(ended1) return "";
                                if(item !== start && !started1) return "";
                                started1 = true;
                                if(item === end) {
                                    ended1 = true;
                                    return "";
                                }
                            }
                            return <option key={item} value={item}>
                                {item}
                            </option>
                        })}
                    </select>
                </div>
                <div className="col">
                    <div className="font-weight-bold"> Do: </div>
                    <select name="end" className="form-control">
                        {this.state.times.map((item) => {
                            if(start) {
                                if (ended2) return "";
                                if (item !== start && !started2) return "";
                                started2 = true;
                                if (item === end) {
                                    ended2 = true;
                                }
                            }
                            return <option key={item} value={item}>
                                {item}
                            </option>
                        })}
                    </select>
                </div>
            </div>

            <div className="mb-3 d-flex align-items-center">
                <button type="button" onClick={() => this.props.openDrawer({name: 'patients', callback: this.patientSelected})} className="btn btn-white">
                    {this.state.patient
                        ? <span> {this.state.patient.name} {this.state.patient.lastname} ({moment().diff(moment(this.state.patient.dob),'years')} god.)</span>
                        : "DODAJTE PACIJENTA"}
                </button>
                {this.state.patient && this.state.patient.phone &&
                <a href={"tel:" + this.state.patient.phone} className="ml-3">{this.state.patient.phone}</a>}
            </div>

            <div className="mb-4">
                <button type="button" onClick={() => this.props.openDrawer({name: 'newPatient', callback: this.patientSelected})}
                    className="btn btn-white">ILI OTVORITE KARTON</button>
            </div>

            {!this.props.drawer.appointment &&
            <div className="mb-4">
                <div className="mb-2"> Naziv pregleda: </div>
                <select className="form-control" name="examination_id">
                    {this.state.examinations.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    })}
                </select>
            </div>}

            <div className="row">
                <div className="col">
                    <input type="submit" className="btn btn-primary btn-block" value="Sačuvaj" />
                </div>
                {this.state.form.appointment_id &&
                <div className="col">
                    <button onClick={this.delete} className="btn btn-danger btn-block"> Obrišite </button>
                </div>}
            </div>
        </Form>
    };

    componentDidMount() {
        getExaminations().then((response) => {
            this.setState({...this.state, examinations: response.data, form: {...this.state.form, examination_id: response.data[0].id}});
        });
        getUsers({all: true, active: true}).then((response) => {
            this.setState({...this.state, users: response.data});
        });
        if(this.props.drawer.appointment)       {
            const app = this.props.drawer.appointment;
            this.setState({...this.state, doctor: app.user, patient: app.patient, form: {...this.state.form,
                    appointment_id: app.id,
                    user_id: app.user.id,
                    instrumentarka: app.instrumentarka,
                    anesteziolog: app.anesteziolog,
                    anesteticarka: app.anesteticarka,
                    patient_id: app.patient.id,
                    surgery: app.surgery,
                    appointment_status_id: app.appointment_status_id,
                    examination_id: app.examinations?.[0]?.examination_id,
                    date: app.start.format("yyyy-MM-DD").toString(),
                    start: app.start.format("H:mm").toString(),
                    end: app.end.format("H:mm").toString()}});
            return false;
        }
        const drawerTime = this.props.drawer.time.format("H:mm").toString();
        const index = this.state.times.indexOf(drawerTime);
        const end = this.state.times[index+2] || this.state.times[this.state.times.length - 1];
        this.setState({...this.state, schedule: this.props.drawer.schedule, doctor: this.props.drawer.schedule?.user,
            form: {...this.state.form,
                user_id: this.props.drawer.schedule?.user?.id || "",
                date: this.props.drawer.time.format("yyyy-MM-DD").toString(),
                start: drawerTime,
                end: end}});
        if(!this.props.drawer.schedule) {
            getUsers({all: true, active: true, doctors: true}).then((response) => {
                this.setState({...this.state, doctors: response.data, form: {...this.state.form, surgery: 1}});
            });
        }
    }
}

const mapStateToProps = state => ({
    office: state.office,
    drawer: state.drawers.appointment,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentModal);
