import store from "../store/store";

export function server(path) {
    let gateway = `https://testapinew.4eyesdoc.rs/${path}`;
   //return gateway
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        gateway = `http://127.0.0.1:5017/${path}`;
    }
    return gateway;
}

export function file(name) {
    if(!name) return "";
    return server("files/" + name);
}
export function fileProxy(name) {
    if(!name) return "";
    return server("file/proxy?name=" + name);
}
export function gallery(item, big = false) {
    if(!item)   return '/images/000-no-image.png';
    if(item.endsWith('.pdf'))   return '/images/000-pdf.png';
    if(big) return server('gallery/lg/' + item);
    return server('gallery/sm/' + encodeURIComponent(item));
}


export function randString(length=5) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function times(full=false)     {
    const office = store.getState().user.offices.find(item => parseInt(item.id) === parseInt(store.getState().office));
    const quarterHours = ["00", "15", "30", "45"];
    const times = [];
    let started = false;
    let ended = false;
    for (var i = 6; i < 24; i++) {
        for (var j = 0; j < 4; j++) {
            const time = i + ":" + quarterHours[j];
            if(full)    {
                times.push(time);
                continue;
            }
            if(office && office.start && !started)        {
                if(time !== office.start)           {
                    continue;
                }
                started = true;
            }
            if(office && office.end && !ended)        {
                if(time === office.end)           {
                    ended = true;
                    break;
                }
            }
            if(!ended)  {
                times.push(time);
            }
        }
    }
    return times;
}

export function price(price)        {
    if(!price) return "";
    price = parseFloat(price);
    let strPrice = String(price);
    if(price > 999)     {
        strPrice = strPrice.slice(0, -3) + " " + strPrice.slice(-3);
    }
    if(price > 999999)     {
        strPrice = strPrice.slice(0, -7) + " " + strPrice.slice(-7);
    }
    return strPrice.replace(".", ",") + " RSD";
}