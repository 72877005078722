import React from 'react';
import {
    deleteAppointmentDisease,
    getDiseases,
    putAppointmentDisease
} from "../../../app/hooks";
import Autocomplete from "../../framework/Autocomplete";


class Diseases extends React.Component {

    state = {
        form: {
            appointment_id: this.props.appointment.id,
            deasese_id: ""
        }
    }

    save = (item) => {
        putAppointmentDisease({appointment_id: this.props.appointment.id, disease_id: item.id}).then((response) => {
            this.setState({...this.state, form: {...this.state.form, deasese_id: ""}});
            this.props.reload();
        })
    }

    delete = (item) => {
        var r = window.confirm(`Želite da obrišete?`);
        if (r !== true) {
            return false;
        }
        deleteAppointmentDisease({id: item.id}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        return <div>
            {this.props.diseases.map((item) => {
                return <div key={item.id} className="border-bottom pb-2 mb-2 d-flex align-items-center justify-content-between">
                    <strong>{item.code} {item.name}</strong>
                    <button
                    onClick={() => this.delete(item)}
                        className="btn btn-white btn-sm">Obrišite</button>
                </div>
            })}

            <div className="alert alert-success">
                <div className="mb-1">Dodajte bolest: </div>

                <Autocomplete
                    route={getDiseases}
                    onClick={this.save}
                    render={props => <div> {props.code} {props.name} </div>} />
            </div>
        </div>
    }

}

export default Diseases;
