import React from 'react';
import {putAppointmentExamination} from "../../../app/hooks";


class AddExamination extends React.Component {

    state = {
        form: {
            appointment_id: this.props.appointment.id,
            examination_id: this.props.examinations.length > 0 ? this.props.examinations[0].id : 1
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    }

    save = () => {
        putAppointmentExamination(this.state.form).then((response) => {
            this.setState({...this.state, form: {...this.state.form, examination_id: this.props.examinations[0].id}});
            this.props.reload();
        })
    }

    render = () => {
        return <div className="alert alert-success">
            <div className="mb-1">Dodajte pregled: </div>
            <div className="d-flex align-items-center">
                <select
                    value={this.state.form.examination_id}
                    onChange={this.changeField}
                    className="form-control mr-2" name="examination_id">
                    {this.props.examinations.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    })}
                </select>
                <button
                    onClick={this.save}
                    className="btn btn-white text-nowrap">
                    DODAJTE
                </button>
            </div>

        </div>
    }

}

export default AddExamination;
