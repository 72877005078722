import React from 'react';
import {Document, Image, Page, Text, StyleSheet, View, Font} from "@react-pdf/renderer";
import robotoSlabLight from "../../../assets/fonts/RobotoSlab-Light.ttf";
import robotoSlabMedium from "../../../assets/fonts/RobotoSlab-Medium.ttf";
import robotoSlabItalic from "../../../assets/fonts/Roboto-Italic.ttf";
import {fileProxy} from "../../../app/helpers";
import moment from "moment";
import { parse } from 'node-html-parser';


Font.register({
    family: 'Roboto',
    fonts: [
        {src: robotoSlabLight},
        {src: robotoSlabMedium, fontWeight: "bold"},
        {src: robotoSlabItalic, fontStyle: "italic"},
    ]
});
const styles = StyleSheet.create({
    split: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    left: {
        width: "20%",
        fontWeight: "bold"
    },
    left2: {
        width: "20%",
        fontWeight: "bold"
    },
    right2: {
        paddingLeft: 10,
        borderLeft: "1 solid black",
        paddingTop: 3,
        paddingBottom: 3,
        width: "80%"

    }
});

class PdfRenderer extends React.Component   {

    state = {
        structure: [],
    }

    tr = (name1, val1, name2, val2, gray) => {
        const bcg = gray ? "#f0f0f0" : "white";
        const padding = 3;
        return <View style={styles.split}>
            <View style={{...styles.split, width: "50%"}}>
                <View style={{width: "30%"}}>
                    <Text style={{margin: 1, textAlign: "right", padding: padding, backgroundColor: bcg, fontWeight: "bold"}}> {name1} </Text>
                </View>
                <View style={{width: "70%"}}>
                    <Text style={{margin: 1, padding: padding, backgroundColor: bcg}}> {val1} </Text>
                </View>
            </View>
            <View style={{...styles.split, width: "50%"}}>
                <View style={{width: "30%"}}>
                    <Text style={{margin: 1, textAlign: "right", padding: padding, backgroundColor: bcg, fontWeight: "bold"}}> {name2} </Text>
                </View>
                <View style={{width: "70%"}}>
                    <Text style={{margin: 1, padding: padding, backgroundColor: bcg}}> {val2} </Text>
                </View>
            </View>
        </View>
    }

    grayColor = true;
    gray = () => {
        const gray = this.grayColor;
        this.grayColor = !this.grayColor;
        return gray;
    }

    domLoop = (el) => {
        let children = [];
        if(el.childNodes.length > 0)       {
            for(let i=0; i<el.childNodes.length; i++)       {
                let child = this.domLoop(el.childNodes[i]);
                children.push(child);
            }
        } else if(el.rawTagName === 'p')    {
            return
        }
        let Container;
        if(el.rawTagName === 'p')       {
            Container = <Text>{children}{"\n"}</Text>
        }
        else if(el.rawTagName === 'strong')       Container = <Text style={{fontWeight: "bold"}}>{children}</Text>
        else if(el.rawTagName === 'u')       Container = <Text style={{textDecoration: "underline"}}>{children}</Text>
        else if(el.rawTagName === 'em')       Container = <Text style={{fontStyle: "italic"}}>{children}</Text>
        //else if(el.rawTagName === 'span')       Container = <Text>{children}</Text>
        else if(el.nodeType === 1)       Container = <Text>{children}</Text>
        else                            Container = <Text>{el.rawText.replace(/&nbsp;/g, " ")
                .replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&").replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'")}</Text>
        return Container;
    }

    anamneza = (an) => {
        if(!an) return " ";
        const root = parse(`<p>${an}</p>`);
        return this.domLoop(root);
    }

    render = () => {
        const app = this.props.appointment;
        const machines = {};
        for(let i=0; i<this.props.machines.length; i++)   {
            machines[this.props.machines[i].id] = this.props.machines[i];
            machines[this.props.machines[i].id].images = [];
        }
        if(app.images.length > 0)   {
            for(let i=0; i<app.images.length; i++)   {
                if(machines[app.images[i].machine_id])   {

                }
                machines[app.images[i].machine_id].images.push(app.images[i]);
            }
        }
        const anamneza = this.anamneza(app.report.anamneza)
        return <Document title={this.props.reportName}>
            <Page size="LETTER" wrap style={{padding: 20, fontFamily: 'Roboto', fontSize: this.props.fontSize}}>

                {(this.props.office && this.props.office.report_logo_name)
                    ? <View style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                        <Image src={fileProxy(this.props.office.report_logo_name)} style={{height: 60}} />
                    </View>
                    : <View />}

                <View style={{textAlign: "center", fontSize: 14, marginBottom: 30, fontWeight: "bold"}}>
                    <Text> {this.props.reportName} </Text>
                </View>

                <View style={{...styles.split, marginBottom: 5}}>
                    <Text style={styles.left}> Ime i prezime: </Text><Text> {app.patient.name} {app.patient.lastname} </Text>
                </View>

                {app.patient.dob
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left}> Datum rođenja: </Text>
                        <Text> {moment(app.patient.dob).format("DD.MM.YYYY.").toString()} ({moment().diff(moment(app.patient.dob),'years')} godina)</Text>
                    </View>
                    : <View />}

                <View style={{...styles.split, marginBottom: 20}}>
                    <Text style={styles.left}> Datum pregleda: </Text>
                    <Text> {moment(app.start).format("DD.MM.YYYY.").toString()}</Text>
                </View>

                {app.report.dijagnoza
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> Dijagnoza: </Text>
                        <Text style={styles.right2}>{app.report.dijagnoza || " "}</Text>
                    </View>
                    : <View />}

                {app.report.anamneza
                    ? <View style={{...styles.split, marginBottom: 20}}>
                            <Text style={styles.left2}> Anamneza: </Text>
                        {/*<Text style={styles.right2}>{app.report.anamneza ? app.report.anamneza.replace(/(<([^>]+)>)/gi, "").replace(/^\s+|\s+$/g, '') : " "}</Text>*/}
                        <View style={styles.right2}>{anamneza}</View>
                    </View>
                    : <View />}

                <View style={styles.split}>
                    <View style={{width: "50%", textAlign: "center"}}>
                        <Text style={{borderBottom: "1 solid black", marginRight: 1, fontWeight: "bold"}}> OD </Text>
                    </View>
                    <View style={{width: "50%", textAlign: "center"}}>
                        <Text style={{borderBottom: "1 solid black", marginLeft: 1, fontWeight: "bold"}}> OS </Text>
                    </View>
                </View>

                {(app.report.vod || app.report.vos)
                    ? this.tr("VOD", app.report.vod || "", "VOS", app.report.vos || "", this.gray())
                    : <View />}

                {(app.report.tod || app.report.tos)
                    ? this.tr("TOD", app.report.tod ? app.report.tod + " mmHg" : "",
                    "TOS", app.report.tos ? app.report.tos + " mmHg" : "", this.gray())
                    : <View />}

                {(app.report.rod || app.report.ros)
                    ? this.tr("ROD", app.report.rod || "", "ROS", app.report.ros || "", this.gray())
                    : <View />}

                {(app.report.cct_od || app.report.cct_os)
                    ? this.tr("CCT OD", app.report.cct_od || "", "CCT OS", app.report.cct_os || "", this.gray())
                    : <View />}

                {(app.report.kf_od || app.report.kf_os)
                    ? this.tr("KF OD", app.report.kf_od ? app.report.kf_od + " mmHg" : "",
                    "KF OS", app.report.kf_os ? app.report.kf_os + " mmHg" : "", this.gray())
                    : <View />}

                {(app.report.ar_od || app.report.ar_os)
                    ? this.tr("AR OD", app.report.ar_od || "", "AR OS", app.report.ar_os || "", this.gray())
                    : <View />}

                {(app.report.foko_f_od || app.report.foko_f_os)
                    ? this.tr("FOKO F OD", app.report.foko_f_od || "", "FOKO F OS", app.report.foko_f_os || "", this.gray())
                    : <View />}

                {(app.report.foko_k_od || app.report.foko_k_os)
                    ? this.tr("FOKO K OD", app.report.foko_k_od || "", "FOKO K OS", app.report.foko_k_os || "", this.gray())
                    : <View />}

                {(app.report.foko_n_od || app.report.foko_n_os)
                    ? this.tr("FOKO N OD", app.report.foko_n_od || "", "FOKO N OS", app.report.foko_n_os || "", this.gray())
                    : <View />}

                {app.report.napomena
                    ? <View style={{marginTop: 5}}>
                        <Text style={{fontSize: 10, color: "gray"}}>* {app.report.napomena}</Text>
                    </View>
                    : <View />}

                <View style={{marginBottom: 20}} />

                {app.report.slod
                ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> Prednji segment OD: </Text>
                        <Text style={styles.right2}>{app.report.slod || " "}</Text>
                    </View>
                : <View />}

                {app.report.slos
                ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> Prednji segment OS: </Text>
                        <Text style={styles.right2}>{app.report.slos || " "}</Text>
                    </View>
                : <View />}

                {app.report.slou
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> SLOU: </Text>
                        <Text style={styles.right2}>{app.report.slou || " "}</Text>
                    </View>
                    : <View />}

                {app.report.fod
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> FOD: </Text>
                        <Text style={styles.right2}>{app.report.fod || " "}</Text>
                    </View>
                    : <View />}
                {app.report.fos
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> FOS: </Text>
                        <Text style={styles.right2}>{app.report.fos || " "}</Text>
                    </View>
                    : <View />}
                {app.report.fou
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> FOU: </Text>
                        <Text style={styles.right2}>{app.report.fou || " "}</Text>
                    </View>
                    : <View />}

                {app.report.kvp_show
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> KVP: </Text>
                        <Text style={styles.right2}>{app.report.kvp || " "}</Text>
                    </View>
                    : <View />}


                {app.report.oct_show && app.report.oct
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> OCT PNO: </Text>
                        <Text style={styles.right2}>{app.report.oct || " "}</Text>
                    </View>
                    : <View />}

                {app.report.oct_show && app.report.oct_zakljucak
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> OCT MAC: </Text>
                        <Text style={styles.right2}>{app.report.oct_zakljucak || " "}</Text>
                    </View>
                    : <View />}

                {app.report.oct_show && app.report.oct_kontrola
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> OCT ANT: </Text>
                        <Text style={styles.right2}>{app.report.oct_kontrola || " "}</Text>
                    </View>
                    : <View />}

                {app.report.ultrazvuk_show && app.report.ultrazvuk_zakljucak
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}>  Ultrazvuk: </Text>
                        <Text style={styles.right2}>{app.report.ultrazvuk_zakljucak || " "}</Text>
                    </View>
                    : <View />}

                {app.report.meibomografija_show && app.report.meibomografija_zakljucak
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> Strabološki nalaz: </Text>
                        <Text style={styles.right2}>{app.report.meibomografija_zakljucak || " "}</Text>
                    </View>
                    : <View />}

                {app.report.topografija_show && app.report.topografija_zakljucak
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> Kornealna topografija: </Text>
                        <Text style={styles.right2}>{app.report.topografija_zakljucak || " "}</Text>
                    </View>
                    : <View />}


                {app.images.length > 0
                    ? <View style={{display: "flex", flexDirection: "row", marginBottom: 10, marginTop: 10}}>
                        {Object.values(machines).map((item) => {
                            if(item.images.length === 0) return <View />
                            return <View key={item.id} style={{marginBottom: 5, width:140, marginRight: 15}}>

                                    {item.images.map((image) => {
                                        return <View>
                                            <Text style={{fontSize: 7, marginBottom: 1, fontWeight: "bold", color: "white",
                                                backgroundColor: "darkgray"}}>
                                                {item.name}
                                            </Text>
                                            <Image src={fileProxy(image.name)} />
                                        </View>
                                    })}

                            </View>
                            })}
                    </View>
                    : <View />}



                {app.report.zakljucak
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> ZAKLJUČAK: </Text>
                        <Text style={styles.right2}>{app.report.zakljucak || " "}</Text>
                    </View>
                    : <View />}
                {app.report.savet
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> SAVET: </Text>
                        <Text style={styles.right2}>{app.report.savet || " "}</Text>
                    </View>
                    : <View />}
                {app.report.kontrola_kod_oftamologa
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> KONTROLA: </Text>
                        <Text style={styles.right2}>{app.report.kontrola_kod_oftamologa || " "}</Text>
                    </View>
                    : <View />}
                {app.report.terapija_kod_kuce
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> TERAPIJA: </Text>
                        <Text style={styles.right2}>{app.report.terapija_kod_kuce || " "}</Text>
                    </View>
                    : <View />}


                {app.diseases.length > 0
                    ? <View style={{...styles.split, marginBottom: 5}}>
                        <Text style={styles.left2}> ICD 10: </Text>
                        <Text style={styles.right2}>
                            {app.diseases.map((item) => {
                                return `${item.code} - ${item.name}
`;
                            })}
                        </Text>
                    </View>
                    : <View />}

                <View style={{marginTop: 20, display: "flex", alignItems: "right"}}>
                    <Text>{app.user.title} {app.user.name} {app.user.lastname}</Text>
                    {app.user.role_id === 5
                        ? <View />
                        : <Text style={{marginBottom: 15, fontSize: 8}}>specijalista oftamolog</Text>}
                    <Text style={{width: 200, borderBottom: "1 dashed black"}}> </Text>
                </View>


                <View style={{marginTop: 20}}>
                    {(this.props.office && this.props.office.report_footer_name)
                        ? <View style={{display: "flex", alignItems: "center", marginBottom: 3}}>
                            <Image src={fileProxy(this.props.office.report_footer_name)} style={{height: 60}} />
                        </View>
                        : <View />}

                    <Text style={{color: "#b5b4b4", fontSize: 8, textAlign: "center"}}>
                        Pacijent je obavešten o dijagnozi i prognozi svoje bolesti, predloženoj medicinskoj meri, cilju, koristi kao i mogućim
                        {"\n"} komplikacijama, rizicima i posledicama
                        preduzimanja odnosno nepreduzimanja iste. Takođe, prihvata dijagnostičke i terapijske procedure koje su preduzete pri ovom pregledu. Izjavljuje da nema
                        alergijskih i drugih rizika.
                    </Text>
                </View>
            </Page>
        </Document>
    };
}

export default PdfRenderer;
