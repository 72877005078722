export const UPDATE_USER = "UPDATE_USER";
export const SET_USER = "SET_USER";
export const SET_OFFICES = "SET_OFFICES";

export function updateUser(field, value) {
    return {
        type: UPDATE_USER,
        payload: {
            field: field,
            value: value
        }
    }
}
export function setUser(value) {
    return {
        type: SET_USER,
        payload: value
    }
}

export function setOffices(value) {
    return {
        type: SET_OFFICES,
        payload: value
    }
}