import {OPEN_DRAWER, CLOSE_DRAWER} from "../actions/drawersActions";

const drawers = {
    patient: {status: false},
    patients: {status: false},
    newPatient: {status: false},
    user: {status: false},
    report: {status: false},
    appointment: {status: false},
    schedule: {status: false},
    error: {status: false},
    notifications: {status: false},
    pdf: {status: false},
    program: {status: false},
    xml: {status: false},
    files: {status: false},
}

function draw(state= drawers, {type, payload})      {
    switch(type)        {
        case OPEN_DRAWER:
            if(payload.name)     {
                return {...state, [payload.name]: {...{status: true}, ...payload}};
            }
            return {...state, [payload]: {status: true}};
        case CLOSE_DRAWER:
            return {...state, [payload]: {status: false}};
        default:
            return state;
    }
}
export default draw;