import React from 'react';
import {getRoles, putRole} from "../../../app/hooks";
import routes from "../../../app/routes";
import Loader from "../../framework/Loader";
import {connect} from "react-redux";
import RoleItem from "./RoleItem";


class Roles extends React.Component {

    state = {
        loading: true,
        roles: [],
        add: false,
        form: {
            search: "",
        },
        insert: {
            title: "",
            name: "",
        }
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, insert: {...this.state.insert, [name]: value}});
    }

    editForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newState = {...this.state, form: {...this.state.form, [name]: value}};
        this.setState(newState);
        this.load(newState.form);
    }

    submit = (e) => {
        e.preventDefault();
        putRole(this.state.insert).then((response) => {
            this.setState({...this.state, insert: {name: "", title: ""}});
            this.load();
        })
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        const insert = this.state.insert;
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.settingsRoles.name}</div>

            <div className="mb-3">
                {this.state.add
                    ? <div>
                        <form action="/" className="mb-2" onSubmit={this.submit}>
                            <div className="d-flex mb-2">
                                <input type="text" className="form-control mr-2" name="name" placeholder="Naziv kategorije"
                                       onChange={this.editField} value={insert.name}  required={true} />
                                <button type="submit" className="btn btn-success"> Sačuvajte </button>
                            </div>
                            <textarea className="form-control" name="title" placeholder="Skraćenica"
                                  onChange={this.editField} value={insert.title} />
                        </form>
                        <button onClick={() => this.setState({...this.state, add: false})} className="btn btn-info"> Zatvorite </button>
                    </div>
                    : <button onClick={() => this.setState({...this.state, add: true})} className="btn btn-success"> Dodajte kategoriju </button>
                }
            </div>


            {this.state.roles.length > 0
                ? <table className="table">
                        <thead className="font-weight-bold">
                        <tr>
                            <td>Ime</td>
                            <td>Skraćenica</td>
                            <td width={100}> </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.roles.map((item) => {
                            return <RoleItem key={item.id} item={item} reload={this.load} />
                        })}
                        </tbody>
                    </table>
                : <div className="p-5 font_2">
                    Nema kategorija
                </div>}

        </div>
    }

    load = (form) => {
        if(!form) form = this.state.form;
        getRoles(form).then((response) => {
            this.setState({...this.state, loading: false, roles: response.data});
        });
    }

    componentDidMount() {
       this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Roles);
