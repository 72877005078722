import React from 'react';
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import {deleteUserSchedule, getOffices, getUsers, putUserSchedule} from "../../app/hooks";
import Form from "../framework/form/Form";
import {times} from "../../app/helpers";


class ScheduleModal extends React.Component {

    state = {
        times: times(),
        doctors: [],
        ordinations: [],
        form: {
            schedule_id: "",
            user_id: "",
            date: "",
            start: "10:30",
            end: "11:00",
            ordination: "",
        }
    }

    onSuccess = (response, form) => {
        this.props.drawer.reload();
        this.props.closeDrawer('schedule');
    }

    delete = () => {
        const doctor = this.props.drawer.doctor;
        var r = window.confirm(`Želite da obrišete iz rasporeda: ${doctor.title} ${doctor.name} ${doctor.lastname}?`);
        if (r !== true) {
            return false;
        }
        deleteUserSchedule({id: this.state.form.schedule_id}).then((response) => {
            this.props.drawer.reload();
        });
    }

    render = () => {
        return <Form form={this.state.form} hook={putUserSchedule} onSuccess={this.onSuccess} reset={true}>

            {this.state.form.schedule_id &&
            <input type="hidden" name="schedule_id" />}

            <div className="mb-3">
                <div className="font-weight-bold mb-2">
                    Datum: {this.props.drawer.time.format("DD.MM.yyyy.").toString()}
                    <input type="hidden" name="date" />
                </div>

            </div>

            <div className="mb-3">
                <div className="font-weight-bold mb-2">
                    Izaberite lekara:
                </div>
                <select name="user_id" className="form-control" required>
                    <option> </option>
                    {this.state.doctors.map((item) => {
                        return <option key={item.id} value={item.id}>
                            {item.title} {item.name} {item.lastname}
                        </option>
                    })}
                </select>
            </div>

            {this.state.ordinations.length > 0 &&
            <div className="mb-3">
                <div className="font-weight-bold"> Izaberite ordinaciju: </div>
                <select name="ordination" className="form-control">
                    <option> </option>
                    {this.state.ordinations.map((item) => {
                        return <option key={item} value={item}> {item} </option>
                    })}
                </select>
            </div>}

            <div className="mb-3 row">
                <div className="col">
                    <div className="font-weight-bold"> Od: </div>
                    <select name="start" className="form-control">
                        <option> </option>
                        {this.state.times.map((item) => {
                            return <option key={item}>
                                {item}
                            </option>
                        })}
                    </select>
                </div>
                <div className="col">
                    <div className="font-weight-bold"> Do: </div>
                    <select name="end" className="form-control">
                        <option> </option>
                        {this.state.times.map((item) => {
                            return <option key={item}>
                                {item}
                            </option>
                        })}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <input type="submit" className="btn btn-primary btn-block" value="Sačuvajte" />
                </div>

                {this.state.form.schedule_id &&
                <div className="col">
                    <button onClick={this.delete} className="btn btn-danger btn-block"> Obrišite </button>
                </div>}
            </div>
        </Form>

    };

    componentDidMount() {
        getUsers({all: true, active: true, doctors: true}).then((response) => {
            this.setState({...this.state, doctors: response.data});
        });
        getOffices().then((response) => {
            const office = response.data.find(item => parseInt(item.id) === parseInt(this.props.office));
            const ordinations = office.ordinations ? Array(office.ordinations).fill(1).map((x, y) => x + y) : [];
            this.setState({...this.state, ordinations: ordinations});
        });
        if(this.props.drawer.doctor)        {
            const doctor = this.props.drawer.doctor;
            this.setState({...this.state, form: {...this.state.form, user_id: doctor.user_id, schedule_id: doctor.id, ordination: doctor.ordination, surgery: doctor.surgery,
                    date: doctor.start.format("yyyy-MM-DD").toString(), start: doctor.start.format("H:mm").toString(), end: doctor.end.format("H:mm").toString()}});
            return false;
        }
        const drawerTime = this.props.drawer.time.format("H:mm").toString();
        const index = this.state.times.indexOf(drawerTime);
        const end = this.state.times[index+2] || this.state.times[this.state.times.length - 1];
        this.setState({...this.state, form: {...this.state.form, date: this.props.drawer.time.format("yyyy-MM-DD").toString(), start: drawerTime, end: end}});
    }
}
const mapStateToProps = state => ({
    office: state.office,
    drawer: state.drawers.schedule,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal);
