import React from "react";
import CmsHeader from "./CmsHeader";
import ReportDrawer from "../drawers/ReportDrawer";
import Modal from "../framework/Modal";
import ScheduleModal from "../drawers/ScheduleModal";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {connect} from "react-redux";
import AppointmentModal from "../drawers/AppointmentModal";
import Drawer from "../framework/Drawer";
import UserDrawer from "../drawers/UserDrawer";
import PatientDrawer from "../drawers/PatientDrawer";
import PatientForm from "../cms/patient/PatientForm";
import {getOffices, putPatient} from "../../app/hooks";
import Patients from "../cms/patients/Patients";
import PdfDrawer from "../drawers/pdf/PdfDrawer";
import {setOffices} from "../../store/actions/userActions";
import FilesDrawer from "../drawers/files/FilesDrawer";
import XmlDrawer from "../drawers/XmlDrawer";
import ProgramPdfDrawer from "../drawers/ProgramPdfDrawer";



class CmsLayout extends React.Component {

    state = {
        loading: true
    }

    newPatient = (response, form) => {
        this.props.drawers.newPatient.callback(response.data);
        this.props.closeDrawer('newPatient');
    }

    render = () => {
        const Component = this.props.component;
        if(this.state.loading) return "";
        return <div>
            <Drawer
                width="100%"
                zIndex={6}
                reload={true}
                title="Specijalistički izveštaj"
                show={this.props.drawers.report.status}
                close={() => this.props.closeDrawer("report")}>

                <ReportDrawer />
            </Drawer>

            <Drawer
                width="100%"
                zIndex={6}
                reload={true}
                title="PDF"
                noHeader={true}
                show={this.props.drawers.pdf.status}
                close={() => this.props.closeDrawer("pdf")}>

                <PdfDrawer />
            </Drawer>

            <Drawer
                width="100%"
                zIndex={6}
                reload={true}
                title="PDF"
                noHeader={true}
                show={this.props.drawers.program.status}
                close={() => this.props.closeDrawer("program")}>

                <ProgramPdfDrawer />
            </Drawer>

            <Drawer
                width="100%"
                zIndex={6}
                reload={true}
                title="XML"
                show={this.props.drawers.xml.status}
                close={() => this.props.closeDrawer("xml")}>

                <XmlDrawer />
            </Drawer>

            <Drawer
                width="90%"
                zIndex={8}
                title="Fajlovi"
                reload={true}
                show={this.props.drawers.files.status}
                close={() => this.props.closeDrawer("files")}>

                <FilesDrawer />
            </Drawer>


            <Modal
                width={660}
                zIndex={7}
                reload={true}
                title="Unesite lekara u raspored"
                open={this.props.drawers.schedule.status}
                close={() => this.props.closeDrawer("schedule")}>

                <ScheduleModal />
            </Modal>

            <Modal
                reload={true}
                width={660}
                zIndex={7}
                title="Zakažite"
                open={this.props.drawers.appointment.status}
                close={() => this.props.closeDrawer("appointment")}>

                <AppointmentModal />
            </Modal>

            <Drawer
                width={660}
                reload={true}
                title="Korisnik"
                show={this.props.drawers.user.status}
                close={() => this.props.closeDrawer("user")}>

                <UserDrawer />
            </Drawer>

            <Drawer
                width={660}
                reload={true}
                zIndex={8}
                title="Pacijent"
                show={this.props.drawers.patient.status}
                close={() => this.props.closeDrawer("patient")}>

                <PatientDrawer />
            </Drawer>

            <Modal
                width={960}
                zIndex={8}
                reload={true}
                title="Otvorite karton"
                open={this.props.drawers.newPatient.status}
                close={() => this.props.closeDrawer("newPatient")}>

                <div className="mb-4">
                    <PatientForm hook={putPatient} onSuccess={this.newPatient} />
                </div>
            </Modal>

            <Modal
                width={960}
                zIndex={8}
                title="Pacijenti"
                open={this.props.drawers.patients.status}
                close={() => this.props.closeDrawer("patients")}>

                <Patients />
            </Modal>

            <CmsHeader />

            <Component
                item={this.props.item}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match} />
        </div>
    };

    componentDidMount() {
        getOffices({admin: true}).then((response) => {
            this.props.setOffices(response.data);
            this.setState({...this.state, loading: false});
        });
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawers: state.drawers,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
    setOffices: setOffices
}
export default connect(mapStateToProps, mapDispatchToProps)(CmsLayout);
