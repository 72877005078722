import React from 'react';
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import {postDownloadXml} from "../../app/hooks";
import {server} from "../../app/helpers";
import moment from "moment";



class XmlDrawer extends React.Component {

    ustanova = [
        {title: "Verzija formata",desc: "Verzija formata elektronske fakture",
            name: "VerFor", placeholder: "10.07", type: "input", required: true},
        {title: "Šifra ustanove",desc: "Šifra zdravstvene ustanove iz šifarnika RZZO (8 cifara)",
            name: "SifUst", placeholder: "17273019", type: "input", required: true},
        {title: "PIB",desc: "PIB zdravstvene ustanove (9 cifara)",
            name: "PIB", placeholder: "101384959", type: "input", required: true},
        {title: "Vrsta fakture",desc: "Vrsta fakture iz šifarnika RZZO\n" +
                "(obavezan podatak). Vrednosti:\n" +
                " 10 - Primarna zdravstvena zaštita,\n" +
                " 11 - Stomatološke usluge primarne\n" +
                "zdravstvene zaštite",
            name: "VrsFak", placeholder: "10", type: "input", required: true},
        {title: "Period od",desc: "Početak perioda na koji se faktura odnosi",
            name: "PerOd", placeholder: "01.01.2019", type: "input", required: true},
        {title: "Period do",desc: "Kraj perioda na koji se faktura odnosi",
            name: "PerDo", placeholder: "31.01.2019", type: "input", required: true},
        {title: "Datum kreiranja fakture",desc: "Datum kreiranja fakture",
            name: "DatFak", placeholder: "15.02.2019", type: "input", required: true},
        {title: "Iznos",desc: "Pun iznos fakture",
            name: "Izn", placeholder: "15256210,00", type: "input", required: true},
        {title: "Strani broj dokumenta",desc: "Broj pod kojim fakturu vodi ZU",
            name: "StrBrDok", placeholder: "", type: "input"},
    ];
    osiguranik = [
        {title: "Filijala",desc: "Šifra filijale osiguranog lica",
            name: "Fil", placeholder: "10", type: "input", required: true},
        {title: "Ispostava",desc: "Šifra ispostave osiguranog lica",
            name: "Isp", placeholder: "152", type: "input"},
        {title: "Prezime",desc: "Prezime osiguranog lica",
            name: "Prez", placeholder: "MITROVIĆ", type: "input", required: true},
        {title: "Ime",desc: "Ime osiguranog lica",
            name: "Ime", placeholder: "ALEKSANDAR", type: "input", required: true},
        {title: "LBO",desc: "LBO - lični broj osiguranika (11 cifara)",
            name: "LBO", placeholder: "15200038733", type: "input", required: true},
        {title: "Pol",desc: "Pol osiguranika, M - muški, Z - ženski, N - nedefinisan",
            name: "Pol", placeholder: "M", type: "input"},
        {title: "JMBG",desc: "Jmbg osiguranog lica (13 cifara)",
            name: "JMBG", placeholder: "11108970761046", type: "input", required: true},
        {title: "Datum rođenja",desc: "Datum rođenja osiguranog lica",
            name: "DatRodj", placeholder: "11.08.1970", type: "input"},
        {title: "Broj zdravstvene knjižice",desc: "Broj zdravstvene knjižice ili zdravstvene potvrde osiguranog lica (11 cifara) ",
            name: "BZK", placeholder: "12312312312", type: "input", required: true},
        {title: "Nosilac",desc: "1 – ako je osigurano lice nosilac osiguranja, 0 – ako osigurano lice nije nosilac osiguranja",
            name: "Nos", placeholder: "1", type: "input", required: true},
        {title: "Vrsta lečenja",desc: "Vrsta lečenja osiguranika, A – za ambulantno, S – za stacionarno lečenje",
            name: "VrsLec", placeholder: "A", type: "input", required: true},
        {title: "Datum od",desc: "Datum početka lečenja",
            name: "DatOd", placeholder: "03.01.2019", type: "input", required: true},
        {title: "Datum do",desc: "Datum završetka lečenja",
            name: "DatDo", placeholder: "03.01.2019", type: "input", required: true},
        {title: "Uputna dijagnoza",desc: "Uputna dijagnoza osiguranika",
            name: "UputDij", placeholder: "A00", type: "input", required: true},
        {title: "Završna dijagnoza", desc: "Završna dijagnoza osiguranika",
            name: "ZavrDij", placeholder: "A00", type: "input", required: true},
        {title: "OOP",desc: "Osnov oslobađanja od plaćanja participacije",
            name: "OOP", placeholder: "", type: "input"},
        {title: "Broj kartona",desc: "Broj kartona ili protokola",
            name: "BrKart", placeholder: "1", type: "input", required: true},
        {title: "Osnov osiguranja",desc: "Osnov osiguranja (4 cifre)",
            name: "OO", placeholder: "1010", type: "input", required: true},
        {title: "Osiguranik po konvenciji",desc: "1 - ako je osigurano lice po konvenciji, 0 - inače",
            name: "PoKon", placeholder: "0", type: "input", required: true},
        {title: "Država",desc: "Šifra države za osiguranike po konvencijama",
            name: "Drz", placeholder: "", type: "input"},
        {title: "Vrsta isprave za konvenciju",desc: "Vrsta isprave za konvenciju",
            name: "VrsIspKon", placeholder: "", type: "input"},
        {title: "Broj isprave po konvenciji",desc: "Broj isprave za konvenciju ",
            name: "BrIspKon", placeholder: "", type: "input",},
        {title: "Napomena konvencija",desc: "Napomena koja se odnosi na vrstu isprave po konvenciji",
            name: "NapKon", placeholder: "", type: "input"},
        {title: "Tip usluge",desc: "Oznaka šifarnika TipUsluge",
            name: "TipUsl", placeholder: "0", type: "input", required: true},
        {title: "Lokacija",desc: "Oznaka sedišta zdravstvene ustanove,\n" +
                "ambulante ili zdravstvene stanice u kojoj je\n" +
                "pružena epizoda lečenja",
            name: "Lok", placeholder: "1000", type: "input", required: true},

    ];
    dodatne = [
        {title: "Dodatne dijagnoze",desc: "",
            name: "DDijag", placeholder: "E107", type: "input", required: false},
    ];
    usluge = [
        {title: "Datum pružanja usluge",desc: "Datum pružanja usluge",
            name: "DatUsl", placeholder: "03.01.2019", type: "input", required: true},
        {title: "Šifra usluge",desc: "Šifra pružene usluge",
            name: "SifUsl", placeholder: "1000025", type: "input", required: true},
        {title: "Količina",desc: "Broj pruženih usluga (ceo pozitivan broj veći od nule)",
            name: "Kol", placeholder: "1", type: "input", required: true},
        {title: "Jedinična cena",desc: "Jedinična cena usluge",
            name: "JedCen", placeholder: "217,43", type: "input", required: true},
        {title: "Učešće",desc: "Iznos naplaćene participacije, odnosno učešća",
            name: "Ucs", placeholder: "0", type: "input", required: true},
        {title: "LBO lekara",desc: "LBO lekara koji je pružio uslugu (11 cifara)",
            name: "LBOLekar", placeholder: "15200020707", type: "input", required: false},
        {title: "Ime lekara",desc: "Ime lekara koji je pružio uslugu",
            name: "ImeLekara", placeholder: "Olga", type: "input", required: false},
        {title: "Prezime lekara",desc: "Prezime lekara koji je pružio uslugu",
            name: "PrezLekara", placeholder: "Andrejević", type: "input", required: false},
        {title: "LBO lekara koji je napisao uput",desc: "LBO lekara koji je napisao uput za uslugu (11 cifara)",
            name: "LBOLekarUputio", placeholder: "15200020707", type: "input", required: false},
        {title: "Šifra službe",desc: "Šifra službe",
            name: "SifSlu", placeholder: "1001", type: "input", required: true},
        {title: "Eksterni ID usluge",desc: "Eksterni ID usluge",
            name: "EksID", placeholder: "", type: "input", required: false},
        {title: "Napomena", desc: "Slobodan unos podataka\n",
            name: "Nap", placeholder: "", type: "input", required: false},

    ];
    sanmedmat = [
        {title: "Šifra materijala",desc: "Šifra utrošenog materijala ili leka",
            name: "SifSanMat", placeholder: "2600013", type: "input", required: true},
        {title: "Količina",desc: "Količina izdatog materijala ili leka (broj veći od nule)",
            name: "KolMat", placeholder: "1,50", type: "input", required: true},
        {title: "Jedinična cena",desc: "Jedinična cena utrošenog materijala ili leka",
            name: "JedCenMat", placeholder: "10,23", type: "input", required: true},
    ];
    usluga_atribut = [
        {title: "Oznaka atributa",desc: "Oznaka atributa usluge",
            name: "Atribut", placeholder: "00", type: "input", required: true},
    ];
    usluga_ishod = [
        {title: "Oznaka ishoda",desc: "Ishod pružene usluge",
            name: "Ishod", placeholder: "00", type: "input", required: true},
    ];

    constructor(props) {
        super(props);
        var holder = {};
        const all = [this.ustanova, this.osiguranik, this.dodatne, this.usluge, this.sanmedmat,
        this.usluga_atribut, this.usluga_ishod];
        for(let j=0; j<all.length; j++)    {
            const coll = all[j];
            for(let i=0; i<coll.length; i++)    {
                const item = coll[i];
                holder[item.name] = '';
            }
        }
        console.log(holder);
        this.state = {
            form: holder
        }
    }


    change = (e) => {
        const name = e.target.name;
        this.setState({...this.state, form: {...this.state.form, [name]: e.target.value}});
    }

    submit = (e) => {
        //e.preventDefault();
        postDownloadXml(this.state.form).then((response) => {
            console.log(response.data)
        });
    }

    RenderItem = (item) => {
        return <div key={item.name} className="mb-3">
            <div className="d-flex align-items-center">
                <div style={{flex: "300px 0 0"}} className="d-flex justify-content-between" title={item.desc}>
                    <div>
                        {item.title}
                        {item.required && <span className="text-danger ml-2">*</span> }
                    </div>
                    <i className="fa fa-info-circle text-info mr-2" />
                </div>
                <div className="flex-grow-1">
                    <input type="text"
                           placeholder={item.placeholder}
                           onChange={this.change}
                           value={this.state.form[item.name]}
                           //required={item.required}
                           name={item.name} className="form-control" />
                </div>
            </div>
        </div>
    }

    render = () => {
        return <div className="container mt-4" id="report" style={{marginBottom: 500}}>
            <form action={server("rfzo/download")} onSubmit={this.submit} method="POST">
                <input type="hidden" name="token" value="SDds989(DF&^js7TGFDUYI" />
                <div className="row">
                    <div className="col-md-3 col-5">
                        <div id="report_header" className={this.state.fixedHeader ? "report_fixed_header" : ""}>
                            <button
                                type="submit"
                                onClick={() => this.load(this.props.drawer.appointment.id, true)}
                                className="btn btn-info btn-block">
                                Download XML
                            </button>
                            <button
                                type="button"
                                onClick={() => this.props.closeDrawer("xml")}
                                className="btn btn-link font_09 mt-2">Zatvorite izveštaj</button>
                        </div>
                    </div>
                    <div className="col-md-9 col-7">
                        <div className="d-flex align-items-center">
                            <div style={{flex: "300px 0 0"}} className="d-flex justify-content-between">

                            </div>
                            <div className="flex-grow-1">
                                <p>
                                    Svi datumi moraju da budu u format dd.mm.gggg. <br/>
                                    Numerički podaci (Iznos, Ucesce, Jedinicna cena, Ukupna cena) treba da budu u formatu #0,00
                                    odnosno, kao delimiter za decimalni deo treba koristiti zarez (,) i bez delimitera za hiljade.
                                </p>
                            </div>
                        </div>


                        <div className="font-weight-bold font_4 mb-3">Ustanova</div>
                        <div className="mb-4">
                            {this.ustanova.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>

                        <div className="font-weight-bold font_4 mb-3">Osiguranik</div>
                        <div className="mb-4">
                            {this.osiguranik.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>

                        <div className="font-weight-bold font_4 mb-3">Dodatne Dijagnoze</div>
                        <div className="mb-4">
                            {this.dodatne.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>

                        <div className="font-weight-bold font_4 mb-3">Usluge</div>
                        <div className="mb-4">
                            {this.usluge.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>

                        <div className="font-weight-bold font_4 mb-2">SanMedMat</div>
                        <div className="mb-3">
                            Peti slog sadrži podatke o utrošenom sanitetskom medicinskom materijalu i lekovima za pruženu
                            uslugu u sledećem redosledu
                        </div>
                        <div className="mb-4">
                            {this.sanmedmat.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>


                        <div className="font-weight-bold font_4 mb-2">Usluga_atribut</div>
                        <div className="mb-3">
                            Polje Oznaka atributa usluge (šifarnik Usluga_atribut) može imati sledeće vrednosti: <br/>
                            - 00 - bez atributa <br/>
                            - 01 - usluga pružena licu sa invaliditetom <br/>
                            - 02 - usluga pružena na terenu <br/>
                            - 03 - ponovni specijalističko-konsultativni pregled <br/>
                            Svaka usluga mora imati najmanje jedan od ponuđenih atributa a može ih imati više. <br/>
                            Podrazumevana vrednost ovog polja je 00.
                        </div>
                        <div className="mb-4">
                            {this.usluga_atribut.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>

                        <div className="font-weight-bold font_4 mb-2">Usluga_ishod</div>
                        <div className="mb-3">
                            Polje Ishod pružene usluge (šifarnik Ishod_lecenja) može imati sledeće vrednosti: <br />
                            - 00 - bez ishoda <br />
                            - 01 - završeno lečenje <br />
                            - 02 - zakazan ponovni pregled <br />
                            - 03- upućivanje specijalisti u okviru ustanove <br />
                            - 04 - upućivanje specijalisti van ustanove <br />
                            - 05 - upućen na bolničko lečenje <br />
                            - 06 - Uput u IZJZ / ZZJZ <br />
                            - 07 - Propisivanje recepata i/ili pomagala <br />
                            - 08 - Evidencija po prenosu ovlašćenja
                        </div>
                        <div className="mb-4">
                            {this.usluga_ishod.map((item) => {
                                return this.RenderItem(item);
                            })}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    }


    load = () => {

    }

    componentDidMount() {
        const appointment = this.props.drawer.appointment;
        const patient = appointment.patient;
        var Pol = "N";
        if(patient.sex === 1)   Pol = "M";
        if(patient.sex === 1)   Pol = "Z";
        this.setState({...this.state, form: {...this.state.form,
                Prez: patient.lastname, Ime: patient.name, Pol: Pol, JMBG: patient.jmbg,
                DatRodj: patient.dob ? moment(patient.dob).format("DD.MM.YYYY.").toString(): ""}})
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.xml,
    office: state.office
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(XmlDrawer);
