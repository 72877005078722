import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Page404 from "../views/layout/Page404";
import GuestMiddleware from "../providers/middlewares/GuestMiddleware";
import Login from "../views/cms/user/Login";
import UserMiddleware from "../providers/middlewares/UserMiddleware";
import Calendar from "../views/cms/calendar/Calendar";
import routes from "./routes";
import UserRegister from "../views/cms/user/UserRegister";
import Account from "../views/cms/user/Account";
import Users from "../views/cms/users/Users";
import Error from "../views/framework/Error";
import User from "../views/cms/users/User";
import PatientRegister from "../views/cms/patient/PatientRegister";
import Patients from "../views/cms/patients/Patients";
import Patient from "../views/cms/patients/Patient";
import Appointments from "../views/cms/appointments/Appointments";
import Program from "../views/cms/program/Program";
import Authorization from "../views/cms/user/Authorization";
import Offices from "../views/cms/offices/Offices";
import Diseases from "../views/cms/diseases/Diseases";
import Examinations from "../views/cms/examinations/Examinations";
import Machines from "../views/cms/machines/Machines";
import Roles from "../views/cms/roles/Roles";
import Templates from "../views/cms/templates/Templates";
import Statistics from "../views/cms/statistics/Statistics";


class App extends React.Component {

    render = () => {
        return <div>
            <Error />

            <Router>
                <Switch>
                    <GuestMiddleware exact path={routes.login.route} component={Login} />
                    <GuestMiddleware exact path={routes.authorization.route} component={Authorization} />

                    <UserMiddleware exact path={routes.userRegister.route} component={UserRegister} />
                    <UserMiddleware exact path={routes.account.route} component={Account} />
                    <UserMiddleware exact path={routes.users.route} component={Users} />
                    <UserMiddleware exact path={routes.user.route(":id?")} component={User} />

                    <UserMiddleware exact path={routes.patientRegister.route} component={PatientRegister} />
                    <UserMiddleware exact path={routes.patients.route} component={Patients} />
                    <UserMiddleware exact path={routes.patient.route(":id?")} component={Patient} />


                    <UserMiddleware exact path="/" component={Calendar} />
                    <UserMiddleware exact path={routes.calendar.route} component={Calendar} />
                    <UserMiddleware exact path={routes.surgery.route} component={Calendar} />
                    <UserMiddleware exact path={routes.apointments.route} component={Appointments} />
                    <UserMiddleware exact path={routes.program.route} component={Program} />

                    <UserMiddleware exact path={routes.settingsOffices.route} component={Offices} />
                    <UserMiddleware exact path={routes.settingsDiseases.route} component={Diseases} />
                    <UserMiddleware exact path={routes.settingsExaminations.route} component={Examinations} />
                    <UserMiddleware exact path={routes.settingsMachines.route} component={Machines} />
                    <UserMiddleware exact path={routes.settingsRoles.route} component={Roles} />
                    <UserMiddleware exact path={routes.settingsTemplates.route} component={Templates} />
                    <UserMiddleware exact path={routes.statistics.route} component={Statistics} />

                    <Route path="*" component={Page404} />
                </Switch>
            </Router>
        </div>
    }
}
export default App;
