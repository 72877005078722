import React from "react";
import {Route, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import routes from "../../app/routes";


const GuestMiddleware = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        rest.user !== false
            ? <Redirect to={routes.calendar.route} />
            : <Component {...props} />
    )}/>
};

const mapStateToProps = state =>    ({
    user: state.user
});

export default connect(mapStateToProps)(GuestMiddleware);
