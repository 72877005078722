import React from 'react';


class Element extends React.Component {

    state = {
        value: this.props.value || ""
    }

    changeField = (e) => {
        const value = e.target.value;
        this.setState({...this.state, value: value});
        this.props.patchReport(this.props.item.code, e.target.value)
    }

    toggleCheckbox = (e) => {
        const value = e.target.checked ? 1 : null;
        this.setState({...this.state, value: value});
        this.props.patchReport(this.props.item.code, value);
    }

    clear = () => {
        this.setState({...this.state, value: ""});
        this.props.patchReport(this.props.item.code, "");
    }

    render = () => {
        const item = this.props.item;
        return <div className="col">
            <div className="row align-items-center">
                <div className={`col-md-${this.props.col} text-right`}>{item.name}:</div>
                <div className="col-md">
                    <div className="position-relative">
                        {item.type === "input" &&
                        <input type="text"
                               onChange={this.changeField}
                               value={this.state.value}
                               onBlur={(e) => this.props.patchReport(item.code, e.target.value)}
                               name={item.code} className="form-control" />}
                        {item.type === "group" &&
                        <div className="input-group">
                            <input type="text"
                                   onChange={this.changeField}
                                   onBlur={(e) => this.props.patchReport(item.code, e.target.value)}
                                   value={this.state.value}  name="tos" className="form-control" />
                            <div className="input-group-append">
                                <span className="input-group-text"> mmHg </span>
                            </div>
                        </div>}
                        {item.type === "textarea" &&
                        <textarea
                            onChange={this.changeField}
                            onBlur={(e) => this.props.patchReport(item.code, e.target.value)}
                            value={this.state.value} name={item.code} className="form-control" />}
                        {item.type === "checkbox" &&
                        <label className="chbx d-block">
                            <input
                                onChange={this.toggleCheckbox}
                                type="checkbox" value={1} checked={this.state.value} name={item.code} /><span className="mr-2" />
                        </label>}

                        {this.props.clear && this.state.value !== "" &&
                        <button
                            onClick={this.clear}
                            title="Obrišite"
                            className="btn btn-sm btn-danger element_clear">
                            <i className="fa fa-close" />
                        </button>}
                    </div>

                </div>
            </div>
        </div>
    }

}

export default Element;
