import {SET_OFFICE} from "../actions/officeActions";
import {checkOffice} from "../../providers/officeProvider";

function office(state = checkOffice(), {type, payload})      {
    switch(type)        {
        case SET_OFFICE:
            return payload;
        default:
            return state;
    }
}
export default office;