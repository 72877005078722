
export function changeOffice(id) {
    setCookie('office', id, null);
    window.location.reload();
}

export function checkOffice() {
    var name = "office=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

function setCookie(name, data, exdays=null) {
    var expires = '';
    if(exdays !== null)     {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires="+ d.toUTCString() + ";";
    }
    document.cookie = name + "=" + data + ";" + expires + "path=/";
}
