import React from 'react';
import {Document, Image, Page, Text, StyleSheet, View, Font} from "@react-pdf/renderer";
import robotoSlabLight from "../../../assets/fonts/RobotoSlab-Light.ttf";
import robotoSlabMedium from "../../../assets/fonts/RobotoSlab-Medium.ttf";
import robotoSlabItalic from "../../../assets/fonts/Roboto-Italic.ttf";
import {fileProxy} from "../../../app/helpers";
import moment from "moment";
import { parse } from 'node-html-parser';


Font.register({
    family: 'Roboto',
    fonts: [
        {src: robotoSlabLight},
        {src: robotoSlabMedium, fontWeight: "bold"},
        {src: robotoSlabItalic, fontStyle: "italic"},
    ]
});
const styles = StyleSheet.create({
    split: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    left: {
        width: "20%",
        fontWeight: "bold"
    },
    left2: {
        width: "20%",
        fontWeight: "bold"
    },
    right2: {
        paddingLeft: 10,
        borderLeft: "1 solid black",
        paddingTop: 3,
        paddingBottom: 3,
        width: "80%"

    }
});

class PdfRenderer extends React.Component   {

    state = {
        structure: [],
    }

    tr = (name1, val1, name2, val2, gray) => {
        const bcg = gray ? "#f0f0f0" : "white";
        const padding = 3;
        return <View style={styles.split}>
            <View style={{...styles.split, width: "50%"}}>
                <View style={{width: "30%"}}>
                    <Text style={{margin: 1, textAlign: "right", padding: padding, backgroundColor: bcg, fontWeight: "bold"}}> {name1} </Text>
                </View>
                <View style={{width: "70%"}}>
                    <Text style={{margin: 1, padding: padding, backgroundColor: bcg}}> {val1} </Text>
                </View>
            </View>
            <View style={{...styles.split, width: "50%"}}>
                <View style={{width: "30%"}}>
                    <Text style={{margin: 1, textAlign: "right", padding: padding, backgroundColor: bcg, fontWeight: "bold"}}> {name2} </Text>
                </View>
                <View style={{width: "70%"}}>
                    <Text style={{margin: 1, padding: padding, backgroundColor: bcg}}> {val2} </Text>
                </View>
            </View>
        </View>
    }

    grayColor = true;
    gray = () => {
        const gray = this.grayColor;
        this.grayColor = !this.grayColor;
        return gray;
    }

    domLoop = (el) => {
        let children = [];
        if(el.childNodes.length > 0)       {
            for(let i=0; i<el.childNodes.length; i++)       {
                let child = this.domLoop(el.childNodes[i]);
                children.push(child);
            }
        } else if(el.rawTagName === 'p')    {
            return
        }
        let Container;
        if(el.rawTagName === 'p')       {
            Container = <Text>{children}{"\n"}</Text>
        }
        else if(el.rawTagName === 'strong')       Container = <Text style={{fontWeight: "bold"}}>{children}</Text>
        else if(el.rawTagName === 'u')       Container = <Text style={{textDecoration: "underline"}}>{children}</Text>
        else if(el.rawTagName === 'em')       Container = <Text style={{fontStyle: "italic"}}>{children}</Text>
        //else if(el.rawTagName === 'span')       Container = <Text>{children}</Text>
        else if(el.nodeType === 1)       Container = <Text>{children}</Text>
        else                            Container = <Text>{el.rawText.replace(/&nbsp;/g, " ")
                .replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&").replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'")}</Text>
        return Container;
    }

    anamneza = (an) => {
        if(!an) return " ";
        const root = parse(`<p>${an}</p>`);
        return this.domLoop(root);
    }
    otpust = (an) => {
        if(!an) return " ";
        const root = parse(`<p>${an}</p>`);
        return this.domLoop(root);
    }

    render = () => {
        const otpusnaLista = this.props.lista === "otpusna";
        const app = this.props.appointment;
        const anamneza = this.anamneza(app.report.anamneza);
        const otpust = this.otpust(app.report.otpust);
        const instrumentarka = this.props.users.find(item => item.id === app.instrumentarka);
        const anesteziolog = this.props.users.find(item => item.id === app.anesteziolog);
        const anesteticarka = this.props.users.find(item => item.id === app.anesteticarka);

        return <Document title={this.props.reportName}>
            <Page size="LETTER" wrap style={{padding: 20, fontFamily: 'Roboto', fontSize: this.props.fontSize}}>

                {(this.props.office && this.props.office.report_logo_name)
                    ? <View style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                        <Image src={fileProxy(this.props.office.report_logo_name)} style={{height: 60}} />
                    </View>
                    : <View />}

                <View style={{textAlign: "center", fontSize: 14, marginBottom: 30, fontWeight: "bold"}}>
                    <Text> {this.props.reportName} </Text>
                </View>

                <View style={{marginBottom: 10, fontWeight: "bold"}}>
                    <Text> Ime i prezime pacijenta, godište: {app.patient.name} {app.patient.lastname} {app.patient.dob && moment(app.patient.dob).format("DD/MM/YYYY")}</Text>
                </View>

                <View style={{marginBottom: 10}}>
                    <Text>Datum operacije: {moment(app.start).format("DD/MM/YYYY").toString()}</Text>
                </View>
                <View style={{marginBottom: 10}}>
                    <Text>Operator: {app.user.title} {app.user.name} {app.user.lastname}</Text>
                </View>

                {!otpusnaLista
                    ? <View>
                        <View style={{marginBottom: 10}}>
                            {instrumentarka
                                ? <Text>Instrumentarka: {instrumentarka.title} {instrumentarka.name} {instrumentarka.lastname}</Text>
                                : <View />}
                        </View>
                        <View style={{marginBottom: 10}}>
                            {anesteziolog
                                ? <Text>Anesteziolog: {anesteziolog.title} {anesteziolog.name} {anesteziolog.lastname}</Text>
                                : <View />}
                        </View>
                        <View style={{marginBottom: 20}}>
                            {anesteticarka
                                ? <Text>Anestetičarka: {anesteticarka.title} {anesteticarka.name} {anesteticarka.lastname}</Text>
                                : <View />}
                        </View>
                    </View>
                    : <View />}


                {!otpusnaLista && app.diseases.length > 0
                    ? <View style={{...styles.split, marginBottom: 20}}>
                        <Text style={styles.left2}> ICD 10: </Text>
                        <Text style={styles.right2}>
                            {app.diseases.map((item) => {
                                return `${item.code} - ${item.name}
`;
                            })}
                        </Text>
                    </View>
                    : <View />}


                {!otpusnaLista && app.report.anamneza
                    ? <View style={{marginBottom: 20}}>
                        <Text style={{marginBottom: 6, fontWeight: "bold"}}> Operativni nalaz: </Text>
                        <View>{anamneza}</View>
                    </View>
                    : <View />}

                {otpusnaLista && app.report.otpust
                    ? <View style={{marginBottom: 20}}>
                        <View>{otpust}</View>
                    </View>
                    : <View />}

                {!otpusnaLista
                    ? <View>
                        {app.report.kontrola_kod_oftamologa === null
                            ? <View style={{marginBottom: 5}}>
                                <Text>{`Kontrola sutra ${moment(app.start).add(1, "day").format("DD/MM/YYYY")} u 9h`}</Text>
                            </View>
                            : <View style={{marginBottom: 5}}>
                                <Text>{app.report.kontrola_kod_oftamologa || " "}</Text>
                            </View>}

                    </View>
                    : <View />}



                <View style={{marginTop: 20, display: "flex", alignItems: "right", fontWeight: "bold"}}>
                    <Text style={{marginBottom: 10}}>Ordinirajući hirurg</Text>
                    <Text>{app.user.title} {app.user.name} {app.user.lastname}</Text>
                </View>


                <View style={{marginTop: 20}}>
                    <Text style={{color: "#b5b4b4", fontSize: 8, textAlign: "center"}}>
                        Pacijent je obavešten o dijagnozi i prognozi svoje bolesti, predloženoj medicinskoj meri, cilju, koristi kao i mogućim komplikacijama, rizicima i
                        posledicama preduzimanja odnosno nepreduzimanja iste. Takođe, prihvata dijagnostičke, terapijske I hiruške procedure koje su preduzete . Izjavljuje da
                        nema alergijskih i dr. rizika.
                    </Text>
                </View>
            </Page>
        </Document>
    };
}

export default PdfRenderer;
