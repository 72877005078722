import {SET_USER, UPDATE_USER, SET_OFFICES} from "../actions/userActions";
import {checkUser} from "../../providers/userProvider";

function user(state = checkUser(), {type, payload})      {
    switch(type)        {
        case UPDATE_USER:
            return {...state, [payload.field]: payload.value};
        case SET_USER:
            return payload;
        case SET_OFFICES:
            return {...state, offices: payload};
        default:
            return state;
    }
}
export default user;