import React from 'react';
import {
    deleteAppointment,
    deleteRole,
    patchAppointment,
    patchRole,
    putAppointment,
    putTemplate
} from "../../../app/hooks";
import {connect} from "react-redux";
import {openDrawer} from "../../../store/actions/drawersActions";


class TemplateItem extends React.Component {

    state = {
        edit: false,
        item: this.props.item,
    }

    editField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, item: {...this.state.item, [name]: value}});
    }

    save = (e) => {
        this.editField(e);
        const name = e.target.name;
        const value = e.target.value;
        patchAppointment({id: this.props.item.id, field: name, value: value}).then((response) => {
           this.props.reload();
        });
    }

    delete = (item) => {
        var r = window.confirm(`Želite da obrišete?`);
        if (r !== true) {
            return false;
        }
        deleteAppointment({id: this.props.item.id}).then((response) => {
            this.props.reload();
        });
    }

    toggleEdit = () => {
        this.setState({...this.state, edit: !this.state.edit});
    }

    render = () => {
        const item = this.state.item;
        return <div className="mb-2">
                {item.surgery
                    ? <button
                        onClick={() => this.props.openDrawer({name: "report", appointment: item, template: true})}
                        className="btn btn-success ml-3">
                        <i className="fas fa-first-aid font_2" />
                        <strong> {item.template_name || "Hirurgija"} </strong>
                    </button>
                    : <button
                    onClick={() => this.props.openDrawer({name: "report", appointment: item, template: true})}
                    className="btn btn-info ml-3">
                    <i className="far fa-clipboard font_2" />
                    <strong> {item.template_name || "Izveštaj"} </strong>
                </button>}

                    {this.state.edit &&
                        <>
                            <span className="ml-3"> Naziv: </span>
                            <input type="text" className="form-control w-auto d-inline-block ml-1" name="template_name" maxLength={191}
                                   onChange={this.editField} onBlur={this.save} value={item.template_name || ""} />
                            <button onClick={this.delete} className="btn btn-danger ml-2">Obrisite</button>
                      </>}

                    <button onClick={this.toggleEdit} className="btn ml-3 btn-white">
                        {this.state.edit ? "Zatvorite" : "Prepravite"}
                    </button>
                </div>
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    openDrawer: openDrawer
}
export default  connect(mapStateToProps, mapDispatchToProps)(TemplateItem);
