import React from 'react';


class Form extends React.Component   {

    state = {
        form: this.props.form,
        error: {},
        success: false,
        loading: false
    }

    changeField = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(e.target.type === "checkbox")       {
            value = !!e.target.checked;
        }
        if(e.target.type === "radio")       {
            value = e.target.getAttribute("data-value");
        }
        this.setState({...this.state, form: {...this.state.form, [name]: value}});
    };

    change = (field, value) => {
        this.setState({...this.state, form: {...this.state.form, [field]: value}});
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}});
        this.props.hook(this.state.form).then((response) => {
            const form = this.props.reset ? this.props.form : this.state.form;
            this.setState({...this.state, form: form, loading: false, success: true, error: {}});
            if(this.props.onSuccess)     {
                this.props.onSuccess(response, this.state.form);
            }
        }).catch((error) => {
            this.setState({...this.state, loading: false, error: error, success: false});
            if(this.props.onError)     {
                this.props.onError(error, this.state.form);
            }
        });
    }

    render = () => {
        return <form onSubmit={this.onSubmit} className={this.props.className}>
            {this.loop(this.props.children)}

            {this.state.error.message &&
            <div className="alert alert-danger mt-3">
                {this.state.error.message}
            </div>}

            {this.state.success &&
            <div className="alert alert-success mt-3">
                {this.props.messageSuccess || "Uspešno."}
            </div>}
        </form>
    };

    loop = (el, acc = [], key=0) => {
        if(!el) return acc;
        if(el.props && el.props.ignore)   {
            acc.push(el);
            return acc;
        }
        if(el.type === "select")      {
            el = {...el, props: {...el.props, value: this.state.form[el.props.name], onChange: this.changeField}};
            const obj = <React.Fragment key={key}>
                {el}
                {this.state.error[el.props.name] &&
                <div className="alert alert-danger p-2 mt-1">{this.state.error[el.props.name]}</div>}
            </React.Fragment>
            acc.push(obj);
            return acc;
        }
        if(el.props && el.props.name === "color")      {
            el = {...el, props: {...el.props, color: this.state.form[el.props.name], onChangeComplete: (color) => this.change("color", color.hex)}};
            const obj = <React.Fragment key={key}>
                {el}
                {this.state.error[el.props.name] &&
                <div className="alert alert-danger p-2 mt-1">{this.state.error[el.props.name]}</div>}
            </React.Fragment>
            acc.push(obj);
            return acc;
        }
        if(Array.isArray(el))     {
            const acc2 = [];
            for(let i=0; i<el.length; i++)      {
                this.loop(el[i], acc2, i);
            }
            acc.push(acc2);
            return acc;
        }
        if(el.props && el.props.children)      {
            const children = this.loop(el.props.children, []);
            acc.push({...el, props: {...el.props, children: children}});
            return acc
        }
        if(!el.props)  {
            acc.push(el);
            return acc;
        }
        if(el.props.type === "submit")      {
            el = {...el, props: {...el.props, disabled: this.state.loading, value: this.state.loading ? "Loading..." : el.props.value}};
            acc.push(el);
            return acc;
        }
        if(!el.props.name)  {
            acc.push(el);
            return acc;
        }
        if(!Object.keys(this.props.form).includes(el.props.name))       {
            acc.push(el);
            return acc;
        }
        el = {...el, props: {...el.props, value: this.state.form[el.props.name], onChange: this.changeField}};
        if(el.props.type === "checkbox")      {
            el = {...el, props: {...el.props, checked: !!this.state.form[el.props.name]}};
        }
        if(el.props.type === "radio")      {
            el = {...el, props: {...el.props, checked: parseInt(this.state.form[el.props.name]) ===  parseInt(el.props['data-value'])}};
        }
        const obj = <React.Fragment key={key}>
            {el}
            {this.state.error[el.props.name] &&
            <div className="alert alert-danger p-2 mt-1">{this.state.error[el.props.name]}</div>}
        </React.Fragment>
        acc.push(obj);
        return acc;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(this.props.form) !== JSON.stringify(prevProps.form))     {
            var change = {};
            for(var key in this.props.form)     {
                if(this.props.form[key] !== prevProps.form[key])    {
                    change[key] = this.props.form[key];
                }
            }
            this.setState({...this.state, form: {...this.state.form, ...change}});
        }
    }
}

export default Form;
