
export function contacts()     {
    return [
        {id: 1, name: "Telefon", value: "phone"},
        {id: 2, name: "Poruka", value: "sms"},
        {id: 3, name: "Email", value: "email"},
        {id: 4, name: "Viber", value: "viber"},
        {id: 5, name: "whatsapp", value: "WhatsApp"},
    ]
}
