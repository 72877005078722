import React from 'react';
import {connect} from "react-redux";
import {getUser, getUsersSchedules} from "../../app/hooks";
import moment from "moment";


class UserDrawer extends React.Component {

    state = {
        user: {},
        schedules: []
    }

    render = () => {
        const user = this.state.user;
        return  <div className="p-3">
            <div className="font_6 font-weight-bold mb-3">
                {user.title} {user.name} {user.lastname}
            </div>
            <div className="accent_block mb-3">
                <div className="font_4 mb-3">
                    {user.role_name}
                </div>
                <div className="mb-3">
                    Telefon: &nbsp;
                    {!user.phone
                        ? <i> nije postavljen </i>
                        : <a href={"tel:" + user.phone}>{user.phone}</a>}
                </div>
                <div className="mb-3">
                    E-mail adresa: &nbsp;
                    {!user.email
                        ? <i> nije postavljen </i>
                        : <a href={"mailto:" + user.email}>{user.email}</a>}
                </div>

                <div className="mb-3">
                    Ordinacije: &nbsp;
                    {user.offices && user.offices.map((item) => {
                        return <span className="mr-3" key={item.id}>
                            {item.name}
                        </span>
                    })}
                </div>

                {user.color &&
                <div className="d-flex align-items-center">
                    <div className="mr-3"> Boja: </div>
                    <div className="" style={{width: 80, height: 30, background: user.color}}>
                    </div>
                </div>}
            </div>

            {this.state.schedules.length > 0 &&
            <div className="mb-3">
                <div className="mb-3"> Radni sati: </div>
                <table className="table">
                    <tbody>
                    {this.state.schedules.map((item) => {
                        const start = moment(item.start);
                        const end = moment(item.end);
                        return <tr key={item.id}>
                            <td>{start.format("DD.MM. YY. ddd").toString()}</td>
                            <td>{start.format("HH:mm").toString()} - {end.format("HH:mm").toString()}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>}
        </div>
    }

    componentDidMount() {
        const id = this.props.drawer.user.user_id || this.props.drawer.user.id
        getUser({id: id}).then((response) => {
            this.setState({...this.state, user: response.data});
        });
        getUsersSchedules({user: id}).then((response) => {
            this.setState({...this.state, schedules: response.data});
        });
    }
}

const mapStateToProps = state => ({
    drawer: state.drawers.user,
});
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer);
