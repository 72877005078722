import React from 'react';
import Overlay from "./Overlay";


class Dropdown extends React.Component   {

    state = {
        show: false
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    toggle = (e) => {
        e.preventDefault();
        if(!this.state.show)     {
            document.addEventListener('click', this.outsideClickListener);
        }
        else        {
            this.removeClickListener();
        }
        this.setState({...this.state, show: !this.state.show});
    };

    removeClickListener = () => {
        document.removeEventListener('click', this.outsideClickListener)
    }
    outsideClickListener = (e) => {
        if(!this.myRef.current)  {
            return false;
        }
        if(this.myRef.current.contains(e.target))    {
            return false;
        }
        this.removeClickListener();
        this.setState({...this.state, show: false});
    };

    render = () => {
        let anchor = "";
        if(this.props.anchor === "right")       {
            anchor = "right"
        }
        const className = this.props.className ? "drop_down d-inline-block " + this.props.className : "drop_down d-inline-block";
        return  <div className={className}>
            <div>
                <a href="/" className="btn btn-white btn-sm" onClick={this.toggle} ref={this.myRef}>
                    <span className={this.props.className}> {this.props.title} </span>
                    <i className="fa fa-caret-down ml-1" />
                </a>
            </div>

            <div className={this.state.show ? "drop_down_content " + anchor : "drop_down_content hide " + anchor}>
                <div className="d-md-none font_3 p-3">
                    {this.props.title}
                    <i className="font_4 float-right fa fa-close" />
                </div>
                {this.props.children}
            </div>

            <Overlay show={this.state.show} mobile={true} />
        </div>
    };
}

export default Dropdown;
