import React from "react";
import {connect} from "react-redux";
import {closeDrawer} from "../../store/actions/drawersActions";

class Error extends React.Component {

    render = () => {
        return <>
            <div className={this.props.drawer.status ? "error_alert alert_open" : "error_alert"}>
                {this.props.drawer.content && this.props.drawer.content.message}
            </div>
            </>
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.drawer.status)        {
            setTimeout(() => {
                this.props.closeDrawer("error");
            }, 5000);
        }
    }

}
const mapStateToProps = state => ({
    drawer: state.drawers.error
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Error);
