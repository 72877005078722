import React from 'react';
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";
import JoditEditor from "jodit-react";
import Guard from "../layout/Guard";
import Loader from "../framework/Loader";
import {
    deleteAppointmentImage,
    getAppointments,
    getExaminations,
    getMachines, getOperationValues, getTemplates, getUser, getUsers,
    patchAppointmentReport, putAppointmentImages,
    putAppointmentTemplate
} from "../../app/hooks";
import Element from "../cms/appointments/Element";
import Examination from "../cms/appointments/Examination";
import AddExamination from "../cms/appointments/AddExamination";
import Diseases from "../cms/appointments/Diseases";
import {diagnosis} from "../../providers/diagnosis";
import Modal from "../framework/Modal";
import Appointments from "../cms/appointments/Appointments";
import {gallery, times} from "../../app/helpers";
import moment from "moment";


class ReportDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.editorRef2 = React.createRef();
        this.anamnezaRef = React.createRef();
        this.zakljucakRef = React.createRef();
        this.localisRef = React.createRef();
        this.preglediRef = React.createRef();
        this.klasifikacijaRef = React.createRef();
        this.dijagnostikaRef = React.createRef();
        this.operacijaRef = React.createRef();
        this.colorRef = React.createRef();
        this.stereoRef = React.createRef();

        this.kvpRef = React.createRef();
        this.octRef = React.createRef();
        this.ultrazvukRef = React.createRef();
        this.meibomografijaRef = React.createRef();
        this.topografijaRef = React.createRef();
        this.statusLocalis = [
            [{name: "VOD", code: "vod", type: "input"}, {name: "VOS", code: "vos", type: "input"}],
            [{name: "TOD", code: "tod", type: "group"}, {name: "TOS", code: "tos", type: "group"}],
            [{name: "ROD", code: "rod", type: "input"}, {name: "ROS", code: "ros", type: "input"}],
            [{name: "CCT OD", code: "cct_od", type: "input"}, {name: "CCT OS", code: "cct_os", type: "input"}],
            [{name: "KF OD", code: "kf_od", type: "group"}, {name: "KF OS", code: "kf_os", type: "group"}],
            [{name: "AR OD", code: "ar_od", type: "input"}, {name: "AR OS", code: "ar_os", type: "input"}],
            [{name: "Napomena", code: "napomena", type: "input", clear: true}],
            [{name: "FOKO F OD", code: "foko_f_od", type: "input"}, {name: "FOKO F OS", code: "foko_f_os", type: "input"}],
            //[{name: "FOKO K OD", code: "foko_k_od", type: "input"}, {name: "FOKO K OS", code: "foko_k_os", type: "input"}],
            [{name: "FOKO N OD", code: "foko_n_od", type: "input"}, {name: "FOKO N OS", code: "foko_n_os", type: "input"}],
            [{name: "SLOD", code: "slod", type: "textarea", clear: true}],
            [{name: "SLOS", code: "slos", type: "textarea", clear: true}],
            [{name: "SLOU", code: "slou", type: "textarea", clear: true}],
            [{name: "FOD", code: "fod", type: "textarea"}],
            [{name: "FOS", code: "fos", type: "textarea"}],
            [{name: "FOU", code: "fou", type: "textarea"}],
        ];
    }

    state = {
        times: times(),
        hir_start_time: "",
        hir_start_date: "",
        appointment: false,
        report: false,
        fixedHeader: false,
        examinations: [],
        templates: [],
        machines: [],
        users: [],
        section: "",
        templateModal: false,
        template: false,
        operationValues: {}
    }

    setReportField = (name, value) => {
        this.setState({...this.state, appointment: {...this.state.appointment, report: {...this.state.appointment.report, [name]: value}}});
    }

    toggleElement = (e) => {
        const name = e.target.name;
        const value = e.target.checked ? 1 : null
        this.setReportField(name, value);
        this.patchReport(name, value);
        if(e.target.checked)    {
            setTimeout(() => {
                if(name === "kvp_show")  this.kvpRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "oct_show")  this.octRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "ultrazvuk_show")  this.ultrazvukRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "meibomografija_show")  this.meibomografijaRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "topografija_show")  this.topografijaRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "dijagnostika_show")  this.dijagnostikaRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "color_show")  this.colorRef.current.scrollIntoView({behavior: 'smooth'});
                if(name === "stereo_show")  this.stereoRef.current.scrollIntoView({behavior: 'smooth'});
            }, 100);
        }
    }

    patchReport = (field, value) => {
        patchAppointmentReport({id: this.state.appointment.report.id, field: field, value: value}).then((response) => {
            if(this.props.drawer.callback)      {
                this.props.drawer.callback();
            }
        });
    }
    patchReportAndReload = (field, value) => {
        patchAppointmentReport({id: this.state.appointment.report.id, field: field, value: value}).then((response) => {
            this.load(this.props.drawer.appointment.id);
            if(this.props.drawer.callback)      {
                this.props.drawer.callback();
            }
        });
    }

    anchor = (e, ref) => {
        e.preventDefault();
        ref.current.scrollIntoView({behavior: 'smooth'});
    }

    closeDrawer = () => {
        this.props.closeDrawer("report");
        if(this.props.drawer.callback)      {
            this.props.drawer.callback();
        }
    }

    saveTemplate = () => {
        putAppointmentTemplate({report: this.state.report, template: this.state.template}).then((response) => {
            this.load(this.props.drawer.appointment.id, true);
        })
    }
    loadCustomTemplate = (template) => {
        var r = window.confirm(`Želite da ucitate izvestaj? Podaci ce biti prepravljeni.`);
        if (r !== true) {
            return false;
        }
        putAppointmentTemplate({report: this.state.report, template}).then((response) => {
            this.load(this.props.drawer.appointment.id, true);
        })
    }

    saveImages = (images, machine) => {
        const ids = images.map(item => item.id);
        putAppointmentImages({id: this.state.appointment.id, images: ids, machine: machine}).then((response) => {
            this.load(this.state.appointment.id);
        })
    }

    deleteImage = (image) => {
        var r = window.confirm(`Želite da obrišete sliku?`);
        if (r !== true) {
            return false;
        }
        deleteAppointmentImage({id: image}).then((response) => {
            this.load(this.state.appointment.id);
        })
    }

    render = () => {
        const app = this.state.appointment;
        if(!this.props.drawer.status)    return "";
        if(app.surgery) {
            const instrumentarka = this.state.users.find(item => item.id === app.instrumentarka) || {};
            const anesteziolog = this.state.users.find(item => item.id === app.anesteziolog) || {};
            const anesteticarka = this.state.users.find(item => item.id === app.anesteticarka) || {};
            return <div className="container mt-4" id="report" style={{marginBottom: 500}}>
                <div className="row">
                    <div className="col-md-3 col-5">
                        {!this.props.drawer.template &&
                        <button
                            onClick={() => this.props.openDrawer({name: "patient", patient: app.patient})}
                            className="btn btn-white btn-block mb-2">
                            Karton: {app.patient.name} {app.patient.lastname}
                        </button>}

                        {this.state.templates.length > 0 &&
                        <div>
                            {this.state.templates.map(tmp => {
                                if(!tmp.surgery) return null;
                                return <div  key={tmp.id} className="mr-2 mb-2">
                                    <button onClick={() => this.loadCustomTemplate(tmp.report?.id)} className="btn btn-white">
                                        {tmp.template_name || "Hirurgija"}
                                    </button>
                                </div>
                            })}
                        </div>}
                    </div>
                    <div className="col-md-9 col-7">
                        <div className="font-weight-bold font_4 mb-3">OPERATIVNA LISTA</div>
                        {!this.props.drawer.template &&
                        <>
                            <div className="mb-3">
                                Ime i prezime pacijenta, godište:
                                <span className="ml-2 font-weight-bold">{app.patient.name} {app.patient.lastname}
                                    &nbsp; {moment(app.patient.dob).format("DD/MM/YYYY")}
                                    &nbsp; ({moment().diff(moment(app.patient.dob),'years')} god.) </span>
                            </div>
                            <div className="mb-3">
                                Instrumentarka: <strong> {instrumentarka.title} {instrumentarka.name} {instrumentarka.lastname} </strong>
                            </div>
                            <div className="mb-3">
                                Anesteziolog: <strong> {anesteziolog.title} {anesteziolog.name} {anesteziolog.lastname} </strong>
                            </div>
                            <div className="mb-4">
                                Anestetičarka: <strong> {anesteticarka.title} {anesteticarka.name} {anesteticarka.lastname} </strong>
                            </div>
                            <div className="mb-4" ref={this.klasifikacijaRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">ICD-10 KLASIFIKACIJA BOLESTI</div>
                                <div className="row align-items-center">
                                    <div className="col-md-2 text-right">
                                        Bolesti:
                                    </div>
                                    <div className="col-md-10">
                                        <Diseases
                                            diseases={app.diseases}
                                            appointment={this.state.appointment}
                                            reload={() => this.load(this.state.appointment.id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}

                        <Guard doctor={true} admin={true} className="pt-4 mb-4">
                            <div ref={this.anamnezaRef}>
                                <JoditEditor
                                    ref={this.editorRef}
                                    value={app.report.anamneza || ""}
                                    config={{
                                        buttons: ['bold', 'italic', 'underline', 'eraser', 'fontsize', 'fullsize', 'print'],
                                        placeholder: "Operativni nalaz...",
                                        minHeight: 400,
                                        readonly: false // all options from https://xdsoft.net/jodit/doc/
                                    }}
                                    tabIndex={1} // tabIndex of textarea
                                    //onBlur={e => this.patchReport("anamneza", e.srcElement.innerHTML)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newContent => this.patchReport("anamneza", newContent)}
                                />
                            </div>
                        </Guard>


                        <div className="mb-5">
                            {[
                                //{name: "DIJAGNOZA", code: "dijagnoza", type: "textarea"},
                                {name: "KONTROLA", code: "kontrola_kod_oftamologa", type: "textarea"},

                            ].map((item) => {
                                const value = app.report[item.code] === null
                                    ? `Kontrola sutra ${moment(app.start).add(1, "day").format("DD/MM/YYYY")} u 9h`
                                    : app.report[item.code];
                                return <div key={item.code} className="row mb-3">
                                    <Element
                                        col={2}
                                        item={item}
                                        value={value}
                                        patchReport={this.patchReport}
                                    />
                                </div>
                            })}
                        </div>


                        <div className="font-weight-bold font_4 mb-3">OTPUSNA LISTA</div>
                        <div>
                            <JoditEditor
                                ref={this.editorRef2}
                                value={app.report.otpust || ""}
                                config={{
                                    buttons: ['bold', 'italic', 'underline', 'eraser', 'fontsize', 'fullsize', 'print'],
                                    placeholder: "Operativni nalaz...",
                                    minHeight: 400,
                                    readonly: false // all options from https://xdsoft.net/jodit/doc/
                                }}
                                tabIndex={1} // tabIndex of textarea
                                //onBlur={e => this.patchReport("anamneza", e.srcElement.innerHTML)} // preferred to use only this option to update the content for performance reasons
                                onChange={newContent => this.patchReport("otpust", newContent)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
        return <div className="container mt-4" id="report" style={{marginBottom: 500}}>
            <div className="row">
                <div className="col-md-3 col-5">
                    <div id="report_header" className={this.state.fixedHeader ? "report_fixed_header" : ""}>
                        {app
                            ? <>
                                {!this.props.drawer.template &&
                                <>
                                    <Modal
                                        reload={true}
                                        width={1900}
                                        zIndex={9}
                                        title="Izveštaji"
                                        open={this.state.templateModal}
                                        close={() => this.setState({...this.state, templateModal: false})}>

                                        <Appointments modal={true} patient={app.patient.id} load={this.loadTemplate} />
                                    </Modal>

                                    <button
                                        onClick={() => this.setState({...this.state, templateModal: true})}
                                        className="btn btn-info btn-block mb-2">Učitajte izveštaj</button>
                                    {this.state.template &&
                                    <>
                                        <button
                                            onClick={this.saveTemplate}
                                            className="btn btn-success mb-2 btn-block mb-2 btn-lg">Sačuvajte izveštaj</button>
                                        <button
                                            onClick={() => this.load(this.props.drawer.appointment.id, true)}
                                            className="btn btn-danger mb-2 btn-block mb-2 btn-lg">Vratite nazad</button>
                                    </>}

                                    <button
                                        onClick={() => this.props.openDrawer({name: "patient", patient: app.patient})}
                                        className="btn btn-white btn-block mb-2">
                                        Karton: {app.patient.name} {app.patient.lastname}
                                    </button>

                                </>}

                                <ul className="link_button list-unstyled border-bottom pb-2 mb-2">
                                    <Guard doctor={true} admin={true}>
                                        <li><a href="/" onClick={(e) => this.anchor(e, this.anamnezaRef)}>ANAMNEZA</a></li>
                                    </Guard>
                                    <li><a href="/" onClick={(e) => this.anchor(e, this.localisRef)}>STATUS LOCALIS</a></li>
                                    <li><a href="/" onClick={(e) => this.anchor(e, this.zakljucakRef)}>ZAKLJUČAK</a></li>
                                    <li><a href="/" onClick={(e) => this.anchor(e, this.preglediRef)}>PREGLEDI I CENA</a></li>
                                    <li><a href="/" onClick={(e) => this.anchor(e, this.klasifikacijaRef)}>ICD-10 KLASIFIKACIJA BOLESTI</a></li>
                                    <li><a href="/" onClick={(e) => this.anchor(e, this.operacijaRef)}>OPERACIJA</a></li>
                                    <li><label className="chbx">
                                        <input name="dijagnostika_show" onChange={this.toggleElement}
                                               checked={app.report.dijagnostika_show} type="checkbox" /><span /> Dijagnostika
                                    </label></li>
                                    <li><label className="chbx">
                                        <input name="color_show" onChange={this.toggleElement}
                                               checked={app.report.color_show} type="checkbox" /><span /> Kolorni vid
                                    </label></li>
                                    <li><label className="chbx">
                                        <input name="stereo_show" onChange={this.toggleElement}
                                               checked={app.report.stereo_show} type="checkbox" /><span /> Stereo vid
                                    </label></li>
                                </ul>
                                <Guard doctor={true} admin={true}>
                                    <ul className="link_button list-unstyled mb-0">
                                        {[{name: "KVP", short: "kvp_show"}, {name: "OCT", short: "oct_show"}, {name: "Ultrazvuk oka", short: "ultrazvuk_show"},
                                            {name: "Strabološki nalaz", short: "meibomografija_show"}, {name: "Kornealna topografija", short: "topografija_show"},
                                           ].map((item) => {
                                            return <li key={item.short}><label className="chbx">
                                                <input name={item.short}
                                                       onChange={this.toggleElement}
                                                       checked={app.report[item.short]} type="checkbox" /><span /> {item.name}
                                            </label></li>
                                        })}
                                    </ul>
                                </Guard>
                                <button
                                    onClick={this.closeDrawer}
                                    className="btn btn-link font_09 mt-2">Zatvorite izveštaj</button>
                            </>
                            : <Loader height={200} />}
                    </div>
                </div>

                <div className="col-md-9 col-7">
                    {this.state.templates.length > 0 &&
                    <div>
                        {this.state.templates.map(tmp => {
                            if(tmp.surgery) return null;
                            return <button onClick={() => this.loadCustomTemplate(tmp.report?.id)} key={tmp.id} className="btn btn-white mr-2 mb-2">
                                {tmp.template_name || "Izveštaj"}
                            </button>
                        })}
                    </div>}

                    {app
                        ? <>
                            <Guard doctor={true} admin={true} className="pt-4 mb-4">
                                <div ref={this.anamnezaRef}>
                                    <JoditEditor
                                        ref={this.editorRef}
                                        value={app.report.anamneza || ""}
                                        config={{
                                            buttons: ['bold', 'italic', 'underline', 'eraser', 'fontsize', 'fullsize', 'print'],
                                            placeholder: "Anamneza...",
                                            minHeight: 400,
                                            readonly: false // all options from https://xdsoft.net/jodit/doc/
                                        }}
                                        tabIndex={1} // tabIndex of textarea
                                        //onBlur={e => this.patchReport("anamneza", e.srcElement.innerHTML)} // preferred to use only this option to update the content for performance reasons
                                        onChange={newContent => this.patchReport("anamneza", newContent)}
                                    />
                                </div>
                            </Guard>

                            <div className="mb-4" ref={this.localisRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">STATUS LOCALIS</div>

                                {this.statusLocalis.map((elements, index) => {
                                    return <div key={index}>
                                        <div className="row mb-3">
                                            {elements.map((item) => {
                                                return <Element
                                                    clear={true}
                                                    key={item.code}
                                                    col={elements.length > 1 ? 4 : 2}
                                                    item={item}
                                                    value={app.report[item.code]}
                                                    patchReport={this.patchReport}
                                                />
                                            })}
                                        </div>
                                    </div>
                                })}
                            </div>

                            {app.report.color_show &&
                            <div className="mb-4" ref={this.colorRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">KOLORNI VID</div>
                                {[
                                    {name: "KOLORNI VID", code: "color", type: "textarea"},

                                ].map((item) => {
                                    return <div key={item.code} className="row mb-3">
                                        <Element
                                            col={2}
                                            item={item}
                                            value={app.report[item.code]}
                                            patchReport={this.patchReport}
                                        />
                                    </div>
                                })}
                            </div>}

                            {app.report.stereo_show &&
                            <div className="mb-4" ref={this.stereoRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">STEREO VID</div>
                                {[
                                    {name: "STEREO VID", code: "stereo", type: "textarea"},

                                ].map((item) => {
                                    return <div key={item.code} className="row mb-3">
                                        <Element
                                            col={2}
                                            item={item}
                                            value={app.report[item.code]}
                                            patchReport={this.patchReport}
                                        />
                                    </div>
                                })}
                            </div>}

                            {app.report.kvp_show &&
                            <Guard doctor={true} admin={true} className="mb-4">
                                <div className="mb-4" ref={this.kvpRef}>
                                    <div className="border-bottom pb-2 mb-3 font_2">KVP</div>
                                    {[
                                        {name: "KVP", code: "kvp", type: "textarea"},

                                    ].map((item) => {
                                        return <div key={item.code} className="row mb-3">
                                            <Element
                                                col={2}
                                                item={item}
                                                value={app.report[item.code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Guard>}

                            {app.report.oct_show &&
                            <Guard doctor={true} admin={true} className="mb-4">
                                <div className="mb-4" ref={this.octRef}>
                                    <div className="border-bottom pb-2 mb-3 font_2">OCT</div>
                                    {[
                                        {name: "OCT PNO", code: "oct", type: "textarea"},
                                        {name: "OCT MAC", code: "oct_zakljucak", type: "textarea"},
                                        {name: "OCT ANT", code: "oct_kontrola", type: "textarea"},
                                    ].map((item) => {
                                        return <div key={item.code} className="row mb-3">
                                            <Element
                                                col={2}
                                                item={item}
                                                value={app.report[item.code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Guard>}

                            {app.report.ultrazvuk_show &&
                            <Guard doctor={true} admin={true} className="mb-4">
                                <div className="mb-4" ref={this.ultrazvukRef}>
                                    <div className="border-bottom pb-2 mb-3 font_2">ULTRAZVUK OKA</div>
                                    {[
                                        {name: "ZAKLJUČAK", code: "ultrazvuk_zakljucak", type: "textarea"},
                                    ].map((item) => {
                                        return <div key={item.code} className="row mb-3">
                                            <Element
                                                col={2}
                                                item={item}
                                                value={app.report[item.code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Guard>}

                            {app.report.meibomografija_show &&
                            <Guard doctor={true} admin={true} className="mb-4">
                                <div className="mb-4" ref={this.meibomografijaRef}>
                                    <div className="border-bottom pb-2 mb-3 font_2">STRABOLOŠKI NALAZ</div>
                                    {[
                                        {name: "ZAKLJUČAK", code: "meibomografija_zakljucak", type: "textarea"},
                                    ].map((item) => {
                                        return <div key={item.code} className="row mb-3">
                                            <Element
                                                col={2}
                                                item={item}
                                                value={app.report[item.code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Guard>}

                            {app.report.topografija_show &&
                            <Guard doctor={true} admin={true} className="mb-4">
                                <div className="mb-4" ref={this.topografijaRef}>
                                    <div className="border-bottom pb-2 mb-3 font_2">KORNEALNA TOPOGRAFIJA</div>
                                    {[
                                        {name: "ZAKLJUČAK", code: "topografija_zakljucak", type: "textarea"},
                                    ].map((item) => {
                                        return <div key={item.code} className="row mb-3">
                                            <Element
                                                col={2}
                                                item={item}
                                                value={app.report[item.code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    })}
                                </div>
                            </Guard>}

                            {!this.props.drawer.template &&
                            <div className="mb-5">
                                <div className="border-bottom pb-2 mb-3 font_2">SLIKE SA APARATA</div>

                                    {this.state.machines.map((machine) => {
                                        return <div key={machine.id} className="row mb-3 align-items-center">
                                            <div className="col-md-2 text-right">
                                                {machine.name}
                                            </div>
                                            <div className="col-md-10 border-bottom pb-3">
                                                <div className="row">
                                                    {app.images.map((image) => {
                                                        if(image.machine_id !== machine.id)     {
                                                            return "";
                                                        }
                                                        return <div key={image.id} className="mb-2 col-md-4 position-relative">
                                                            <button
                                                                onClick={() => this.deleteImage(image.id)}
                                                                className="btn btn-sm btn-danger position-absolute pt-0 pb-0 pl-1 pr-1">
                                                                <i className="fa fa-close" />
                                                            </button>
                                                            <a href={gallery(image.name, true)} rel="noreferrer" target="_blank">
                                                                <img src={gallery(image.name)} className="img-fluid" alt={image.name} />
                                                            </a>
                                                        </div>
                                                    })}
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => this.props.openDrawer({name: "files", callback: (images) => this.saveImages(images, machine.id)})}
                                                        className="btn btn-info btn-sm">Dodajte slike za: {machine.name}</button>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                            </div>}

                            {!this.props.drawer.template &&
                            <div className="mb-4">
                                <div className="border-bottom pb-2 mb-3 font_2">PROŠIRENE ZENICE</div>

                                {[
                                    [{name: "PROŠIRENE", code: "zenice", type: "checkbox"}, {name: "DODATNI OPIS", code: "zenice_opis", type: "textarea"}],

                                ].map((elements, index) => {
                                    return <div key={index}>
                                        <div className="row mb-3 align-items-center">
                                            <div className="col-md-2">
                                                <Element
                                                    key={elements[0].code}
                                                    col={8}
                                                    item={elements[0]}
                                                    value={app.report[elements[0].code]}
                                                    patchReport={this.patchReport}
                                                />
                                            </div>
                                            <div className="col-md-10">
                                                <Element
                                                    col={2}
                                                    key={elements[1].code}
                                                    item={elements[1]}
                                                    value={app.report[elements[1].code]}
                                                    patchReport={this.patchReport}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>}


                            {app.report.dijagnostika_show && !this.props.drawer.template &&
                            <div className="mb-4" ref={this.dijagnostikaRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">DIJAGNOSTIKA</div>
                                {diagnosis().map((items, index) => {
                                    return <div key={items[0].code} className="row align-items-center mb-2">
                                        <div className="col-md-2">
                                            <Element
                                                key={items[0].code}
                                                col={8}
                                                item={items[0]}
                                                value={app.report[items[0].code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <Element
                                                col={2}
                                                key={items[1].code}
                                                item={items[1]}
                                                value={app.report[items[1].code]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                    </div>
                                })}
                            </div>}

                            <div className="mb-4" ref={this.zakljucakRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">ZAKLJUČAK</div>

                                {[
                                    {name: "ZAKLJUČAK", code: "zakljucak", type: "textarea"},
                                    {name: "SAVET", code: "savet", type: "textarea"},
                                    {name: "KONTROLA KOD OFTAMOLOGA", code: "kontrola_kod_oftamologa", type: "textarea"},
                                    {name: "TERAPIJA KOD KUCE", code: "terapija_kod_kuce", type: "textarea"},
                                    {name: "DIJAGNOZA", code: "dijagnoza", type: "textarea"},

                                ].map((item) => {
                                    return <div key={item.code} className="row mb-3">
                                        <Element
                                            col={2}
                                            item={item}
                                            value={app.report[item.code]}
                                            patchReport={this.patchReport}
                                        />
                                    </div>
                                })}
                            </div>

                            {!this.props.drawer.template &&
                            <div className="mb-4" ref={this.preglediRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">PREGLEDI I CENA</div>

                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-md-2 text-right">PREGLEDI:</div>
                                        <div className="col-md-10">
                                            {app.examinations.map((exam) => {
                                                return <Examination
                                                    key={exam.id}
                                                    exam={exam}
                                                    reload={() => this.load(app.id)}
                                                    examinations={this.state.examinations} />
                                            })}
                                            <div className="mt-3">
                                                <AddExamination
                                                    appointment={app}
                                                    reload={() => this.load(app.id)}
                                                    examinations={this.state.examinations} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {!this.props.drawer.template &&
                            <div className="mb-4" ref={this.klasifikacijaRef}>
                                <div className="border-bottom pb-2 mb-3 font_2">ICD-10 KLASIFIKACIJA BOLESTI</div>
                                <div className="row align-items-center">
                                    <div className="col-md-2 text-right">
                                        Bolesti:
                                    </div>
                                    <div className="col-md-10">
                                        <Diseases
                                            diseases={app.diseases}
                                            appointment={this.state.appointment}
                                            reload={() => this.load(this.state.appointment.id)}
                                        />
                                    </div>
                                </div>
                            </div>}

                            {!this.props.drawer.template &&
                            <div className="mb-4" ref={this.operacijaRef}>
                                <div className="border-bottom pb-2 font-weight-bold mb-3 font_2">OPERACIJA</div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <div className="mb-1 mt-3">
                                            <Element
                                                key="hir_os"
                                                col={8}
                                                item={{name: "OS", code: "hir_os", type: "checkbox"}}
                                                value={app.report["hir_os"]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>
                                        <Element
                                            key="hir_od"
                                            col={8}
                                            item={{name: "OD", code: "hir_od", type: "checkbox"}}
                                            value={app.report["hir_od"]}
                                            patchReport={this.patchReportAndReload}
                                        />

                                        <div className="mb-1 mt-5">
                                            <Element
                                                key="hir_obavljeno"
                                                col={8}
                                                item={{name: "OBAVLJENO", code: "hir_obavljeno", type: "checkbox"}}
                                                value={app.report["hir_obavljeno"]}
                                                patchReport={this.patchReport}
                                            />
                                        </div>

                                        <div className="mb-1">
                                            <Element
                                            key="hir_otkazano"
                                            col={8}
                                            item={{name: "OTKAZANO", code: "hir_otkazano", type: "checkbox"}}
                                            value={app.report["hir_otkazano"]}
                                            patchReport={this.patchReport}
                                        />
                                        </div>

                                        <Element
                                            key="hir_rfzo"
                                            col={8}
                                            item={{name: "RFZO", code: "hir_rfzo", type: "checkbox"}}
                                            value={app.report["hir_rfzo"]}
                                            patchReport={this.patchReport}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        Vrsta sočiva
                                        <select onChange={(e) => this.patchReportAndReload("hir_socivo", e.target.value || null)} className="form-control mb-2"
                                                value={app.report["hir_socivo"] || ""}>
                                            <option> </option>
                                            {this.state.operationValues?.sociva && this.state.operationValues?.sociva?.map(op => {
                                                return <option key={op.hir_socivo} value={op.hir_socivo}>
                                                    {op.hir_socivo}
                                                </option>
                                            })}
                                        </select>

                                        <div> ... ili unesite</div>
                                        <input type="text" onChange={(e) => this.patchReportAndReload("hir_socivo", e.target.value || null)} value={app.report["hir_socivo"] || ""} className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        Jačina sočiva
                                        <select onChange={(e) => this.patchReportAndReload("hir_jacina_sociva", e.target.value || null)} className="form-control"
                                                value={app.report["hir_jacina_sociva"] || ""}>
                                            <option> </option>
                                            {Array(Math.ceil((50 - 1) / 0.5)).fill().map((_, index) => {
                                                const val = 1 + index * 0.5;
                                                return <option key={val} value={val.toFixed(1)}>{val}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        Operacija
                                        <select onChange={(e) => this.patchReportAndReload("hir_operacija", e.target.value || null)} className="form-control mb-2"
                                                value={app.report["hir_operacija"] || ""}>
                                            <option> </option>
                                            {this.state.operationValues?.operacije && this.state.operationValues?.operacije?.map(op => {
                                                return <option key={op.hir_operacija} value={op.hir_operacija}>
                                                    {op.hir_operacija}
                                                </option>
                                            })}
                                        </select>

                                        <div> ... ili unesite</div>
                                        <input type="text" onChange={(e) => this.patchReportAndReload("hir_operacija", e.target.value || null)} value={app.report["hir_operacija"] || ""} className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        Komentar
                                        <div>
                                            <textarea value={app.report["hir_komentar"] || ""} onChange={(e) => this.patchReportAndReload("hir_komentar", e.target.value || null)}
                                                className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            Doktor
                                            <select onChange={(e) => this.patchReportAndReload("hir_doktor", e.target.value || null)} className="form-control mb-2"
                                                    value={app.report["hir_doktor"] || ""}>
                                                <option> </option>
                                                {this.state.users && this.state.users.map(user => {
                                                    if(!user.is_doctor) return null;
                                                    return <option key={user.id} value={user.id}>
                                                        {user.title}   {user.name}   {user.lastname}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-1">
                                            DATUM OPERACIJE:
                                        </div>
                                        <div className="d-flex w-auto">
                                            <select onChange={(e) => this.setState({...this.state, hir_start_time: e.target.value})} value={this.state.hir_start_time} className="form-control">
                                                <option> </option>
                                                {this.state.times.map((item) => {
                                                    return <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                })}
                                            </select>
                                            <div className="position-relative">
                                                <input type="date" onChange={(e) => this.setState({...this.state, hir_start_date: e.target.value})} value={this.state.hir_start_date} className=" ml-1 form-control" />
                                            </div>

                                            <button onClick={() => {
                                                if(!this.state.hir_start_time || !this.state.hir_start_date)  {
                                                    this.patchReportAndReload("hir_start", null);
                                                    return;
                                                }
                                                this.patchReportAndReload("hir_start", this.state.hir_start_date + " " + this.state.hir_start_time)
                                            }} className="btn btn-white ml-2">
                                                Zakazi
                                            </button>
                                        </div>
                                        {app.report["hir_start"] &&
                                        <div className="mt-2 gray">
                                            zakazano za: {app.report["hir_start"]}
                                        </div>}
                                    </div>


                                </div>
                            </div>}
                        </>
                        : <Loader height={200} />}
                </div>
            </div>
        </div>
    }

    loadTemplate = (report) => {
        for(let i=0; i<this.statusLocalis.length; i++)      {
            for(let j=0; j<this.statusLocalis[i].length; j++)      {
                const item = this.statusLocalis[i][j];
                delete report[item.code];
            }
        }
        const newApp = {...this.state.appointment, report: {...this.state.appointment.report, ...report}};
        this.setState({...this.state, templateModal: false, appointment: false});
        setTimeout(() => {
            this.setState({...this.state, templateModal: false, appointment: newApp, template: report.id});
        }, 10);
    }

    load = (id, reset = false) => {
        if(reset)   {
            this.setState({...this.state, template: false, appointment: false});
        } else  {
            this.setState({...this.state, template: false});

        }
        getAppointments({id: id, subtract: "all", images: true, withoutGlobalScope: true}).then((response) => {
            let hir_start_time = "";
            let hir_start_date = "";
            if(response.data.report?.hir_start) {
                hir_start_date = moment(response.data.report.hir_start).format("yyyy-MM-DD").toString();
                hir_start_time = moment(response.data.report.hir_start).format("H:mm").toString();
            }
            this.setState({...this.state, appointment: response.data, report: response.data.report?.id,
            hir_start_time, hir_start_date});
        })
    }

    componentDidMount() {
        var el = document.getElementById('report');
        var scrolledDiv = el.closest(".drawer_scrollable");
        let t = this;
        scrolledDiv.onscroll = function(){
            if(scrolledDiv.scrollTop > 70) {
                if(!t.state.fixedHeader)  {
                    t.setState({...t.state, fixedHeader: true});
                }
            } else  {
                if(t.state.fixedHeader)  {
                    t.setState({...t.state, fixedHeader: false});
                }
            }
        };
        const header = document.getElementById('report_header');
        header.style.width = (header.parentElement.offsetWidth - 30) + "px";
        getExaminations().then((response) => {
            this.setState({...this.state, examinations: response.data});
        });
        getMachines().then((response) => {
            this.setState({...this.state, machines: response.data});
        })
        getUsers({all: true, active: true}).then((response) => {
            this.setState({...this.state, users: response.data});
        })
        getTemplates({user_id: this.props.drawer.appointment?.user?.id}).then((response) => {
            this.setState({...this.state, templates: response.data});
        });
        getOperationValues({}).then((response) => {
            this.setState({...this.state, operationValues: response.data});
        });
        this.load(this.props.drawer.appointment.id);
    }
}

const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.report,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportDrawer);
