import React from 'react';
import Overlay from "./Overlay";


class Modal extends React.Component   {

    state = {
        opacity: 0,
        render: false
    }

    render = () => {
        let style = {opacity: this.state.opacity};
        if(this.props.width) style.maxWidth = this.props.width;
        if(this.props.zIndex) style.zIndex = this.props.zIndex;
      return <>
          <div className={this.props.open ? "builder_modal_content open" : "builder_modal_content"} style={style}>
              <div className="builder_modal_title">
                  {this.props.title}
              </div>
              <div className="builder_modal_inner">
                  {this.props.reload
                    ? this.state.render &&
                      this.props.children
                    : this.props.children}
              </div>
          </div>
          <Overlay show={this.props.open} z={this.props.zIndex} close={this.props.close} />
      </>
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.open !== prevProps.open)      {
            if(!this.props.open)    {
                this.setState({...this.state, opacity: 0, render: false});
                if(this.props.reload)       {
                    setTimeout(() => {
                        this.setState({...this.state, render: false});
                    }, 300);

                }
            }
            else    {
                setTimeout(() => {
                    this.setState({...this.state, opacity: 1, render: true});
                },1);
            }
        }
    }
}

export default Modal;
