import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {openDrawer} from "../../../store/actions/drawersActions";
import routes from "../../../app/routes";


class CalendarModal extends React.Component {

    state = {
        office: false,
        isSurgery: false
    }

    render = () => {
        if(!this.props.time) return "";
        let schedules = [];
        const dateString = this.props.time.format("DD.MM.YYYY.").toString();
        if(this.props.schedules[dateString])      {
            for(let i=0; i<this.props.schedules[dateString].length; i++) {
                const schedule = this.props.schedules[dateString][i]
                if (this.props.time.isBetween(schedule.start.clone().subtract(1, "seconds"), schedule.end)) {
                    schedules.push(schedule);
                }
            }
        }
        let appointments = [];
        if(this.props.appointments[dateString])      {
            for(let i=0; i<this.props.appointments[dateString].length; i++) {
                const appointment = this.props.appointments[dateString][i]
                if (this.props.time.isBetween(appointment.start.clone().subtract(1, "seconds"), appointment.end)) {
                    appointments.push(appointment);
                }
            }
        }

        return <div>
                {appointments.map((item) => {
                    if(this.state.isSurgery)    {
                        if(!item.surgery) return "";
                    } else  {
                        if(item.surgery) return "";
                    }
                    return <div key={item.id} className="mb-2 d-flex">
                        <button
                            onClick={() => this.props.openDrawer({name: "appointment", appointment: item, reload: this.props.reload})}
                            className="btn btn-white btn-block text-left d-flex align-items-center">
                            <i className="fas fa-notes-medical font_10 mr-3" style={{color: item.status_color}} />
                            <div>
                                <div className="font-weight-bold">
                                    {item.surgery ? "Hirurgija" : "Pregled"}: {item.patient.name} {item.patient.lastname} </div>
                                {item.user.title} {item.user.name} {item.user.lastname} &nbsp;
                                {item.start.format("HH:mm").toString()} -  {item.end.format("HH:mm").toString()}
                                {item.examinations.map((exam) => {
                                    return <div key={exam.id} className="font-weight-bold">{exam.name}</div>
                                })}
                                <div className="font-weight-bold" style={{color: item.status_color}}>{item.status_name}</div>
                            </div>
                        </button>

                        <button
                            onClick={() => this.props.openDrawer({name: "report", appointment: item, reload: this.props.reload})}
                            className="ml-2 btn btn-info font-weight-bold">
                            <i className="far fa-clipboard font_6 mb-2" /> <br />
                            IZVEŠTAJ
                        </button>
                    </div>
                })}

                {this.props.time.isBefore(moment())
                    ? <div className="font_3 accent_block mb-2"><i className="fas fa-exclamation-triangle" /> Istekao termin za zakazivanje.</div>
                    : <div>
                        {!this.state.isSurgery && schedules.map((schedule) => {
                            return <div key={schedule.id} className="mb-3">
                                <button
                                    onClick={() => this.props.openDrawer({name: "appointment", schedule: schedule, time: this.props.time, reload: this.props.reload})}
                                    className="btn btn-white btn-block text-left d-flex align-items-center">
                                    <i className="fas fa-stethoscope font_10 mr-3" />
                                    <div>
                                        <div className="font-weight-bold"> Zakažite pregled kod: {schedule.user.title} {schedule.user.name} {schedule.user.lastname} </div>
                                        <div>{this.props.time.format("DD.MM.yyyy.").toString()} &nbsp; {this.props.time.format("HH:mm").toString()} - {this.props.time.clone().add(30, "minutes").format("HH:mm").toString()}</div>
                                    </div>
                                </button>
                                <button
                                    onClick={() => this.props.openDrawer({name: "schedule", doctor: schedule, time: this.props.time, reload: this.props.reload})}
                                    className="btn btn-white btn-block text-left d-flex align-items-center">
                                    <i className="far fa-clock font_10 mr-3" />
                                    <div>
                                        <div className="font-weight-bold"> Promenite raspored za: {schedule.user.title} {schedule.user.name} {schedule.user.lastname} </div>
                                        <div> </div>
                                    </div>
                                </button>
                            </div>
                        })}

                        {!this.state.isSurgery &&
                        <div className="mb-3">
                            <button
                                onClick={() => this.props.openDrawer({name: "schedule", time: this.props.time, reload: this.props.reload})}
                                className="btn btn-white btn-block text-left d-flex align-items-center">
                                <i className="far fa-clock font_10 mr-3" />
                                <div>
                                    <div className="font-weight-bold"> Unesite lekara u raspored </div>
                                    Vreme: {this.props.time.format("DD.MM.yyyy. HH:mm").toString()}
                                </div>
                            </button>
                        </div>}

                        {this.state.office?.surgery &&
                        <div className="mb-3">
                            <button
                                onClick={() => this.props.openDrawer({name: "appointment", time: this.props.time, reload: this.props.reload})}
                                className="btn btn-white btn-block text-left d-flex align-items-center">
                                <i className="fas fa-first-aid text-success font_10 mr-3" />
                                <div>
                                    <div className="font-weight-bold"> Zakažite hirurgiju </div>
                                    Vreme: {this.props.time.format("DD.MM.yyyy. HH:mm").toString()}
                                </div>
                            </button>
                        </div>}
                    </div>}

                <div className="font_09">
                    {schedules.map((schedule) => {
                        return <span key={schedule.user.id + schedule.user.name} className="mr-2 mb-2 d-inline-block">
                            <button onClick={() => this.props.openDrawer({name: "user", user: schedule})} className="btn btn-white btn-sm">
                                <i className="far fa-file-alt mr-1" /> {schedule.user.title} {schedule.user.name} {schedule.user.lastname}
                            </button>
                        </span>
                    })}
                    {appointments.map((appointment) => {
                        return <span key={appointment.id + appointment.patient.name} className="mr-2 mb-2 d-inline-block">
                            <button onClick={() => this.props.openDrawer({name: "patient", patient: appointment.patient})} className="btn btn-white btn-sm">
                                <i className="far fa-file-alt mr-1" /> {appointment.patient.name} {appointment.patient.lastname}
                            </button>
                        </span>
                    })}
                </div>
            </div>
    };

    componentDidMount() {
        let office = false;
        if(this.props.office)       {
            office = this.props.user.offices.find(item => parseInt(item.id) === parseInt(this.props.office));
        } else  {
            office = this.props.user.offices[0];
        }
        const isSurgery = (window.location.pathname === routes.surgery.route);
        this.setState({...this.state, office: office, isSurgery: isSurgery});
    }
}

const mapStateToProps = state => ({
    user: state.user,
    office: state.office,
});
const mapDispatchToProps = {
    openDrawer: openDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal);
