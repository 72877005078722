import axios from "axios";
import {server} from "./helpers";


export async function getRoles(form) {
    return await axios.get(server("roles"), {params: form});
}
export async function getOffices(form) {
    return await axios.get(server("offices"), {params: form});
}
export async function patchOffice(form) {
    return await axios.patch(server("offices"), form);
}

export async function postUserLogin(form) {
    return await axios.post(server("user/login"), form);
}
export async function postUserAuthorization(form) {
    return await axios.post(server("user/authorization"), form);
}
export async function getUserAuthorizationStatus(form) {
    return await axios.get(server("user/authorization/status"), {params: form});
}
export async function patchUserAuthorization(form) {
    return await axios.patch(server("user/authorization"), form);
}
export async function deleteUserAuthorization(form) {
    return await axios.delete(server("user/authorization"), {data: form});
}

export async function putUser(form) {
    return await axios.put(server("user"), form);
}
export async function patchUser(form) {
    return await axios.patch(server("user"), form);
}
export async function getUser(form) {
    return await axios.get(server("user"), {params: form});
}
export async function getUsers(form) {
    return await axios.get(server("users"), {params: form});
}
export async function deleteUser(form) {
    return await axios.delete(server("user"), {data: form});
}
export async function getUsersSchedules(form) {
    return await axios.get(server("users/schedules"), {params: form});
}
export async function putUserSchedule(form) {
    return await axios.put(server("user/schedule"), form);
}
export async function deleteUserSchedule(form) {
    return await axios.delete(server("user/schedule"), {data: form});
}
export async function patchUserScheduleConfirm(form) {
    return await axios.patch(server("user/schedule/confirm"), form);
}


export async function getAppointments(form) {
    return await axios.get(server("appointments"), {params: form});
}
export async function putAppointment(form) {
    return await axios.put(server("appointment"), form);
}
export async function patchAppointment(form) {
    return await axios.patch(server("appointment"), form);
}
export async function getAppointmentStatus(form) {
    return await axios.get(server("appointment/status"), {params: form});
}
export async function patchAppointmentReport(form) {
    return await axios.patch(server("appointment/report"), form);
}
export async function patchAppointmentStatus(form) {
    return await axios.patch(server("appointment/status"), form);
}
export async function patchAppointmentExamination(form) {
    return await axios.patch(server("appointment/examination"), form);
}
export async function deleteAppointmentExamination(form) {
    return await axios.delete(server("appointment/examination"), {data: form});
}
export async function putAppointmentExamination(form) {
    return await axios.put(server("appointment/examination"), form);
}
export async function deleteAppointment(form) {
    return await axios.delete(server("appointment"), {data: form});
}
export async function putAppointmentTemplate(form) {
    return await axios.put(server("appointment/template"), form);
}
export async function putAppointmentImages(form) {
    return await axios.put(server("appointment/images"), form);
}
export async function deleteAppointmentImage(form) {
    return await axios.delete(server("appointment/image"), {data: form});
}

export async function getExaminations(form) {
    return await axios.get(server("examinations"), {params: form});
}
export async function patchExamination(form) {
    return await axios.patch(server("examinations"), form);
}
export async function deleteExamination(form) {
    return await axios.delete(server("examinations"), {data: form});
}
export async function putExamination(form) {
    return await axios.put(server("examinations"), form);
}

export async function getDiseases(form) {
    return await axios.get(server("diseases"), {params: form});
}
export async function patchDisease(form) {
    return await axios.patch(server("diseases"), form);
}
export async function deleteDisease(form) {
    return await axios.delete(server("diseases"), {data: form});
}
export async function putDisease(form) {
    return await axios.put(server("diseases"), form);
}

export async function putAppointmentDisease(form) {
    return await axios.put(server("appointment/disease"), form);
}
export async function deleteAppointmentDisease(form) {
    return await axios.delete(server("appointment/disease"), {data: form});
}

export async function putPatient(form) {
    return await axios.put(server("patient"), form);
}
export async function getPatients(form) {
    return await axios.get(server("patients"), {params: form});
}
export async function getPatient(form) {
    return await axios.get(server("patient"), {params: form});
}
export async function deletePatient(form) {
    return await axios.delete(server("patient"), {data: form});
}
export async function patchPatient(form) {
    return await axios.patch(server("patient"), form);
}

export async function getAssociates(form) {
    return await axios.get(server("associates"), {params: form});
}

export async function getNotifications(form) {
    return await axios.get(server("notifications"), {params: form});
}
export async function getPatientsWaiting(form) {
    return await axios.get(server("patients/waiting"), {params: form});
}

export async function getFiles(form) {
    return await axios.get(server("file"), {params: form});
}
export async function postFile(form) {
    return await axios.post(server("file"), form);
}
export async function deleteFile(form) {
    return await axios.delete(server("file"), {data: form});
}


export async function getMachines(form) {
    return await axios.get(server("machines"), {params: form});
}
export async function patchMachine(form) {
    return await axios.patch(server("machines"), form);
}
export async function deleteMachine(form) {
    return await axios.delete(server("machines"), {data: form});
}
export async function putMachine(form) {
    return await axios.put(server("machines"), form);
}


export async function patchRole(form) {
    return await axios.patch(server("roles"), form);
}
export async function deleteRole(form) {
    return await axios.delete(server("roles"), {data: form});
}
export async function putRole(form) {
    return await axios.put(server("roles"), form);
}
export async function getTemplates(form) {
    return await axios.get(server("templates"), {params: form});
}
export async function getOperationValues(form) {
    return await axios.get(server("get_operation_values"), {params: form});
}
export async function putTemplate(form) {
    return await axios.put(server("templates"), form);
}

export async function postCheckLbo(form) {
    return await axios.post(server("rfzo/check"), form);
}

export async function postDownloadXml(form) {
    return await axios.post(server("rfzo/download"), form);
}
export async function getStatistics(form) {
    return await axios.get(server("statistics"), {params: form});
}
export async function getStatisticsExams(form) {
    return await axios.get(server("statistics/exams"), {params: form});
}
