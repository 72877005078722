import React from 'react';
import {getAppointments, getUsers} from "../../../app/hooks";
import routes from "../../../app/routes";
import moment from "moment";
import {closeDrawer, openDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import Loader from "../../framework/Loader";


class Program extends React.Component {

    state = {
        loading: true,
        doctors: [],
        appointments: []
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-4 mb-5">
            <div className="title mb-4">{routes.program.name}</div>

            {this.state.appointments.length > 0
                ? <>
                {this.state.appointments.map((byDate, i) => {
                    return <div key={i} className="" style={{marginBottom: 100}}>
                            <div className="font_5 font-weight-bold mb-3">
                                {moment(Object.values(byDate)[0][0].report.hir_start).format("DD.MM.YYYY.")}
                                <span className="ml-2"> ({moment(Object.values(byDate)[0][0].report.hir_start).format("dddd")}) </span>
                            </div>

                        {Object.values(byDate).map((byDoc, a) => { // array
                            const app = byDoc[0];
                            return <div key={a} className="mb-5">
                                <div className="font_4 mb-2">
                                    {app.report.doctor
                                        ? <span>
                                              {app.report.doctor.title} {app.report.doctor.name} {app.report.doctor.lastname}
                                        </span>
                                        : <span className="red">
                                            Nije postavljen doktor.
                                        </span>}
                                    <span className="ml-4 font-italic">
                                    (od {moment(app.report.hir_start).add(1, "hour").format("HH:mm")}h)
                                    </span>
                                        <span className="ml-5">
                                        {moment(app.report.hir_start).format("DD.MM.YYYY.")}
                                    </span>
                                </div>

                                <table className="table">
                                    <thead>
                                    <tr className="font-weight-bold">
                                        <td style={{width: 20}}> </td>
                                        <td style={{width: 140}}> </td>
                                        <td>Ime i prezime</td>
                                        <td>Vreme dolaska</td>
                                        <td>Oko</td>
                                        <td>Vrsta sočiva</td>
                                        <td>Operacija</td>
                                        <td>Jačina sočiva</td>
                                        <td>Komentar</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {byDoc.map((item, j) => {
                                        return <tr key={item.id}>
                                            <td>{j+1}.</td>
                                            <td>
                                                <button
                                                    onClick={() => this.props.openDrawer({name: "report", appointment: item, callback: this.load})}
                                                    className="btn btn-info btn-block">
                                                    <i className="far fa-clipboard font_2 mb-2" /><strong> izveštaj </strong>
                                                </button>
                                            </td>
                                            <td>{item.patient.lastname} {item.patient.name} {moment(item.patient.dob).format("YYYY")} ({moment().diff(moment(item.patient.dob),'years')})</td>
                                            <td> {moment(item.report.hir_start).format("HH:mm")}h </td>
                                            <td> {item.report.hir_os && "OS"} {item.report.hir_od && "OD"} </td>
                                            <td> {item.report.hir_socivo} </td>
                                            <td> {item.report.hir_operacija} </td>
                                            <td> {item.report.hir_jacina_sociva} </td>
                                            <td style={{whiteSpace: "pre-wrap"}}> {item.report.hir_komentar} </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>

                                <button onClick={() => this.props.openDrawer({name: "program", appointments: byDoc, callback: this.load})} className="btn btn-success">
                                    PDF
                                </button>
                            </div>
                        })}
                    </div>
                })}
                </>
                : <div className="p-5 font_2">
                    Nema operacija
                </div>}


        </div>
    }

    load = () => {
        getAppointments({subtract: "all", program: true}).then((response) => {
            const dateSorted = response.data.sort((a, b) => {
                const dateA = new Date(a.report.hir_start);
                const dateB = new Date(b.report.hir_start);
                return dateA - dateB;
            });
            const dates = [];
            const holder = [];
            for(let i=0; i<dateSorted.length; i++)   {
                const item = dateSorted[i];
                const hirStart = moment(item.report.hir_start).format('DD.MM.YYYY.');
                if(dates.indexOf(hirStart) < 0) dates.push(hirStart);
                const index = dates.indexOf(hirStart);
                if(!holder[index])    holder[index] = {};
                const doctorId = item.report?.hir_doktor || 0;
                if(!holder[index][doctorId])    holder[index][doctorId] = [];
                holder[index][doctorId].push(item);
            }
            console.log(holder)
            this.setState({...this.state, loading: false, appointments: holder});
        });
    }

    componentDidMount() {
        getUsers({all: true, active: true, doctors: true}).then((response) => {
            this.setState({...this.state, doctors: response.data});
        });
       this.load();
    }
}
const mapStateToProps = state => ({
    user: state.user,
    drawer: state.drawers.appointment,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Program);
